import React from "react";
import concatClasses from "utils/concatClasses";
import { ISwitch } from "./Switch";
import styles from "./Switch.module.scss";

export const Switch: React.FC<ISwitch.IProps> = ({
  checked,
  label,
  customClass,
  onClick,
}) => {
  return (
    <label className={concatClasses(styles.labelContainer, customClass)}>
      <input
        checked={checked}
        type="checkbox"
        onChange={() => {
          onClick(!checked);
          console.log("checked", !checked);
        }}
      />
      <div className={styles.labelContainer__wrapper}></div>
      <span>{label}</span>
    </label>
  );
};

Switch.defaultProps = {
  onClick: () => ({}),
  checked: false,
  customClass: "",
};
