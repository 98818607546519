import { StoreListData } from "interfaces";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const defaultStoreList: StoreListData = {
  fetching: false,
  listItems: [],
  meta: { totalCount: 0, limit: 20, page: 1 },
  // query: {},
};
