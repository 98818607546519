import React, { useRef } from "react";
import { IImageUploader } from "./ImageUploader";
import styles from "./ImageUploader.module.scss";
import concatClasses from "utils/concatClasses";
import { Image } from "components";
import { useRootStore } from "store";
import { Spinner } from "react-bootstrap";

export default function ImageUploader({
  src,
  customClass,
  style,
  onUpload,
  label,
}: IImageUploader.IProps) {
  const {
    imagesStore: { createSignature, uploadImage },
  } = useRootStore();
  const inputRef: any = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const { change, spinner } = styles;

  const handleClick = async () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = async (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length) {
      const [imageFile] = files;
      const base64File = await toBase64(files[0]);
      const uploadPayload = {
        file: base64File,
      };
      // create signature and add to upload payload
      const { timestamp, signature, publicId } = await createSignature(
        imageFile.name
      );
      handleUpload({
        ...uploadPayload,
        public_id: publicId,
        signature,
        timestamp,
      });
    }
  };
  const handleUpload = async (uploadPayload) => {
    try {
      setIsLoading(true);
      const { secure_url } = await uploadImage(uploadPayload);
      if (onUpload) {
        onUpload(secure_url);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="mb-3">
      <input
        type="file"
        className="d-none"
        accept="image/*"
        ref={inputRef}
        onChange={handleChange}
      />
      <div className="position-relative">
        {isLoading && (
          <div className={spinner}>
            <Spinner animation="border" size="sm" variant="primary" />
          </div>
        )}
        <Image src={src} height="200px" label={label} />
      </div>

      <div className="mt-2">
        <small
          onClick={handleClick}
          className={concatClasses(change, "text-primary")}
        >
          Change
        </small>
      </div>
    </div>
  );
}

ImageUploader.defaultProps = {
  style: {},
};
