import { Button, Form, TextInput } from "components";
import { IInsuranceCompanyForm } from "./InsuranceCompanyForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { InsuranceCompanySchema } from "schemas";

const InsuranceCompanyForm: React.FC<IInsuranceCompanyForm.IProps> = ({
  insuranceCompany,
  handleSubmit = () => ({}),
}) => {
  const { insuranceCompaniesStore } = useRootStore();
  const initialValues: IInsuranceCompanyForm.InsuranceCompanyFormValues = {
    name: insuranceCompany?.name,
  };

  const onSubmit = async (
    values: IInsuranceCompanyForm.InsuranceCompanyFormValues,
    actions: FormikHelpers<IInsuranceCompanyForm.InsuranceCompanyFormValues>
  ) => {
    try {
      let resp;
      if (!insuranceCompany.id) {
        resp = await insuranceCompaniesStore.createInsuranceCompany(values);
      } else {
        resp = await insuranceCompaniesStore.updateInsuranceCompany(
          insuranceCompany.id,
          values
        );
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={InsuranceCompanySchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />
          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { InsuranceCompanyForm };
