import React from "react";

function HandshakeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill={props.fill || "#363131"}
        d="M20.71 7.71c1.25-1.25.68-2.71 0-3.42l-3-3c-1.26-1.25-2.71-.68-3.42 0A5.797 5.797 0 0110.178 3H10C8.1 3 7 4 6.44 5.15L4.828 6.76A9.657 9.657 0 002 13.59l-.71.7c-1.25 1.26-.68 2.71 0 3.42l3 3c.54.54 1.12.74 1.67.74.71 0 1.36-.35 1.75-.74l.166-.167A8.645 8.645 0 0114 18c1.7 0 2.56-1.06 2.87-2.1 1.13-.3 1.75-1.16 2-2C20.42 13.5 21 12.03 21 11V8.59a.59.59 0 00-.59-.59l.3-.29zM19 11c0 .45-.19 1-1 1a1 1 0 00-1 1c0 .45-.19 1-1 1a1 1 0 00-1 1c0 .45-.19 1-1 1a10.646 10.646 0 00-7.529 3.118l-.161.162c-.31.29-.49.12-.6.01l-2.99-2.98c-.29-.31-.12-.49-.01-.6A4.446 4.446 0 004 12.578v-.748c0-.91.361-1.781 1.004-2.424L6 8.41V10c0 1.21.8 3 3 3 1.445 0 2.286-.772 2.693-1.645.327-.7.934-1.355 1.707-1.355H18a1 1 0 011 1zm.29-4.71l-.195.196A5.134 5.134 0 0115.455 8H12a2 2 0 00-2 2c0 .45-.19 1-1 1s-1-.55-1-1V7c0-.46.17-2 2-2h.185c2.065 0 4.045-.82 5.505-2.28.31-.29.49-.12.6-.01l2.99 2.98c.29.31.12.49.01.6z"
      ></path>
    </svg>
  );
}

export default HandshakeIcon;
