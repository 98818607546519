import { Icon } from "components";
import React from "react";
import concatClasses from "utils/concatClasses";
import { IFormControl } from "./FormControl";
import styles from "./FormControl.module.scss";

export const FormControl: React.FC<IFormControl.IProps> = (props) => {
  const {
    width,
    height,
    style,
    label,
    icon,
    name,
    error,
    value,
    withFormik,
    formikInstance,
    bottomText,
    onClickBottomText,
  } = props;

  const controlError = withFormik ? formikInstance.errors[name] : error;
  let hasError = !!controlError;

  if (withFormik) {
    hasError = hasError && (formikInstance.touched[name] as boolean);
  }

  const controlValue = withFormik ? formikInstance.values[name] : value;
  let hasValue = false;
  if (controlValue) {
    hasValue = Array.isArray(controlValue)
      ? !!controlValue.length
      : !!controlValue;
    hasValue = hasValue && !hasError;
  }

  return (
    <div
      style={{ width, height, marginBottom: 16, ...style }}
      className={`d-flex flex-column ${props.containerClass}`}
      tabIndex={-1}
      onBlur={props.onBlur}
    >
      {label ? (
        <span className={`d-flex align-items-center ${styles.controlLabel}`}>
          {label}
        </span>
      ) : null}
      <div
        className={concatClasses(
          "w-100 position-relative",
          styles.controlInputContainer,
          error ? styles.controlErrorContainer : "",
          // hasValue ? styles.Active : "",
          props.bordered ? styles.borderedControl : "",
          props.controlInputContainerClass
        )}
      >
        {props.children}
        {icon ? (
          <div className={styles.iconContainer} onClick={props.onIconClick}>
            <Icon icon={icon} {...props.iconProps} />
          </div>
        ) : null}
      </div>

      {bottomText ? (
        <div
          className={concatClasses("text-primary my-2", styles.bottomText)}
          onClick={onClickBottomText}
        >
          <small>{bottomText}</small>
        </div>
      ) : null}

      {hasError ? (
        <span className={styles.controlError}>{controlError}</span>
      ) : null}
      <style jsx>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }
      `}</style>
    </div>
  );
};

FormControl.defaultProps = {
  style: {},
  containerClass: "",
  inputClass: "",
  bordered: true,
  withFormik: true,
  onChange: () => ({}),
  postChange: () => ({}),
  onBlur: () => ({}),
  onIconClick: () => ({}),
  onTouched: () => ({}),
};
