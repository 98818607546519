import React from "react";

function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.fill || "#307EC4"}
        fillRule="evenodd"
        d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0zm0 1.5c-4.687 0-8.5 3.813-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5 0-4.687-3.813-8.5-8.5-8.5zm.004 11.296a1 1 0 110 2 1.003 1.003 0 01-1.005-1c0-.553.443-1 .995-1h.01zm-.01-7.342a.75.75 0 01.75.75v4.419a.75.75 0 01-1.5 0V6.204a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InfoIcon;
