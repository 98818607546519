import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getDailySettlements = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/payments/daily-settlements${params}`);
};

export const updateSettlement = (settlementId, payload) => {
  return api.put(`/payments/settlements/${settlementId}`, payload);
};
