import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { RiskProfile } from "interfaces";
import styles from "./RiskProfileDetail.module.scss";
import { color } from "theme";
import { IRiskProfileDetail } from "./RiskProfileDetail";
import { formatDate } from "utils/dates";
import formatNumber from "utils/formatMoney";

const RiskProfileDetail: React.FC<IRiskProfileDetail.IProps> = observer(
  (props: IRiskProfileDetail.IProps) => {
    const { riskProfile } = props;

    const { riskDetailTr } = styles;

    const groups = [
      [
        { text: "Phone Number", value: riskProfile.phone },
        { text: "Email Address", value: riskProfile.email },
        { text: "Date Created", value: formatDate(riskProfile.createdAt) },
      ],
      [
        { text: "Driving Duration", value: riskProfile.drivingDuration },
        {
          text: "Vehicle Value",
          value: `N ${formatNumber(riskProfile.vehicleWorth)}`,
        },
        { text: "Security Feature", value: riskProfile.securityFeature },
      ],
      [
        { text: "Parking", value: riskProfile.homeCarPark },
        { text: "Driving Aid", value: riskProfile.drivingAid },
        { text: "Vehicle Usage", value: riskProfile.vehicleUsage },
      ],
      [
        {
          text: "LawEnforcement Off. hist.",
          value: riskProfile.hasLawEnforcementOffenseHistory ? "Yes" : "No",
        },
        {
          text: "Motor Accident hist.",
          value: riskProfile.hasMotorAccidentHistory ? "Yes" : "No",
        },
        {
          text: "Insurance Claim hist.",
          value: riskProfile.hasInsuranceClaimHistory ? "Yes" : "No",
        },
      ],
    ];
    return (
      <table className="w-100">
        {groups.map((group) => {
          return (
            <tr className={riskDetailTr}>
              {group.map((item: { text: string; value: any }) => (
                <td className="pl-0">
                  <div className="text-muted mb-3">{item.text}</div>
                  <div className="text-primary">{item.value}</div>
                </td>
              ))}
            </tr>
          );
        })}
      </table>
    );
  }
);

export { RiskProfileDetail };
