import api from "./config";

export const getVehicleModels = ({
  page = 1,
  limit = 20,
  query = "",
  sortBy = "",
  orderBy = "ASC",
  make = "",
}) => {
  console.log(make, "VEHMAKE");
  let url = `/api/vehicle-models/admin?limit=${limit}&query=${query}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`;
  if (make) url += `&make=${make}`;
  return api.get(url);
};

export const createVehicleModel = (payload) => {
  return api.post(`/api/vehicle-models`, payload);
};

export const updateVehicleModel = (vehicleModelId, payload) => {
  return api.put(`/api/vehicle-models/${vehicleModelId}`, payload);
};
