import { Business, DataListMeta } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  createApiKey,
  createBusiness,
  getApiKeys,
  getBusinesses,
  updateBusiness,
} from "services/api";
import { RootStore } from "..";

export const PartnerStore = types
  .model("Partner", {
    partners: types.optional(types.array(types.frozen<Business>()), []),
    partnersMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
    activePartner: types.optional(types.frozen<Business>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setActivePartner(partner: Business) {
      self.activePartner = partner;
    },
    getPartners: flow(function* (payload) {
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getBusinesses(payload);
        const { data, ...rest } = resp.data;
        self.partners = data;
        self.partnersMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        self.fetching = false;
      }
    }),
    getApiKeys: flow(function* (businessId: number) {
      const resp: GenericAxiosResponse = yield getApiKeys(businessId);
      return resp.data;
    }),
    createApiKey: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createApiKey(payload);
        self.rootStore.toastStore.addSuccessToast(
          "Api key successfully created"
        );
        return resp.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }))
  .actions((self) => ({
    createPartner: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createBusiness(payload);
        self.getPartners({});
        self.rootStore.toastStore.addSuccessToast(
          "Business successfully created"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updatePartner: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateBusiness(
          vehicleModelId,
          payload
        );
        self.getPartners({});
        self.rootStore.toastStore.addSuccessToast(
          "Business successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
