import { Button, Icon, Pagination2 } from "components";
import React, { useState } from "react";
import { color } from "theme";
import concatClasses from "utils/concatClasses";
import { eventEmitter, Events } from "utils/event-emitter";
import { ITable } from "./Table";
import styles from "./Table.module.scss";

export const Table: React.FC<ITable.IProps> = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  const hasNoItems = !props.listItems?.length;
  const renderEmptyRow = () => (
    <tr>
      <td colSpan={props.headersList.length} align="center">
        {loading ? "Fetching Items..." : props.emptyText}
      </td>
    </tr>
  );

  const handlePagination = async (page) => {
    setLoading(true);
    await props.handlePagination(page);
    if (props.scollOnPaginate) {
      eventEmitter.dispatch(Events.SCROLL_LAYOUT_TOP, null);
    }
    setLoading(false);
  };

  let resultsText = `Showing ${props.listItems.length} results`;
  if (!hasNoItems) {
    resultsText += ` of ${props.totalCount}`;
  }

  const { tableTop, tableTop__item, tableTop__stat } = styles;

  return (
    <div
      className={concatClasses(
        styles.tableContainer,
        props.tableClass || "",
        loading ? styles.loading : ""
      )}
    >
      <div className={styles.tableWrapper}>
        {props.usePagination && (
          <div className={styles.tableMeta}>
            {props.topStats && (
              <div className={tableTop}>
                {props.topStats.map((stat) => (
                  <div className={tableTop__item} key={`stat_${stat?.label}`}>
                    <span className="text-muted">{stat.label}</span>
                    <div
                      className={concatClasses(
                        tableTop__stat,
                        stat.className || ""
                      )}
                    >
                      {stat.value}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <span>{!loading && !hasNoItems ? resultsText : ""}</span>

            <div className={`pr-2 ${styles.pageNumWrapper}`}>
              {props.headerContent}
              {!hasNoItems && (
                <>
                  <span>Page</span>
                  <div className={styles.pageNumContainer}>
                    {props.currentPage}
                  </div>{" "}
                </>
              )}

              {props.showAddBtn && (
                <Button
                  label={props.addBtnLabel || "Add New"}
                  customClass="ml-3 px-4"
                  onClick={props.handleAdd}
                />
              )}

              {props.showRefresh && (
                <Icon
                  icon="refresh"
                  containerClass="ml-3"
                  width={16}
                  height={16}
                  onClick={() => props.handleRefresh()}
                  fill={color.secondary}
                />
              )}
            </div>
          </div>
        )}

        <table className={styles.table}>
          <thead>
            <tr
              className={concatClasses(
                styles.headersTr,
                props.borderlessHeader ? styles.noBorderTr : ""
              )}
              style={{ height: props.thRowHeight }}
            >
              {props.headersList?.map((header, index) => {
                return (
                  <th
                    className={styles.th}
                    style={header.thStyle || {}}
                    key={index}
                  >
                    <div
                      className={`d-flex align-items-center ${
                        props.headerClass
                      } ${header.headerClass || ""}`}
                    >
                      {header.icon}

                      <span
                        className={styles.headerText}
                        style={header.textStyle || {}}
                      >
                        {header.header}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={props.bodyClass}>
            {hasNoItems
              ? renderEmptyRow()
              : props.listItems.map((item, id) => {
                  return props.renderItems({ item, id });
                })}
          </tbody>
        </table>
        {props.usePagination && (
          <Pagination2
            pageCount={props.pageCount}
            initialPage={props.initialPage}
            currentPage={props.currentPage}
            paginationHandler={handlePagination}
            isFixed={props.fixedPagination}
          />
        )}
      </div>
    </div>
  );
};

Table.defaultProps = {
  emptyText: "No results found",
  listItems: [],
  renderItems: () => <div />,
  initialPage: 1,
  usePagination: false,
  handlePagination: () => ({}),
  thRowHeight: 40,
  scollOnPaginate: true,
  // headerClass: "col-md-9",
};
