import React from "react";
import { observer } from "mobx-react-lite";
import { Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Bank } from "interfaces";
import styles from "./ClaimList.module.scss";
import { IBanksList } from "./BanksList";

const headersList = [
  { header: "Bank Name" },
  { header: "Provider code" },
  { header: "Country code" },
];

const BanksList: React.FC<IBanksList.IProps> = observer(
  (props: IBanksList.IProps) => {
    const { bankStore } = useRootStore();
    const {
      banks,
      banksMeta: { page, totalCount, limit },
      fetching,
      getBanks,
    } = bankStore;

    console.log(`pagexx`, page);

    const renderBanks = (props: { item: Bank; id: number }) => {
      const { item: bank, id } = props;
      return (
        <tr key={`bank_${id}`}>
          <td>{bank.name}</td>
          <td>{bank.providerCode}</td>
          <td>{bank.country}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={banks}
          headersList={headersList}
          totalCount={totalCount}
          loading={fetching}
          renderItems={renderBanks}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            getBanks({ page });
          }}
        />
      </>
    );
  }
);

export { BanksList };
