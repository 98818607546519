import { GalleryItem } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, types } from "mobx-state-tree";
import { getCrashes } from "services/api";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const GalleryStore = types
  .model("Gallery", {
    isVisible: false,
    items: types.optional(types.array(types.frozen<GalleryItem>()), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    setVisibility(isVisible) {
      self.isVisible = isVisible;
    },
    setItems(items) {
      self.items = items;
    },
  }));
