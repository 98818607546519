import React from "react";

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={props.fill || "#000"}
        fillRule="evenodd"
        d="M14.334 0C17.723 0 20 2.378 20 5.916v8.168C20 17.622 17.723 20 14.333 20H5.665C2.276 20 0 17.622 0 14.084V5.916C0 2.378 2.276 0 5.665 0h8.669zm0 1.5H5.665C3.135 1.5 1.5 3.233 1.5 5.916v8.168c0 2.683 1.635 4.416 4.165 4.416h8.668c2.531 0 4.167-1.733 4.167-4.416V5.916c0-2.683-1.636-4.416-4.166-4.416zM8.13 7.063L9.998 8.93l1.867-1.865a.749.749 0 111.06 1.06L11.058 9.99l1.869 1.87a.749.749 0 11-1.06 1.06l-1.869-1.87-1.865 1.867a.744.744 0 01-.53.22.749.749 0 01-.53-1.28L8.938 9.99 7.07 8.123a.749.749 0 111.06-1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CloseIcon;
