import React from "react";

function UserIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M15.84 16.193c0 3.296-4.52 3.677-7.919 3.677h-.243C5.512 19.865 0 19.728 0 16.173c0-3.229 4.338-3.66 7.711-3.676h.453c2.166.005 7.676.141 7.676 3.696zm-7.919-2.197c-4.261 0-6.421.732-6.421 2.177 0 1.458 2.16 2.197 6.421 2.197 4.26 0 6.419-.732 6.419-2.177 0-1.458-2.159-2.197-6.419-2.197zM7.921 0a5.315 5.315 0 015.309 5.31 5.314 5.314 0 01-5.309 5.309h-.032A5.3 5.3 0 012.61 5.307 5.316 5.316 0 017.921 0zm0 1.428A3.887 3.887 0 004.038 5.31a3.873 3.873 0 003.854 3.882l.029.714v-.714a3.886 3.886 0 003.881-3.882 3.886 3.886 0 00-3.881-3.882z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UserIcon;
