import React from "react";
import useLoadImage from "hooks/useLoadImage";
import { useEffect } from "react";
import { IH4 } from "./H4";
import styles from "./H4.module.scss";
import concatClasses from "utils/concatClasses";
import { useRef } from "react";
import BaseText from "components/BaseText";

export default function H4({
  className,
  ...props
}: IH4.IProps) {
  const { h4Class } = styles;

  return <BaseText {...props} className={concatClasses(h4Class, className)} />;
}
