import React from "react";

function ClockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={props.fill || "#000"}
        fillRule="evenodd"
        d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0zm0 1.5c-4.687 0-8.5 3.813-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5 0-4.687-3.813-8.5-8.5-8.5zm-.339 3.595a.75.75 0 01.75.75v4.422l3.405 2.03a.752.752 0 01-.769 1.29l-3.77-2.249a.755.755 0 01-.366-.645V5.845a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ClockIcon;
