import { OverviewFilter } from "enums/overview-filter";
import { PaginatedListPayload } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { Overview } from "interfaces/overview";
import { flow, types } from "mobx-state-tree";
import { getOverview } from "services/api/overview";
import cloneDeep from "utils/cloneDeep";
import { getAllMonthsShort, getAllMonthsWithIds } from "utils/dates";
import getPercentChange from "utils/percentChange";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const OverviewStore = types
  .model("Overview", {
    overview: types.optional(types.frozen<Overview>(), {}),
    fetching: false,
    periodFilter: types.optional(
      types.enumeration<any>(Object.values(OverviewFilter)),
      OverviewFilter.MONTH
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get activeOverviewData() {
      return self.periodFilter === OverviewFilter.MONTH
        ? self.overview?.paymentsOverviewMonths || []
        : self.overview?.paymentsOverviewYears || [];
    },
  }))
  .views((self) => ({
    get paymentsChartLabels() {
      if (self.periodFilter === OverviewFilter.MONTH) {
        return getAllMonthsShort();
      }
      return self.activeOverviewData?.map((overview) => overview.period);
    },
    get paymentsChartData() {
      let data = [];
      const overviewData = self.activeOverviewData;
      if (self.periodFilter === OverviewFilter.MONTH) {
        const allMonths = getAllMonthsWithIds();
        for (const month of allMonths) {
          const overviewIdx = overviewData.findIndex(
            (d) => d.period === month.id + 1
          );
          data.push(
            overviewIdx >= 0 ? overviewData[overviewIdx].total_amount : 0
          );
        }
      } else {
        data =
          self.activeOverviewData?.map((overview) => +overview.total_amount) ||
          [];
      }

      return data;
    },
    get totalTransactionAmount() {
      let total = 0;
      if (self.activeOverviewData) {
        total += self.activeOverviewData?.reduce(
          (total, curr) => total + +curr.total_amount,
          0
        );
      }
      return total;
    },
    get totalTransactionPeriod() {
      return self.activeOverviewData.length || 0;
    },
  }))
  .views((self) => ({
    get avgTransactionAmount() {
      return +self.totalTransactionAmount / +self.totalTransactionPeriod || 0;
    },
  }))
  .actions((self) => ({
    setOverviewFilter(filter: OverviewFilter) {
      self.periodFilter = filter;
    },
    calculatePercentChanges(newData): any {
      const resp = {};
      Object.keys(newData).forEach((k) => {
        const newValue = newData[k];
        const stat = { value: newValue, percentChange: 0 };
        if (self.overview) {
          const currValue = self.overview[k]?.value;
          const currPercent = self.overview[k]?.percentChange;
          stat.percentChange =
            currValue !== newValue
              ? getPercentChange(+currValue, +newValue)
              : currPercent;
        }
        resp[k] = stat;
      });
      return resp;
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    getOverview: flow(function* () {
      try {
        self.fetching = true;
        console.log(`self.query`, self.query);
        const resp: GenericAxiosResponse = yield getOverview({ ...self.query });
        const data = resp.data.data;
        const {
          driver,
          paymentsOverviewMonths,
          paymentsOverviewYears,
          ...stats
        } = data;
        const statsWithPercentages = self.calculatePercentChanges(stats);

        self.overview = {
          ...statsWithPercentages,
          driver,
          paymentsOverviewMonths,
          paymentsOverviewYears,
        };
        self.fetching = false;
        return data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.fetching = false;
      }
    }),
  }));
