import React from "react";

function BagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M9.754 0a4.527 4.527 0 014.519 4.474h.219A5.012 5.012 0 0119.5 9.48v5.516A5.011 5.011 0 0114.492 20H5.008A5.012 5.012 0 010 14.996V9.48a5.012 5.012 0 015.008-5.005l.204-.001.006-.195A4.488 4.488 0 016.55 1.311C7.408.46 8.56.035 9.754 0zm4.738 5.975l-.219-.001v1.582a.75.75 0 01-1.5 0V5.974H6.711v1.582a.75.75 0 01-1.5 0V5.974h-.203A3.51 3.51 0 001.5 9.48v5.516A3.51 3.51 0 005.008 18.5h9.484A3.51 3.51 0 0018 14.996V9.48a3.51 3.51 0 00-3.508-3.505zM9.752 1.5c-.812.028-1.572.308-2.146.876a2.999 2.999 0 00-.895 2.098h6.06l-.003-.128A3.027 3.027 0 009.751 1.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BagIcon;
