import { Button, Dropdown, Form, TextInput } from "components";
import { IAdminForm } from "./AdminForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { AdminSchema } from "schemas";
import { serializedAccessLevels } from "utils/access-levels";

const AdminForm: React.FC<IAdminForm.IProps> = ({
  admin,
  handleSubmit = () => ({}),
}) => {
  const { adminStore } = useRootStore();
  const initialValues: IAdminForm.AdminFormValues = {
    firstname: admin?.firstname,
    lastname: admin?.lastname,
    email: admin?.email,
    phone: admin?.phone,
    role: admin?.role,
  };

  const onSubmit = async (
    values: IAdminForm.AdminFormValues,
    actions: FormikHelpers<IAdminForm.AdminFormValues>
  ) => {
    try {
      let resp;
      if (!admin.id) {
        resp = await adminStore.createAdmin(values);
      } else {
        resp = await adminStore.updateAdmin(admin.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={AdminSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="email"
            type="text"
            label="Email"
            placeholder="Enter email"
            disabled={!!admin.id}
          />
          <TextInput
            formikInstance={formikProps}
            name="firstname"
            type="text"
            label="First Name"
            placeholder="Enter name"
          />
          <TextInput
            formikInstance={formikProps}
            name="lastname"
            type="text"
            label="Last Name"
            placeholder="Enter name"
          />

          <TextInput
            formikInstance={formikProps}
            name="phone"
            type="text"
            label="Phone Number"
            placeholder="Enter phone"
          />

          <Dropdown
            items={serializedAccessLevels}
            name="role"
            withFormik
            formikInstance={formikProps}
            label="Access Level"
            single
            placeholder="Choose One"
            displayKey="text"
          />

          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { AdminForm };
