import { Card } from "components";
import { IPolicyDetail } from "./PolicyDetail";
import appStyles from "styles/app.module.scss";
import styles from "./PolicyDetail.module.scss";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";

const PointDetail: React.FC<IPolicyDetail.IProps> = observer(({ ...props }) => {
  const {
    pointStore: { activePoint },
  } = useRootStore();

  return (
    <div>
      <div className="row mb-5">
        <div className="col-12 col-md-7">
          <Card bgColor="dark" customClass="h-100">
            <div className={appStyles.formTitle}>Point Details</div>
          </Card>
        </div>
      </div>
    </div>
  );
});

export { PointDetail };
