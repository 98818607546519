import React, { useRef, forwardRef } from "react";
import { ICheckBox } from "./CheckBox";
import { ReactComponent as CheckedIcon } from "./assets/images/checkedIcon.svg";
import { ReactComponent as UnCheckedIcon } from "./assets/images/uncheckedIcon.svg";
import styles from "./CheckBox.module.scss";
import useCombinedRefs from "hooks/useCombinedRefs";
import concatClasses from "utils/concatClasses";

const CheckBox = forwardRef<HTMLInputElement, ICheckBox.IProps>(
  (
    { className, checked, name, label, onChange = (e) => console.log(e) },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(innerRef, ref);
    const boxId = label + "-" + name;

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    };

    const { container, inputEl, labelEl, labelEl__text } = styles;

    return (
      <div className={concatClasses(container, className)}>
        <input
          ref={combinedRef}
          type="checkbox"
          name={name}
          id={boxId}
          className={inputEl}
          onChange={changeHandler}
        />
        <label htmlFor={boxId} className={labelEl}>
          {checked ? <CheckedIcon /> : <UnCheckedIcon />}
          <p className={labelEl__text}>{label}</p>
        </label>
      </div>
    );
  }
);

export default CheckBox;
