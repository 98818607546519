import {
  DataListMeta,
  PaginatedListPayload,
  StoreListData,
  VehicleModel,
} from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  createVehicleModel,
  getVehicleModels,
  updateVehicleModel,
} from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const VehicleModelStore = types
  .model("VehicleModel", {
    vehicleModels: types.optional(
      types.array(types.frozen<VehicleModel>()),
      []
    ),
    vehicleModelsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    activeVehicleModel: types.optional(types.frozen<VehicleModel>(), {}),
    posting: false,
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveVehicleModel(vehicleModel: VehicleModel) {
      self.activeVehicleModel = cloneDeep(vehicleModel);
    },
  }))
  .actions((self) => ({
    getVehicleModels: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getVehicleModels({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      }
    }),
  }))
  .actions((self) => ({
    createVehicleModel: flow(function* (payload) {
      try {
        self.posting = true;
        const resp: GenericAxiosResponse = yield createVehicleModel(payload);
        self.getVehicleModels({});
        self.posting = false;
        self.rootStore.toastStore.addSuccessToast(
          "Vehicle Model successfully created"
        );
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.posting = false;
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateVehicleModel: flow(function* (vehicleModelId, payload) {
      try {
        self.posting = true;
        const resp: GenericAxiosResponse = yield updateVehicleModel(
          vehicleModelId,
          payload
        );
        self.getVehicleModels({});
        self.posting = false;
        self.rootStore.toastStore.addSuccessToast(
          "Vehicle Model successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.posting = false;
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
