import React, { useEffect, useRef, useState } from "react";
import { IPageHeader } from "./PageHeader";
import styles from "./PageHeader.module.scss";
import { Dropdown, TextInput } from "components";
import concatClasses from "utils/concatClasses";
import useDebounce from "hooks/useDebounce";

const PageHeader: React.FC<IPageHeader.IProps> = ({
  ...props
}): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  useEffect(() => {
    if (Object.keys(selectedFilters).length && props.handleFilterChange) {
      props.handleFilterChange(selectedFilters);
    }
  }, [selectedFilters, props]);

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      if (debouncedSearchTerm !== null && props.handleSearch) {
        props.handleSearch(searchTerm, selectedFilters);
      }
    } else {
      isMounted.current = true;
    }
  }, [debouncedSearchTerm, props, searchTerm, selectedFilters]);

  const renderFilters = () => {
    return props.filters.map((filter, index) => {
      return (
        <Dropdown
          key={`filter_${index}`}
          items={filter.items}
          name={filter.name}
          containerClass={concatClasses("mr-4", styles.filterDropdown)}
          onChange={(val) =>
            setSelectedFilters((prev) => ({
              ...prev,
              [filter.name]: val === "all" ? "" : val,
            }))
          }
          value={selectedFilters[filter.name]}
          withFormik={false}
          bordered={false}
          placeholder={filter.label}
          displayKey={filter.displayKey || "text"}
          hasSearch={filter.hasSearch}
          searchKeys={filter.searchKeys}
          handleSearchChange={(search) => filter.handleSearchChange(search)}
        />
      );
    });
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.pageTitle}>{props.pageTitle}</div>
      <div className={styles.searchContainer}>
        {!props.customRender ? (
          <>
            <TextInput
              style={{ opacity: props.handleSearch ? 1 : 0 }}
              withFormik={false}
              placeholder="Search..."
              icon="search"
              name="search"
              value={searchTerm}
              controlInputContainerClass={styles.searchInput}
              containerClass={styles.searchInputWrapper}
              iconProps={{ width: 14, height: 14 }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="d-flex">{renderFilters()}</div>
          </>
        ) : (
          <>{props.customRender}</>
        )}
      </div>
    </div>
  );
};

export { PageHeader };

PageHeader.defaultProps = {
  filters: [],
  // handleSearch: () => ({}),
};
