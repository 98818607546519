import { PaginatedListPayload, Region, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { createRegion, getRegions, updateRegion } from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

export const RegionStore = types
  .model("Region", {
    activeRegion: types.optional(types.frozen<Region>(), {}),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveRegion(region: Region) {
      self.activeRegion = region;
    },
    getRegions: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getRegions({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }))
  .actions((self) => ({
    createRegion: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createRegion(payload);
        self.getRegions({});
        self.rootStore.toastStore.addSuccessToast(
          "Region successfully created"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateRegion: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateRegion(
          vehicleModelId,
          payload
        );
        self.getRegions({});
        self.rootStore.toastStore.addSuccessToast(
          "Region successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
