import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      {...props}
    >
      <path
        fill={props.fill || "#56CB77"}
        d="M13.65 2.505A7.958 7.958 0 008 .155a8 8 0 100 16c3.73 0 6.84-2.55 7.73-6h-2.08a5.99 5.99 0 01-5.65 4 6 6 0 110-12c1.66 0 3.14.69 4.22 1.78L9 7.155h7v-7l-2.35 2.35z"
      ></path>
    </svg>
  );
}

export default Icon;
