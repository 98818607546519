import { Button, Dropdown, Form, TextInput } from "components";
import { IBadgeForm } from "./BadgeForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { BadgeSchema } from "schemas";
import { BadgeType } from "enums/badge-type.enum";
import CheckBox from "components/Checkbox";
import { useState } from "react";
import BaseText from "components/BaseText";
import { BadgeBasis } from "enums/badge-basis.enum";

const BadgeForm: React.FC<IBadgeForm.IProps> = ({
  badge,
  handleSubmit = () => ({}),
}) => {
  const [showCriteria, setShowCriteria] = useState(!!badge.criteria?.basis);
  const { badgeStore } = useRootStore();
  console.log("badge :>> ", badge);
  const initialValues: IBadgeForm.BadgeFormValues = {
    name: badge?.name,
    description: badge?.description,
    badgeType: badge?.badgeType,
    active: badge?.id ? badge.active : true,
    rank: badge?.rank,
    criteria: badge?.criteria ? { ...badge.criteria } : {},
  };

  const onSubmit = async (
    values: IBadgeForm.BadgeFormValues,
    actions: FormikHelpers<IBadgeForm.BadgeFormValues>
  ) => {
    try {
      let resp;
      if (!badge.id) {
        resp = await badgeStore.createBadge(values);
      } else {
        resp = await badgeStore.updateBadge(badge.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BadgeSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />
          <TextInput
            formikInstance={formikProps}
            name="description"
            type="text"
            label="Description"
            placeholder="Enter description"
          />
          <Dropdown
            withFormik={true}
            formikInstance={formikProps}
            name="badgeType"
            label="Badge Type"
            placeholder="Choose One"
            items={Object.values(BadgeType)}
          />

          <TextInput
            formikInstance={formikProps}
            name="rank"
            label="Rank"
            type="number"
            placeholder="Enter rank"
          />
          <CheckBox
            checked={formikProps.values.active}
            label="Active"
            name="active"
            className="my-3"
            onChange={() =>
              formikProps.setFieldValue("active", !formikProps.values.active)
            }
          />
          <CheckBox
            checked={showCriteria}
            label="Attach a criteria to this badge?"
            name="attachCriteria"
            onChange={() => {
              setShowCriteria((prev) => !prev);
              formikProps.setFieldValue("criteria", {});
            }}
          />
          {showCriteria ? (
            <>
              <BaseText fontSize="21px" className="mt-4 mb-2">
                Criteria
              </BaseText>
              <Dropdown
                withFormik={true}
                formikInstance={formikProps}
                name="criteria.basis"
                label="Badge Basis"
                placeholder="Choose One"
                items={Object.values(BadgeBasis)}
              />
              <TextInput
                formikInstance={formikProps}
                name="criteria.amount"
                label="Amount"
                type="number"
                placeholder="Enter amount"
              />
            </>
          ) : null}

          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { BadgeForm };
