import React, { forwardRef, useEffect, useRef } from "react";
import { Sidebar } from "../index";
import { ILayout } from "./Layout";
import { SideBarItem } from "components/Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { useLocation } from "react-router-dom";
import concatClasses from "utils/concatClasses";
import { eventEmitter, Events } from "utils/event-emitter";
import scrollToTop from "utils/scrollToTop";
import { addInterceptor } from "services/api/config";
import { useRootStore } from "store";

const Layout = forwardRef<HTMLDivElement, ILayout.IProps>(
  ({ children, className, style }, ref): JSX.Element => {
    const location = useLocation();
    const layoutRef = useRef();
    const {
      authStore: { logout },
    } = useRootStore();

    useEffect(() => {
      eventEmitter.subscribe(Events.SCROLL_LAYOUT_TOP, () =>
        layoutRef.current ? scrollToTop(layoutRef.current) : null
      );
      addInterceptor(() => logout());
      return () => {
        eventEmitter.unsubscribe(Events.SCROLL_LAYOUT_TOP);
      };
    }, []);

    const sidebaritems: SideBarItem[] = [
      {
        label: "Overview",
        icon: "overview",
        path: "/overview",
      },
      {
        label: "Customers",
        icon: "users",
        path: "/customers",
      },
      {
        label: "Enterprises",
        icon: "handshake",
        path: "/enterprises",
      },
      {
        label: "Claims",
        icon: "claims",
        path: "/claims",
      },
      {
        label: "Policies",
        icon: "edit",
        path: "/policies",
      },
      {
        label: "Web Policies",
        icon: "edit",
        path: "/web-policies",
      },
      {
        label: "Point History",
        icon: "star",
        path: "/points-history",
      },
      {
        label: "Ride History",
        icon: "star",
        path: "/ride-history",
      },
      {
        label: "Crash Notifications",
        icon: "info",
        path: "/crash-notifications",
      },
      // {
      //   label: "Gift Providers",
      //   icon: "cart",
      //   path: "/gift-providers",
      // },
      {
        label: "Bank",
        icon: "wallet",
        path: "/banks",
      },
      {
        label: "Vehicle Parts",
        icon: "compass",
        path: "/vehicle-parts",
      },
      {
        label: "Vehicle Makes",
        icon: "compass",
        path: "/vehicle-makes",
      },
      {
        label: "Vehicle Models",
        icon: "compass",
        path: "/vehicle-models",
      },
      {
        label: "Businesses",
        icon: "handshake",
        path: "/businesses",
      },
      // {
      //   label: "Accounts",
      //   icon: "user",
      //   path: "/accounts",
      // },
      {
        label: "Products",
        icon: "bag",
        path: "/products",
      },
      {
        label: "Admins",
        icon: "bag",
        path: "/admins",
      },
      {
        label: "Broadcasts",
        icon: "bag",
        path: "/broadcasts",
      },
      {
        label: "Partner Settlements",
        icon: "bag",
        path: "/daily-settlements",
      },
      {
        label: "Service Centers",
        icon: "bag",
        path: "/service-centers",
      },
      {
        label: "Risk Profiles",
        icon: "bag",
        path: "/risk-profiles",
      },
      {
        label: "Insurance Companies",
        icon: "bag",
        path: "/insurance-companies",
      },
      {
        label: "Regions",
        icon: "bag",
        path: "/regions",
      },
      {
        label: "Badges",
        icon: "bag",
        path: "/badges",
      },
      {
        label: "Vehicle Inspections",
        icon: "bag",
        path: "/vehicle-inspection",
      },
    ];

    const [sidebarOpen, setsidebarOpen] = React.useState(true);

    return (
      <div className={styles.layoutContainer}>
        <Sidebar
          isOpen={sidebarOpen}
          setIsOpen={setsidebarOpen}
          items={sidebaritems.map((sidebarItem) => ({
            ...sidebarItem,
            active: location.pathname === sidebarItem.path,
          }))}
        />
        <div className="flex-grow-1 h-100 d-flex flex-column">
          <div
            ref={layoutRef}
            className={concatClasses(styles.pageContainer, className || "")}
            style={style}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

const PageWrapper = ({ children }) => {
  return <div className={styles.pageWrapper}>{children}</div>;
};

export { Layout, PageWrapper };

Layout.defaultProps = {
  selectedFilters: [],
  filters: [],
  handleSearch: () => ({}),
};
