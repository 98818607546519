import React from "react";
import { color } from "theme";

function CalendarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 22"
      {...props}
    >
      <path
        fill={props.fill}
        fillRule="evenodd"
        d="M13.793 0a.75.75 0 01.75.75l.001.848c1.46.1 2.673.6 3.531 1.46.937.941 1.43 2.294 1.425 3.917v9.123c0 3.332-2.116 5.402-5.521 5.402H5.521C2.116 21.5 0 19.401 0 16.022V6.973c0-3.143 1.887-5.16 4.965-5.375V.75a.75.75 0 011.5 0v.829h6.578V.75a.75.75 0 01.75-.75zM18 8.904H1.5v7.118C1.5 18.588 2.928 20 5.521 20h8.458C16.572 20 18 18.614 18 16.098V8.904zm-3.799 6.292a.75.75 0 010 1.5.754.754 0 01-.754-.75c0-.414.331-.75.745-.75h.01zm-4.437 0a.75.75 0 010 1.5.754.754 0 01-.754-.75c0-.414.33-.75.745-.75h.009zm-4.447 0a.75.75 0 010 1.5.755.755 0 01-.755-.75c0-.414.332-.75.746-.75h.009zM14.2 11.31a.75.75 0 010 1.5.754.754 0 01-.754-.75c0-.414.331-.75.745-.75h.01zm-4.437 0a.75.75 0 010 1.5.754.754 0 01-.754-.75c0-.414.33-.75.745-.75h.009zm-4.447 0a.75.75 0 010 1.5.755.755 0 01-.755-.75c0-.414.332-.75.746-.75h.009zm7.726-8.231H6.465v.962a.75.75 0 01-1.5 0v-.94C2.725 3.29 1.5 4.649 1.5 6.974v.431H18v-.43c.004-1.236-.328-2.196-.987-2.856-.579-.58-1.424-.927-2.469-1.016v.939a.75.75 0 01-1.5 0l-.001-.962z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CalendarIcon;

CalendarIcon.defaultProps = {
  width: 20,
  height: 22,
  fill: color.palette.black,
};
