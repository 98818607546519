import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { RegionForm, Modal, Table, CogList } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Region } from "interfaces";
import styles from "./RegionList.module.scss";
import { IRegionsList } from "./RegionsList";
import appStyles from "styles/app.module.scss";

const headersList = [
  { header: "Name" },
  { header: "Payment Provider" },
  { header: "Underwriter" },
  { header: "Currency" },
  { header: "" },
];

const RegionsList: React.FC<IRegionsList.IProps> = observer(
  (props: IRegionsList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { regionStore } = useRootStore();
    const {
      activeRegion,
      setActiveRegion,
      data: {
        listItems: regions,
        meta: { page, totalCount, limit },
      },
    } = regionStore;

    console.log("regions", regions);

    const getCogActions = (region) => [
      {
        label: "Edit",
        fn: () => {
          setActiveRegion(region);
          setShowModal(true);
        },
      },
    ];

    const renderRegions = (props: { item: Region; id: number }) => {
      const { item: region, id } = props;
      return (
        <tr key={`region_${id}`}>
          <td>{region.name}</td>
          <td>{region.paymentProvider}</td>
          <td>{region.underwriter}</td>
          <td>{region.currency}</td>
          <td>
            <CogList actions={getCogActions(region)} />
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={regions}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderRegions}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await regionStore.getRegions({ page });
          }}
          showAddBtn
          handleAdd={() => {
            setActiveRegion({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveRegion({});
          }}
          show={showModal}
          title={`${activeRegion?.id ? "Edit" : "Create"} Region `}
        >
          <RegionForm
            region={activeRegion}
            handleSubmit={() => {
              setShowModal(false);
              setActiveRegion({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { RegionsList };
