import axios from "axios";
import { CLOUDINARY_URL } from "constants/URLS";

const cloudinaryAxios = axios.create({
  baseURL: CLOUDINARY_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

export const uploadImage = (payload) => {
  return cloudinaryAxios.post(CLOUDINARY_URL, {
    ...payload,
    api_key: "484846517688282",
  });
};
