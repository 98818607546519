import React from "react";

function MarkerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      fill="none"
      viewBox="0 0 17 20"
      {...props}
    >
      <path
        fill={props.fill || "#307EC4"}
        fillRule="evenodd"
        d="M8.25 0c4.549 0 8.25 3.729 8.25 8.313 0 5.635-6.456 11.187-8.25 11.187C6.456 19.5 0 13.948 0 8.313 0 3.729 3.701 0 8.25 0zm0 1.5C4.528 1.5 1.5 4.557 1.5 8.313c0 4.779 5.624 9.435 6.75 9.683C9.376 17.747 15 13.091 15 8.313 15 4.557 11.972 1.5 8.25 1.5zM8.251 5a3.254 3.254 0 013.25 3.251 3.253 3.253 0 01-3.25 3.249 3.253 3.253 0 01-3.25-3.249A3.254 3.254 0 018.251 5zm0 1.5a1.751 1.751 0 101.75 1.751c0-.966-.785-1.751-1.75-1.751z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MarkerIcon;
