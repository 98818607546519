import React from "react";
import { observer } from "mobx-react-lite";
import { Card } from "components";
import styles from "./VehicleInspectionDetail.module.scss";
import { IVehicleInspectionDetail } from "./VehicleInspectionDetail";
import { formatDate } from "utils/dates";
import { VehicleCapture } from "components/Vehicles/VehicleCapture";

const VehicleInspectionDetail: React.FC<IVehicleInspectionDetail.IProps> = observer(
  (props: IVehicleInspectionDetail.IProps) => {
    const { vehicleInspection } = props;

    const { vehicleInspectionTr } = styles;

    const groups = [
      [
        {
          text: "Registration Number",
          value: vehicleInspection.registrationNumber,
        },
        { text: "Color", value: vehicleInspection.color },
        {
          text: "Date Created",
          value: formatDate(vehicleInspection.createdAt),
        },
      ],
      [
        {
          text: "Business Name",
          value: vehicleInspection.businessName
            ? vehicleInspection.businessName
            : "-",
        },
        {
          text: "Verified",
          value: vehicleInspection.isVerified ? "Yes" : "No",
        },
        { text: "Year", value: vehicleInspection.year },
      ],
    ];

    const renderNotes = () => (
      <Card bgColor="dark" customClass="px-3 px-md-5 pb-5 mb-3">
        <span className="text-muted" style={{ lineHeight: "24px" }}>
          Notes
        </span>
        <div className="mt-3">
          {vehicleInspection?.notes?.map((note) => (
            <div className="mb-2">{note}</div>
          ))}
        </div>
      </Card>
    );
    return (
      <>
        <table className="w-100">
          {groups.map((group) => {
            return (
              <tr className={vehicleInspectionTr}>
                {group.map((item: { text: string; value: any }) => (
                  <td className="pl-0">
                    <div className="text-muted mb-3">{item.text}</div>
                    <div className="text-primary">{item.value}</div>
                  </td>
                ))}
              </tr>
            );
          })}
        </table>
        {vehicleInspection?.notes?.length ? renderNotes() : null}

        <VehicleCapture capture={vehicleInspection.captures} />
      </>
    );
  }
);

export { VehicleInspectionDetail };
