import { PaginatedListPayload, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import cloneDeep from "utils/cloneDeep";
import NProgress from "nprogress";
import { defaultStoreList } from "utils/constants";
import { getDailySettlements, updateSettlement } from "services/api";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const DailySettlementStore = types
  .model("DailySettlement", {
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    getDailySettlements: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getDailySettlements({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
  }))
  .actions((self) => ({
    updateSettlement: flow(function* (settlementId, payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield updateSettlement(
          settlementId,
          payload
        );
        self.getDailySettlements({});
        self.rootStore.toastStore.addSuccessToast(
          "Settlement successfully updated"
        );
        NProgress.done();
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        NProgress.done();
        throw error;
      }
    }),
  }));
