import { IModal } from "./Modal";
import { Modal as BootstrapModal } from "react-bootstrap";
import styles from "./Modal.module.scss";
import concatClasses from "utils/concatClasses";

const Modal: React.FC<IModal.IProps> = ({ scrollable = true, ...props }) => {
  const {
    fullScreenModal,
    fullScreenModal__content,
    modalHeader,
    modalHeader__title,
  } = styles;

  return (
    <div className={styles.modalContainer}>
      <BootstrapModal
        show={props.show}
        animation={true}
        backdrop={true}
        onHide={props.handleHide}
        aria-labelledby="custom-modal"
        dialogClassName={concatClasses(
          props.fullscreen ? fullScreenModal : "",
          props.customClass
        )}
        centered={true}
        contentClassName={props.fullscreen ? fullScreenModal__content : ""}
        scrollable={scrollable}
        size={props.size}
      >
        <BootstrapModal.Header className={modalHeader} closeButton>
          <div className="d-flex justify-content-between w-100 flex-column flex-md-row">
            <BootstrapModal.Title className={modalHeader__title}>
              {props.title}
            </BootstrapModal.Title>
            {props.headerActions}
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>{props.children}</BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export { Modal };
