// import "styles/app.scss";
import "styles/global.scss";
import { RootStoreProvider } from "store";

import { BrowserRouter } from "react-router-dom";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes } from "components/Routes";
import { setToken } from "services/api";
import { ToastNotifications } from "components";
import { QueryClient, QueryClientProvider } from "react-query";

function App(): JSX.Element {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(`Bearer ${token}`);
    }
  }, []);

  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <RootStoreProvider>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <ToastNotifications />
            <Routes />
          </div>
        </QueryClientProvider>
      </RootStoreProvider>
    </BrowserRouter>
  );
}

export default observer(App);
