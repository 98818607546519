import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getBusinesses = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/businesses${params}`);
};

export const getApiKeys = (businessId: number) => {
  return api.get(`/apikey?businessId=${businessId}`);
};

export const createApiKey = (payload) => {
  return api.post(`/apikey`, payload);
};

export const createBusiness = (payload) => {
  return api.post(`/businesses`, payload);
};
export const updateBusiness = (businessId, payload) => {
  return api.put(`/businesses/${businessId}`, payload);
};
