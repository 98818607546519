import axios from "axios";
import { BASE_URL } from "constants/URLS";
import { PaginatedListPayload } from "interfaces/PaginatedListPayload";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

export const externalClaimsApi = axios.create({
  baseURL: 'http://etapclaims-env.eba-3be2gps4.us-west-2.elasticbeanstalk.com',
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

export const addInterceptor = (logoutFn?: Function) =>
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        logoutFn();
      }
      // throw error;
      return Promise.reject(error);
    }
  );

export const defaultPaginatedList: PaginatedListPayload = {
  limit: 20,
  page: 1,
  orderBy: "DESC",
};

export default api;
