import api from "./config";

export const getBanks = ({
  page = 1,
  limit = 20,
  query = "",
  sortBy = "",
  orderBy = "ASC",
}) => {
  return api.get(`/banks?limit=${limit}&query=${query}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`)
}