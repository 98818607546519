import { Button, Dropdown, TextInput, Form } from "components";
import { Modal } from "components/Modal";
import { FormikHelpers } from "formik";
import { VehiclePart } from "interfaces";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import VehiclePartSchema from "schemas/vehiclePart";
import { useRootStore } from "store";
import cloneDeep from "utils/cloneDeep";
import { PartCategories } from "utils/vehicle-part-categories";
import { VehiclePartValues } from "./VehiclePartReview";

const VehiclePartReview = observer(
  ({
    vehiclePart,
    onHide,
    isNew,
    handleSubmit,
  }: {
    vehiclePart?: VehiclePart;
    isNew?: boolean;
    onHide?: () => any;
    handleSubmit?: (vehiclePart?: VehiclePart) => any;
  }) => {
    const { name, year, partsCategory, make, model } = vehiclePart || {};

    const {
      vehicleModelStore: {
        getVehicleModels,
        data: { listItems: vehicleModels },
        setQuery: setModelQuery,
        resetQuery: resetModelQuery,
      },
      makeStore: { getMakes, makes },
      vehiclePartsStore: { createPart, updatePart },
    } = useRootStore();

    useEffect(() => {
      getMakes({});
      if (vehiclePart?.id) {
        setModelQuery({ make: vehiclePart.make?.id });
        getVehicleModels({});
      }
      return () => resetModelQuery();
    }, [vehiclePart]);

    const initialValues: VehiclePartValues = {
      name,
      partsCategoryId: partsCategory?.id,
      price: vehiclePart?.price ? +vehiclePart.price : 0,
      side: vehiclePart?.side,
      year: year || new Date().getFullYear(),
      make: make?.id,
      model: model?.id,
    };

    const onSubmit = async (
      values: VehiclePartValues,
      actions: FormikHelpers<VehiclePartValues>
    ) => {
      try {
        if (!vehiclePart?.id) {
          await createPart(values);
        } else {
          await updatePart(vehiclePart.id, values);
        }
        onHide();
      } catch (error) {
        actions.setSubmitting(false);
      }
    };

    const handleMakeChange = (make) => {
      setModelQuery({ make });
      getVehicleModels({});
    };

    const makeList = cloneDeep(makes);
    if (vehiclePart?.id) {
      const { make: vehicleMake } = vehiclePart;
      const selectedMakeIdx = makes.findIndex(
        (make) => vehicleMake.id === make?.id
      );
      if (selectedMakeIdx === -1) {
        makeList.push(vehicleMake);
      }
    }

    return (
      <Modal
        handleHide={onHide}
        show={vehiclePart || isNew ? true : false}
        title={`${isNew ? "Create" : "Edit"} Vehicle Part`}
        scrollable={false}
      >
        {(vehiclePart || isNew) && (
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={VehiclePartSchema}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit}>
                <div className="row px-3">
                  {/* <div className="col-3">
                  <Image label={label} src={""} height="200px" />
                </div> */}
                  <div className="col-12 px-0">
                    <TextInput
                      formikInstance={formikProps}
                      name="name"
                      label="Name"
                      placeholder="Enter name"
                    />
                    <Dropdown
                      withFormik={true}
                      formikInstance={formikProps}
                      name="partsCategoryId"
                      label="Category"
                      placeholder="Choose One"
                      displayKey={"text"}
                      items={PartCategories}
                    />
                    <Dropdown
                      items={makeList}
                      name="make"
                      withFormik
                      formikInstance={formikProps}
                      label="Make"
                      displayKey="name"
                      single
                      hasSearch
                      searchKeys={["name"]}
                      placeholder="Choose One"
                      onChange={handleMakeChange}
                      handleSearchChange={(search) =>
                        getMakes({ query: search })
                      }
                    />
                    <Dropdown
                      items={vehicleModels}
                      name="model"
                      withFormik
                      formikInstance={formikProps}
                      label="Model"
                      displayKey="name"
                      single
                      hasSearch
                      searchKeys={["name"]}
                      placeholder="Choose One"
                      disabled={!formikProps.values.make}
                    />
                    <TextInput
                      formikInstance={formikProps}
                      name="side"
                      label="Side (front, back, left or right)"
                      type="text"
                      placeholder="Enter front, back, left or right"
                    />
                    <div className="row mb-3">
                      <div className="col-6">
                        <TextInput
                          formikInstance={formikProps}
                          name="price"
                          label="Price"
                          type="number"
                          placeholder="10"
                        />
                      </div>
                      <div className="col-6">
                        <TextInput
                          formikInstance={formikProps}
                          type="number"
                          name="year"
                          label="Year"
                        />
                      </div>
                    </div>

                    <Button
                      label={"Save Changes"}
                      customClass=""
                      type="submit"
                      disabled={
                        !formikProps.isValid || formikProps.isSubmitting
                      }
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        )}
      </Modal>
    );
  }
);

export { VehiclePartReview };
