import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getRegions = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/regions/admin${params}`);
};

export const createRegion = (payload) => {
  return api.post(`/regions`, payload);
};
export const updateRegion = (regionId, payload) => {
  return api.put(`/regions/${regionId}`, payload);
};
