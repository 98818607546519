import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Login.module.scss";
import Logo from "../../assets/img/Logo";
import { Card } from "components/card";
import { Button, Form, TextInput } from "components";
import { LoginSchema } from "schemas";
import { useRootStore } from "store";
import { FormikHelpers } from "formik";
import { LoginFormValues } from "./Login";

const Login: React.FC = () => {
  const history = useHistory();
  const { authStore } = useRootStore();

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => {
    try {
      await authStore.signIn(values);
      await authStore.getUserProfile();
      history.push("/");
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={`row ${styles.loginPage}`}>
      <div className="col-12 col-md-4 mx-auto text-center">
        <Logo />
        <div className={styles.intro}>
          Insurance As Easy As Taking a Picture. Just ETAP it
        </div>
        <Card bgColor="dark" customClass="p-5">
          <div className={styles.formTitle}>Sign up as Admin</div>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={LoginSchema}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit}>
                <TextInput
                  formikInstance={formikProps}
                  name="email"
                  type="email"
                  label="Email"
                />
                <TextInput
                  formikInstance={formikProps}
                  name="password"
                  type="password"
                  label="Password"
                />

                <Button
                  label="Log in"
                  type="submit"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                  customClass={styles.loginBtn}
                />
              </form>
            )}
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
