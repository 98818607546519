import React from "react";

function StarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M9.75 1.5a.471.471 0 00-.434.263L7.49 5.414A1.979 1.979 0 016 6.484l-4.088.589a.467.467 0 00-.39.323.443.443 0 00.12.465l2.957 2.84c.47.453.685 1.106.573 1.745l-.696 4.01a.446.446 0 00.184.447.473.473 0 00.517.039l3.655-1.895a2.003 2.003 0 011.836 0l3.654 1.894a.483.483 0 00.519-.038.446.446 0 00.184-.447l-.698-4.01a1.952 1.952 0 01.573-1.745l2.956-2.84a.439.439 0 00.12-.465.464.464 0 00-.388-.323L13.5 6.484a1.978 1.978 0 01-1.49-1.071l-1.827-3.65A.47.47 0 009.75 1.5zm-4.803 17c-.413 0-.823-.13-1.174-.386a1.945 1.945 0 01-.774-1.915l.696-4.01a.455.455 0 00-.135-.406L.604 8.943a1.939 1.939 0 01-.51-2.006 1.96 1.96 0 011.604-1.348L5.786 5a.475.475 0 00.362-.257l1.827-3.652A1.972 1.972 0 019.75 0c.758 0 1.438.418 1.775 1.091l1.828 3.651a.476.476 0 00.36.258l4.089.589a1.96 1.96 0 011.603 1.348 1.94 1.94 0 01-.51 2.006l-2.956 2.84a.453.453 0 00-.134.405l.697 4.011a1.945 1.945 0 01-.776 1.915 1.973 1.973 0 01-2.095.158l-3.653-1.893a.498.498 0 00-.457 0l-3.653 1.894a1.99 1.99 0 01-.921.227z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default StarIcon;
