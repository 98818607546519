import React, { useState } from "react";
import styles from "./Dropdown.module.scss";
import { IDropdown } from "./Dropdown";
import Fuse from "fuse.js";
import { FormControl, Icon } from "components";
import { color } from "theme";
import concatClasses from "utils/concatClasses";
import extractFormikValue from "utils/extract-formik-value";

export const Dropdown: React.FC<IDropdown.IProps> = ({ ...props }) => {
  const { name, value, withFormik, formikInstance } = props;
  const controlValue = withFormik
    ? extractFormikValue(name, formikInstance.values)
    : value;
  const { items } = props;
  const [focus, setfocus] = useState(false);
  const [selected, setSelected] = useState<IDropdown.IState[]>(
    props.single ? [controlValue] : [...controlValue]
  );
  const [searchTerm, setsearchTerm] = useState("");

  const hasValue = !!selected.length && !selected.some((s) => s === undefined);

  const onChangeHandler = (e) => {
    if (withFormik) {
      formikInstance.setFieldValue(name, e);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const addSelected = (item: any) => {
    item =
      typeof item !== "object"
        ? item
        : item.overrideIdKey || item[props.idKey || "id"];
    const exist = selected.includes(item);

    if (props.single) {
      setSelected([item]);
      onChangeHandler(item);
      setsearchTerm("");
      setfocus(false);
      return;
    }
    if (exist) {
      setSelected((prev) => {
        const updatedList = prev.filter((prevItem) => prevItem !== item);
        onChangeHandler(updatedList);
        return updatedList;
      });
      return;
    } else {
      setSelected((prev) => {
        onChangeHandler([...prev, item]);
        return [...prev, item];
      });
    }
  };

  const searchItems = () => {
    const options: any = {
      includeScore: false,
    };
    if (props.searchKeys) {
      options.keys = props.searchKeys;
    }

    const fuse = new Fuse([...items], options);

    const result = fuse.search(searchTerm);

    console.log(result);
    return result.map((i) => i.item);
  };

  const filteredItems = props.hasSearch && searchTerm ? searchItems() : items;

  const renderSelected = () => {
    if (!props.customRender) {
      if (
        !props.displayKey ||
        props.items.every((i) => typeof i === "string")
      ) {
        return selected.toString();
      } else {
        return items
          .filter((i) => selected.includes(i[props.idKey || "id"]))
          .map((s: any) => s[props.displayKey || "text"])
          .join(", ");
      }
    }
    return props.customRender();
  };
  const renderDisplayText = (x) => {
    if (typeof x !== "object") {
      return x;
    }
    if (props.displayKey) {
      let val = x[props.displayKey];
      if ([null, undefined].includes(val) && props.secondaryDisplayKey) {
        val = x[props.secondaryDisplayKey];
      }
      return val || x.title;
    }
    return x[props.secondaryDisplayKey || "text"];
  };

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (props.handleSearchChange) {
      await props.handleSearchChange(value, selected);
    }
    setsearchTerm(value);
  };

  return (
    <FormControl
      {...props}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setfocus(false);
          setsearchTerm("");
        }
      }}
      style={{ width: props.width }}
    >
      <div>
        <div
          style={{
            borderBottom: "none",
            background: hasValue ? color.palette.deepGrey : "",
          }}
          className={`${styles.dropdownComponent} ${props.dropdownClass} `}
          onClick={() => setfocus(!props.disabled ? !focus : false)}
        >
          <div className={styles.selectorContainer}>
            <span
              className={concatClasses(
                selected.length && selected.every((i) => !!i)
                  ? styles.label
                  : styles.placeholder,
                "mr-4"
              )}
            >
              {selected.length &&
              selected.every((i) => ![null, undefined].includes(i))
                ? renderSelected()
                : props.placeholder}
            </span>
            <Icon icon="angleDown" />
          </div>
        </div>

        <div className={`${styles.dropdownListCon}`}>
          <div
            className={`${styles.dropdownList} ${styles.dropdownOverlayCon}`}
            style={{ display: focus ? "block" : "none" }}
          >
            {props.hasSearch ? (
              <div
                style={{ display: focus ? "block" : "none" }}
                className={styles.searchContainer}
              >
                <input
                  placeholder={props.searchPlaceholder}
                  onChange={handleSearchChange}
                  // onChange={(e) => setsearchTerm(e.target.value)}
                  className={styles.search}
                ></input>
              </div>
            ) : null}
            {filteredItems.map((x: any, id) => (
              <div
                key={id}
                onClick={() => {
                  addSelected(x);
                }}
                className={`${styles.dropdownitems} ${
                  selected.includes(
                    typeof x !== "object" ? x : x[props.idKey || "id"]
                  )
                    ? styles.selectedItem
                    : ""
                } d-flex flex-row align-items-center`}
              >
                <span className={styles.items}>{renderDisplayText(x)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  onChange: () => ({}),
  onBlur: () => ({}),
  single: true,
};
