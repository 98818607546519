import {
  PaginatedListPayload,
  InsuranceCompany,
  StoreListData,
} from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  createInsuranceCompany,
  getInsuranceCompanies,
  updateInsuranceCompany,
} from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

export const insuranceCompaniesStore = types
  .model("InsuranceCompany", {
    activeInsuranceCompany: types.optional(
      types.frozen<InsuranceCompany>(),
      {}
    ),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveInsuranceCompany(insuranceCompany: InsuranceCompany) {
      self.activeInsuranceCompany = insuranceCompany;
    },
    getInsuranceCompanies: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getInsuranceCompanies({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }))
  .actions((self) => ({
    createInsuranceCompany: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createInsuranceCompany(
          payload
        );
        self.getInsuranceCompanies({});
        self.rootStore.toastStore.addSuccessToast(
          "Insurance company successfully created"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateInsuranceCompany: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateInsuranceCompany(
          vehicleModelId,
          payload
        );
        self.getInsuranceCompanies({});
        self.rootStore.toastStore.addSuccessToast(
          "Insurance company successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
