import { PaginatedListPayload, VehicleInspection, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { getVehicleInspection } from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";
import NProgress from "nprogress";

export const VehicleInspectionStore = types
  .model("VehicleInspection", {
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
    activeVehicleInspection: types.optional(types.frozen<VehicleInspection>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveVehicleInspection(vehicleInspection: VehicleInspection) {
      self.activeVehicleInspection = vehicleInspection;
    },
    getVehicleInspection: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getVehicleInspection({
          ...self.query,
          ...payload,
        });
        
        const { data } = resp;
        const totalCount = Object.keys(data).length
        self.data = {
          listItems: data,
          meta: {
            limit: 20,
            totalCount,
            page:1,
            key: []
          }
        };
        return resp.data;
      } catch (error) {
        console.log(error);
      } finally {
        NProgress.done();
      }
    }),
  }));
