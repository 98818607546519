import { ContentLoader, Layout } from "components";
import { Suspense, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "utils/routes";

const ProtectedRoutes = () => {
  const layoutRef = useRef();
  return (
    <Switch>
      <Layout className="p-0" ref={layoutRef}>
        <Suspense fallback={<ContentLoader />}>
          {routes.map(({ component: Component, path, exact }) => (
            <Route path={`/${path}`} key={path} exact={exact}>
              <Component />
            </Route>
          ))}
        </Suspense>
      </Layout>
    </Switch>
  );
};

export { ProtectedRoutes };
