import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import NProgress from "nprogress";
import { createSignature } from "services/api";
import { uploadImage } from "services/cloudinary";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const ImagesStore = types
  .model("Images", {})
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    createSignature: flow(function* (publicId) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield createSignature(publicId);
        return resp.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      } finally {
        NProgress.done();
      }
    }),
    uploadImage: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield uploadImage(payload);
        return resp.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      } finally {
        NProgress.done();
      }
    }),
  }));
