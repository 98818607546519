import React from "react";
import styles from "./AddressInput.module.scss";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { IAddressInput } from "./AddressInput";
import { FormControl } from "components/FormControl";
import appStyles from "styles/app.module.scss";
import { getAddressData } from "utils/geocode";
import { color } from "theme";

export const AddressInput: React.FC<IAddressInput.IProps> = ({ ...props }) => {
  const { name, value, withFormik, formikInstance } = props;
  const controlValue = withFormik ? formikInstance.values[name] : value;
  const onChangeHandler = (e) =>
    withFormik ? formikInstance.setFieldValue(name, e) : props.onChange(e);

  const onBlurHandler = (e) =>
    withFormik ? formikInstance.setFieldTouched(name, e) : props.onBlur(e);

  return (
    <FormControl {...props}>
      <GooglePlacesAutocomplete
        suggestionsClassNames={{
          container: styles.customSugesstions,
          suggestion: "",
          suggestionActive: "custom-suggestions-classname--active",
        }}
        onSelect={async (payload) => {
          onChangeHandler(payload.description);
          if (props.postChange) {
            const data = await geocodeByPlaceId(payload.place_id);
            let payloadData = { ...payload };
            if (data.length) {
              payloadData = {
                ...payload,
                ...getAddressData(data),
              };
            }
            props.postChange(payloadData);
          }
        }}
        renderInput={(addressProps) => {
          return (
            <input
              {...addressProps}
              onBlur={onBlurHandler}
              autocomplete="chrome-off"
              className={`${appStyles.textInput} ${props.inputClass || ""}`}
              placeholder={props.placeholder}
              ref={props.setRef}
              style={{
                backgroundColor: !controlValue
                  ? props.inputBgColor
                  : color.palette.deepGrey,
              }}
            />
          );
        }}
        initialValue={controlValue}
        loader={<div className="d-none"></div>}
      />
    </FormControl>
  );
};

AddressInput.defaultProps = {
  postChange: () => ({}),
  onChange: () => ({}),
  onBlur: () => ({}),
  withFormik: true,
};
