import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  CogList,
  Modal,
  PolicyDetail,
  Table,
  TextInput,
  Switch,
} from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { ExternalPolicy, Policy } from "interfaces";
import { getVerboseName } from "utils/vehicle";
import { formatDate } from "utils/dates";
import { IPoliciesList } from "./PoliciesList";
import appStyles from "styles/app.module.scss";
import getFullName from "utils/fullName";
import { UserRole } from "enums/userRole.enum";
import { color } from "theme";

const PoliciesList: React.FC<IPoliciesList.IProps> = observer(
  (props: IPoliciesList.IProps) => {
    const { onToggleExternal, showExternal } = props;
    const [showModal, setShowModal] = useState(false);
    const [showRenewalModal, setShowRenewalModal] = useState(false);
    const [busy, setBusy] = useState(false);
    const [numOfRenewals, setNumOfRenewals] = useState();
    const { policyStore, authStore } = useRootStore();
    const { user } = authStore;
    const {
      setActivePolicy,
      getPolicies,
      renewPolicy,
      hasCreationError,
      data: {
        listItems: policies,
        meta: {
          page,
          totalCount,
          limit,
          pendingRenewalPolicies,
          activePolicies,
          expiredPolicies,
        },
      },
    } = policyStore;

    const headersList = useMemo(
      () =>
        !showExternal
          ? [
              { header: "Vehicle" },
              { header: "Customer" },
              { header: "Start Date" },
              { header: "End Date" },
              { header: "Policy Number" },
              { header: "Band" },
              { header: "" },
            ]
          : [
              { header: "Customer" },
              { header: "Insurance Company" },
              { header: "Policy Number" },
              { header: "Expiry Date" },
            ],
      [showExternal]
    );

    const isSuperUser = user.role === UserRole.SUPER_ADMIN;
    const handleToggleExternal = useCallback(
      (value: boolean) => {
        onToggleExternal(value);
        if (value) {
          policyStore.getExternalPolicies({ page: 1 });
        } else {
          policyStore.getPolicies({ page: 1 });
        }
      },
      [policyStore, onToggleExternal]
    );

    const getCogActions = (policy) => [
      {
        label: "Renew Advance",
        fn: () => {
          setActivePolicy(policy);
          setShowRenewalModal(true);
        },
      },
    ];

    const renderPolicies = (props: { item: Policy; id: number }) => {
      const { item: policy, id } = props;
      const showError = hasCreationError(policy);
      return (
        <tr
          key={`policy_${id}`}
          style={{
            backgroundColor: showError
              ? color.palette.lightRed
              : color.palette.white,
          }}
          onClick={() => {
            setActivePolicy(policy);
            setShowModal(true);
          }}
        >
          <td>
            <div>
              <div>
                {policy.vehicleInformation
                  ? getVerboseName(policy.vehicleInformation)
                  : "-"}
              </div>
              <span className="text-muted mt-2">
                {policy.vehicleInformation?.registrationNumber}
              </span>
            </div>
          </td>
          <td>{policy.user?.email || "-"}</td>
          <td>{formatDate(policy.startDate, "ll")}</td>
          <td className="text-danger">{formatDate(policy.expiryDate, "ll")}</td>
          <td>{policy.policyNumber}</td>
          <td>{policy.band}</td>{" "}
          {isSuperUser ? (
            <td>
              <CogList actions={getCogActions(policy)} />
            </td>
          ) : null}
        </tr>
      );
    };

    const renderExternalPolicies = (props: {
      item: ExternalPolicy;
      id: number;
    }) => {
      const { item: policy, id } = props;
      return (
        <tr key={`policy_${id}`}>
          <td>{policy.user ? getFullName(policy.user) : "-"}</td>
          <td>{policy.insuranceCompany?.name}</td>
          <td>{policy.policyNumber}</td>
          <td>{formatDate(policy.expiryDate, "ll")}</td>
        </tr>
      );
    };

    const handlePolicyRenewal = async () => {
      if (busy) {
        return;
      }
      setBusy(true);
      await renewPolicy(numOfRenewals ? +numOfRenewals : 0);
      setBusy(false);
      setShowRenewalModal(false);
    };

    const displayedHeaders =
      isSuperUser || showExternal ? headersList : [...headersList].slice(0, -1);

    return (
      <>
        <Table
          listItems={policies}
          headersList={displayedHeaders}
          totalCount={totalCount}
          renderItems={!showExternal ? renderPolicies : renderExternalPolicies}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={+(page || 1)}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            (await showExternal)
              ? policyStore.getExternalPolicies({ page })
              : policyStore.getPolicies({ page });
          }}
          topStats={
            !showExternal
              ? [
                  {
                    label: "Active",
                    value: activePolicies,
                    className: appStyles.successCard,
                  },
                  { label: "Pending Renewal", value: pendingRenewalPolicies },

                  {
                    label: "Expired",
                    value: expiredPolicies,
                    className: appStyles.dangerCard,
                  },
                ]
              : []
          }
          headerContent={
            <Switch
              label="Show External Policies"
              checked={showExternal}
              onClick={handleToggleExternal}
              customClass="mb-0 mr-4"
            />
          }
          showRefresh={!showExternal}
          handleRefresh={!showExternal ? () => getPolicies({}) : () => ({})}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActivePolicy({});
          }}
          show={showModal}
          title={`Manage Policy`}
          size="xl"
        >
          <PolicyDetail />
        </Modal>
        <Modal
          handleHide={() => {
            setShowRenewalModal(false);
            setActivePolicy({});
          }}
          show={showRenewalModal}
          title={`Renew Policy`}
        >
          <div>
            <TextInput
              name="name"
              type="number"
              label="Number of Renewals"
              placeholder="Enter number"
              value={numOfRenewals}
              withFormik={false}
              onChange={(e) => setNumOfRenewals(e.target.value)}
            />

            <Button
              label="Save"
              disabled={!numOfRenewals || busy}
              customClass="mt-4"
              type="button"
              onClick={handlePolicyRenewal}
            />
          </div>
        </Modal>
      </>
    );
  }
);

export { PoliciesList };
