import { DataListMeta, Customer } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { createAdmin, getAdmins, updateAdmin } from "services/api";
import { RootStore } from "..";

export const AdminStore = types
  .model("Admin", {
    admins: types.optional(types.array(types.frozen<Customer>()), []),
    adminsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
    activeAdmin: types.optional(types.frozen<Customer>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setActiveAdmin(admin: Customer) {
      self.activeAdmin = admin;
    },
    getAdmins: flow(function* (payload) {
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getAdmins(payload);
        const { data, ...rest } = resp.data.data;
        self.admins = data;
        self.adminsMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        self.fetching = false;
      }
    }),
  }))
  .actions((self) => ({
    createAdmin: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createAdmin(payload);
        self.getAdmins({});
        self.rootStore.toastStore.addSuccessToast("Admin successfully created");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateAdmin: flow(function* (adminId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateAdmin(adminId, payload);
        self.getAdmins({});
        self.rootStore.toastStore.addSuccessToast("Admin successfully updated");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
