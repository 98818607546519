import * as Yup from "yup";

const UserProfileSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  dateOfBirth: Yup.string().required("Required"),
  homeAddress: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  presetBand: Yup.number().nullable(),
});

export default UserProfileSchema;
