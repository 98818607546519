import { Card, Image } from "components";
import { IClaimCapture } from "./ClaimCapture";
import appStyles from "styles/app.module.scss";
import styles from "./ClaimCapture.module.scss";
import { getVerboseName } from "utils/vehicle";
import { ClaimCapture as ClaimCaptureType } from "interfaces";

import { useRootStore } from "store";
import concatClasses from "utils/concatClasses";

const ClaimCapture: React.FC<IClaimCapture.IProps> = ({ claim }) => {
  const {
    galleryStore: { setItems, setVisibility },
  } = useRootStore();

  const mainDriver = claim.drivers?.find((driver) => !!driver.userId);
  const capturesToUse = mainDriver ? mainDriver.captures : claim.captures;
  const hasCaptures = !!capturesToUse?.length;
  const renderCaptures = () =>
    hasCaptures ? (
      capturesToUse?.map((capture: ClaimCaptureType) => (
        <Image
          key={`capture_${capture.id}`}
          width="69px"
          height="64px"
          label={`Capture ${capture.id}`}
          customClass="mr-2 mb-2"
          src={mainDriver ? capture?.image?.url : capture?.capture?.url}
        />
      ))
    ) : (
      <div className="text-center w-100">No Captures found</div>
    );

  return (
    <Card bgColor="dark" customClass="h-100 pb-5">
      <div
        className={concatClasses(
          appStyles.formTitle,
          "d-flex justify-content-between"
        )}
      >
        Photo capture
        <span
          className="text-primary"
          onClick={() => {
            setItems(
              capturesToUse?.map((capture: ClaimCaptureType) => ({
                original: mainDriver
                  ? capture?.image?.url
                  : capture?.capture?.url,
                thumbnail: mainDriver
                  ? capture?.image?.url
                  : capture?.capture?.url,
              }))
            );
            setVisibility(true);
          }}
        >
          <small>Expand</small>{" "}
        </span>
      </div>
      <div className="d-flex flex-wrap mb-5">{renderCaptures()}</div>
      {hasCaptures && (
        <>
          <div className={styles.incidentVisual}></div>
          <div>
            <span className="font-weight-bold">
              {getVerboseName(claim.vehicleInformation)}
            </span>

            <Card
              bgColor="dark"
              customClass={`d-flex justify-content-between mt-3 ${styles.incidentClassCon}`}
              border={false}
            >
              {/* <div>
                <div className="text-muted mb-3">Method</div>
                <div>
                  <span className="font-weight-bold">
                    {claim.settlementMethod}
                  </span>
                </div>
              </div> */}
              <div>
                <div className="text-muted mb-3">Policy Num</div>
                <div>
                  <span className="font-weight-bold">
                    {claim.premiumInformation?.policyNumber}
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
    </Card>
  );
};

export { ClaimCapture };
