import { PaymentStatus } from "enums/payment-status.enum";
import { PremiumStatus } from "enums/premium-status.enum";
import { PaginatedListPayload, Policy, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  getPolicies,
  renewPolicy,
  retryPolicyCreate,
  updatePolicy,
  logTransferPayment,
} from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const PolicyStore = types
  .model("Policy", {
    toggle: false, // external or internal policies
    activePolicy: types.optional(types.frozen<Policy>(), {}),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
    get activePolicyTotalCovered() {
      if (!self.activePolicy?.id) return 0;
      const { amount = 0, totalOutstandingPaid = 0 } = self.activePolicy;
      return +amount + +totalOutstandingPaid;
    },
    hasCreationError(policy: Policy) {
      return (
        policy.paymentStatus !== PaymentStatus.NOT_PAID &&
        policy.status === PremiumStatus.PENDING
      );
    },
  }))

  .actions((self) => ({
    setData(payload: StoreListData) {
      const currentData = cloneDeep(self.data);
      self.data = { ...currentData, ...payload };
    },
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    resetToggle() {
      self.toggle = !self.toggle;
    },
    setActivePolicy(policy: Policy) {
      self.activePolicy = cloneDeep(policy);
    },
  }))
  .actions((self) => ({
    getPolicies: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getPolicies({
          ...self.query,
          ...payload,
        });

        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      }
    }),
  }))
  .actions((self) => ({
    getExternalPolicies: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getPolicies(
          {
            ...self.query,
            ...payload,
          },
          true
        );

        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      }
    }),
  }))
  .actions((self) => ({
    updatePolicy: flow(function* (policyId: number, payload) {
      try {
        const resp: GenericAxiosResponse = yield updatePolicy(
          policyId,
          payload
        );
        const { data } = resp.data;
        self.getPolicies({});
        self.setActivePolicy({ ...self.activePolicy, ...data });
        self.rootStore.toastStore.addSuccessToast("Policy updated");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
    renewPolicy: flow(function* (numOfPayments) {
      try {
        console.log("IN renew");
        const resp: GenericAxiosResponse = yield renewPolicy(
          self.activePolicy.id,
          numOfPayments
        );
        const { data } = resp.data;
        self.getPolicies({});
        self.setActivePolicy({ ...self.activePolicy, ...data });
        self.rootStore.toastStore.addSuccessToast("Policy renewed");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
    retryPolicyCreate: flow(function* (policyId) {
      try {
        console.log("IN renew");
        const resp: GenericAxiosResponse = yield retryPolicyCreate(policyId);
        self.getPolicies({});
        self.setActivePolicy({});
        self.rootStore.toastStore.addSuccessToast(
          "Policy created successfully"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
    logTransferPayment: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield logTransferPayment(payload);

        self.getPolicies({});
        self.rootStore.toastStore.addSuccessToast(
          "Payment logged successfully"
        );
        return resp.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }));
