import { Button, Dropdown, Form, ImageUploader, TextInput } from "components";
import { IVehicleModelForm } from "./VehicleModelForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { VehicleModelSchema } from "schemas";
import { observer } from "mobx-react-lite";
import { generateArrayOfYears } from "utils/dates";
import cloneDeep from "utils/cloneDeep";

const VehicleModelForm: React.FC<IVehicleModelForm.IProps> = observer(
  ({ vehicleModel, handleSubmit = () => ({}) }) => {
    const { vehicleModelStore, makeStore } = useRootStore();

    const { getMakes, makes } = makeStore;

    const initialValues: IVehicleModelForm.VehicleModelFormValues = {
      name: vehicleModel.name,
      picture: vehicleModel.picture || "",
      makeId: vehicleModel.make?.id,
    };

    const onSubmit = async (
      values: IVehicleModelForm.VehicleModelFormValues,
      actions: FormikHelpers<IVehicleModelForm.VehicleModelFormValues>
    ) => {
      try {
        let resp;
        if (!vehicleModel.id) {
          const payload = { ...values, years: generateArrayOfYears() };
          resp = await vehicleModelStore.createVehicleModel(payload);
        } else {
          resp = await vehicleModelStore.updateVehicleModel(
            vehicleModel.id,
            values
          );
        }
        handleSubmit(resp);
      } catch (error) {
        actions.setSubmitting(false);
      }
    };

    const makeList = cloneDeep(makes);
    if (vehicleModel.id) {
      const selectedMakeIdx = makes.findIndex(
        (make) => make.id === vehicleModel?.make?.id
      );
      if (selectedMakeIdx === -1) {
        makeList.push(vehicleModel.make);
      }
    }

    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={VehicleModelSchema}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <ImageUploader
              src={formikProps.values.picture}
              label={
                formikProps.values.name ? formikProps.values.name.charAt(0) : ""
              }
              onUpload={(url) => {
                formikProps.setFieldValue("picture", url);
              }}
            />
            <Dropdown
              items={makeList}
              name="makeId"
              withFormik
              formikInstance={formikProps}
              label="Vehicle Make"
              displayKey="name"
              single
              hasSearch
              searchKeys={["name"]}
              placeholder="Choose One"
              handleSearchChange={(search) => getMakes({ query: search })}
            />
            <TextInput
              formikInstance={formikProps}
              name="name"
              type="text"
              label="Model Name"
              placeholder="Enter name"
            />

            <Button
              label="Save"
              type="submit"
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              customClass="mt-4"
            />
          </form>
        )}
      </Form>
    );
  }
);
export { VehicleModelForm };
