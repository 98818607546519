import {
  Card,
  Icon,
  NumberCard,
  Table,
  VehicleCapture,
  Button,
  ImageUploader,
  TextInput,
} from "components";
import { IPolicyDetail } from "./PolicyDetail";
import appStyles from "styles/app.module.scss";
import styles from "./PolicyDetail.module.scss";
import concatClasses from "utils/concatClasses";
import { color } from "theme";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";
import { daysFromNow, formatDate } from "utils/dates";
import { getVerboseName } from "utils/vehicle";
import formatNumber from "utils/formatMoney";
import { Customer } from "interfaces";
import capitalize from "utils/capitalize";
import { PremiumHistoryType } from "enums/premium-history-type.enum";
import { useMemo, useState, useRef } from "react";
import getPolicyTypeVerbose from "utils/policyTypeVerbose";
import { LogTransferSchema } from "schemas";
import { Formik } from "formik";

const headersList = [
  { header: "Vehicle" },
  { header: "Start Date" },
  { header: "End Date" },
  { header: "Plan" },
  { header: "Type" },
];

const PolicyDetail: React.FC<IPolicyDetail.IProps> = observer(
  ({ ...props }) => {
    const {
      policyStore: {
        activePolicy,
        updatePolicy,
        hasCreationError,
        activePolicyTotalCovered,
        retryPolicyCreate,
        logTransferPayment,
      },
    } = useRootStore();

    const [isRetyring, setIsRetyring] = useState(false);
    const [showManualPayment, setShowManualPayment] = useState(false);
    const [isLoggingPayment, setIsLoggingPayment] = useState(false);
    const transferFormRef = useRef<any>(null);
    const user: Customer | null = activePolicy.user;

    const vehicle = activePolicy?.vehicleInformation;

    const preLossCapture = vehicle
      ? vehicle?.vehicleCapture || vehicle.vehicleCaptures
      : {};

    const handleRetryPolicyCreate = async () => {
      if (isRetyring) return;
      setIsRetyring(true);
      await retryPolicyCreate(activePolicy.id);
      setIsRetyring(false);
    };

    const handleLogManualTransfer = async (formValues) => {
      let payload = {
        premiumId: activePolicy?.id,
        amount: parseFloat(formValues.amount),
        image: formValues.screenshot,
      };
      if (isLoggingPayment) return;
      setIsLoggingPayment(true);
      const resp = await logTransferPayment(payload);
      setIsLoggingPayment(false);
      if (resp.status === "success") {
        transferFormRef.current?.resetForm();
        setShowManualPayment(false);
      }
    };

    const renderHistory = (props: { item: any; id: number }) => {
      const { item: historyItem, id } = props;
      return (
        <tr key={`model_${id}`} style={{ cursor: "pointer" }}>
          <td>{getVerboseName(activePolicy.vehicleInformation)}</td>
          <td>{formatDate(historyItem.startDate || historyItem.createdAt)}</td>
          <td>{historyItem.endDate ? formatDate(historyItem.endDate) : "-"}</td>
          <td>
            <span className="text-primary">{historyItem.policyPlan}</span>
          </td>
          <td>
            <span
              className={
                historyItem.type === PremiumHistoryType.RENEWAL
                  ? "text-success"
                  : "text-danger"
              }
            >
              {capitalize(historyItem.type)}
            </span>
          </td>
        </tr>
      );
    };

    const showErrorPanel = useMemo(
      () => hasCreationError(activePolicy),
      [activePolicy, hasCreationError]
    );

    return (
      <div>
        <div className="row mb-5">
          {showErrorPanel ? (
            <div className="col-12">
              <div
                className="alert alert-danger d-flex align-items-center justify-content-between"
                role="alert"
              >
                <b>Policy paid for but not created on TG.</b>

                <Button
                  label=" Retry Now"
                  type="submit"
                  style={{ width: 200 }}
                  disabled={isRetyring}
                  useLoader
                  customClass="ml-2 btn-danger"
                  onClick={handleRetryPolicyCreate}
                />
              </div>
            </div>
          ) : null}
          <div className="col-12 col-md-7">
            <Card bgColor="dark" customClass="h-100">
              <div className={appStyles.formTitle}>Policy Details</div>
              <div className="pb-4 border-bottom d-flex justify-content-between ">
                <div>
                  <Card
                    customClass="mb-2 py-2 px-3 text-center border-primary"
                    bgColor="dark"
                  >
                    Underwriter
                  </Card>
                  <span className="text-primary">
                    {activePolicy.underwriter}
                  </span>
                </div>
                <div>
                  <div>
                    {formatDate(activePolicy.startDate, "DD MMMM, YYYY")}
                  </div>
                  <div className="text-danger mt-2">
                    {formatDate(activePolicy.expiryDate, "DD MMMM, YYYY")}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Insurance Type</div>
                  <div>{getPolicyTypeVerbose(activePolicy)}</div>
                </div>
              </div>
              <div className="pt-4 d-flex justify-content-between">
                <div>
                  <div className="text-muted mb-2">Vehicle Type</div>
                  <div className="mb-2">
                    {getVerboseName(activePolicy.vehicleInformation)}
                  </div>
                  <div className="mb-2 small">
                    {activePolicy.vehicleInformation?.registrationNumber}
                  </div>
                  <div className="small">
                    Chasis No.
                    {activePolicy.vehicleInformation?.chasisNumber}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Vehicle Value</div>
                  <div className="mb-2">
                    N {formatNumber(activePolicy.vehicleInformation?.worth)}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Amount Covered</div>
                  <div className="mb-2 text-primary">
                    N {formatNumber(activePolicyTotalCovered)}
                  </div>
                </div>
              </div>
              <div className="py-3 border-top mt-4">
                <div className={concatClasses(appStyles.formTitle, "mb-0")}>
                  Customer Information
                </div>
                <div className="pt-4 d-flex justify-content-between">
                  <div>
                    <div className="text-muted mb-2">Phone Number</div>
                    <div className="mb-2">{user?.phone || "N/A"}</div>
                  </div>
                  <div>
                    <div className="text-muted mb-2">Email Address</div>
                    <div className="mb-2">{user?.email}</div>
                  </div>
                  <div>
                    <div className="text-muted mb-2">Date registered</div>
                    <div className="mb-2 text-primary">
                      {user?.createdAt ? formatDate(user.createdAt, "ll") : ""}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-5 mt-4 mt-md-0">
            <Card bgColor="dark" customClass="h-100">
              <div
                style={{ fontSize: 16 }}
                className={concatClasses(
                  appStyles.formTitle,
                  "font-weight-normal text-success"
                )}
              >
                Days Left
              </div>
              <div className="pb-4 border-bottom d-flex justify-content-between">
                <NumberCard number={daysFromNow(activePolicy.expiryDate)} />

                <div className="d-flex align-items-center">
                  <span className="text-muted">Auto renewal</span>
                  <Icon
                    icon="refresh"
                    containerClass="ml-3"
                    fill={
                      activePolicy.autorenew ? color.green : color.palette.red
                    }
                    successIcon={activePolicy.autorenew}
                    dangerIcon={!activePolicy.autorenew}
                    width={16}
                    height={16}
                    onClick={() =>
                      updatePolicy(activePolicy.id, {
                        autorenew: !activePolicy.autorenew,
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between pt-4">
                <div>
                  <div className="text-muted mb-2">Next charge date</div>
                  <div>{formatDate(activePolicy.nextPaymentDueDate, "ll")}</div>
                </div>
                {activePolicy.card && (
                  <div>
                    <Card customClass="py-2 px-3 text-center">
                      <span className="text-muted">
                        {activePolicy.card?.type}
                      </span>
                      <br />
                      **{activePolicy.card?.suffix}
                    </Card>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between pt-5">
                <div>
                  <div className="text-muted mb-2">Payment Frequency</div>
                  <div>{activePolicy.plan}</div>
                </div>
                <div>
                  <Card bgColor="dark" customClass="py-2 px-3 text-center">
                    N{formatNumber(activePolicy.subtotalAmount)}
                  </Card>
                </div>
              </div>
              <div className="d-flex justify-content-between pt-5">
                <div>
                  <div className="text-muted mb-2">Policy Amount</div>
                  <div>N{formatNumber(activePolicy.totalPayableAmount)}</div>
                </div>
                <div>
                  <div className="text-muted mb-2">Amount Remaining</div>
                  <div className="text-danger">
                    N{formatNumber(activePolicy.outstandingAmount)}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between pt-5">
                <div>
                  <div className="text-muted mb-2">Pending Paid Renewals</div>
                  <div>{activePolicy.pendingPaidRenewals}</div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        {preLossCapture && (
          <div className="row">
            <div className="col-12">
              <div className={appStyles.formTitle}>Vehicle Capture</div>
            </div>
            <div className="col-12">
              <VehicleCapture capture={preLossCapture} />
            </div>
          </div>
        )}
        <div className="mt-3">
          {showManualPayment && (
            <div className="w-50 my-4 mx-auto">
              <Formik
                initialValues={{
                  amount: "",
                  screenshot: "",
                }}
                onSubmit={(values) => {
                  handleLogManualTransfer(values);
                }}
                validationSchema={LogTransferSchema}
                innerRef={transferFormRef}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <ImageUploader
                      src={formikProps.values.screenshot}
                      label={"upload payment screenshot"}
                      onUpload={(url) => {
                        formikProps.setFieldValue("screenshot", url);
                      }}
                    />
                    <TextInput
                      formikInstance={formikProps}
                      name="amount"
                      type="text"
                      label="Amount paid"
                      placeholder="Enter amount"
                    />

                    <Button
                      label="Submit"
                      type="submit"
                      useLoader
                      isLoading={isLoggingPayment}
                      disabled={
                        !formikProps.isValid || formikProps.isSubmitting
                      }
                      customClass="mt-4"
                    />
                  </form>
                )}
              </Formik>
            </div>
          )}
          <Button
            label={showManualPayment ? "Cancel" : "Log a transfer"}
            style={{ width: 160, marginLeft: "auto" }}
            useLoader
            outline
            onClick={() => setShowManualPayment(!showManualPayment)}
          />
        </div>

        <div className="row pt-4">
          <div className="col-12">
            <div className={appStyles.formTitle}>Policy History</div>
          </div>
          <div className="col-12">
            <Card customClass="p-0">
              <Table
                listItems={activePolicy.history}
                headersList={headersList}
                renderItems={renderHistory}
                currentPage={1}
                initialPage={0}
                thRowHeight={53}
                borderlessHeader
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
);

export { PolicyDetail };
