import * as React from "react";

function CheckIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 12C2 6.477 6.5 2 12 2s10 4.477 10 10-4.5 10-10 10S2 17.523 2 12zM12 0C5.4 0 0 5.373 0 12s5.4 12 12 12 12-5.373 12-12S18.6 0 12 0zm4.8 9.391c.3-.424.3-1.055-.2-1.409-.4-.354-1-.297-1.4.127l-5.4 6.464-2.1-2.047c-.4-.381-1-.366-1.4.034-.4.4-.4 1.033 0 1.414l2.9 2.784c.2.191.5.291.8.275.3-.016.5-.145.7-.358l6.1-7.284z"
        fill={props.fill || "#3BCEAC"}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default CheckIcon;
