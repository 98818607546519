import { ChangeEvent, useState } from "react";
import { useRootStore } from "store";

import getRowArr from "../../utils/getCsvRowAsArray";

interface ICsvUpload {
  isLoading?: boolean;
  disabled?: boolean;
  requiredColumns?: string[];
  onSaveMapping?: (csvObject: any) => void;
}

const CsvUpload = ({
  isLoading = false,
  disabled = false,
  requiredColumns,
  onSaveMapping,
}: ICsvUpload): JSX.Element => {
  const {
    toastStore: { addToast },
  } = useRootStore();

  const [recipients, setRecipients] = useState<number | undefined>();

  const csvToCleanObj = (csv: string) => {
    const cleanCsv = { headerRow: [""], rows: [""] };
    const allRows = csv.split(/\r?\n|\r/);
    const [headerRow] = allRows;
    const headerRowsArr = getRowArr(headerRow) as string[];

    const allRequiredColsPresent = (requiredColumns || [])
      .map((c) => c.toLowerCase())
      .every((c) => headerRowsArr.map((i) => i.toLowerCase()).includes(c));
    if (!allRequiredColsPresent) {
      const requiredColumnsStr = requiredColumns?.join(",");
      addToast({
        bg: "danger",
        body: `Ensure that ${requiredColumnsStr} columns are present in your csv`,
      });
    } else {
      cleanCsv.headerRow = headerRowsArr;
      cleanCsv.rows = allRows.slice(1);
    }
    return cleanCsv;
  };

  const handleCsvFileChange = (f: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    if (f.target.files) {
      reader.readAsArrayBuffer(f?.target?.files[0] as Blob);
    }
    reader.onloadend = function (evt) {
      const data = evt?.target?.result as ArrayBuffer;

      // Grab our byte length
      const byteLength = data?.byteLength;

      // Convert to conventional array, so we can iterate though it
      const ui8a = new Uint8Array(data, 0);

      const decodedCsv = new TextDecoder("utf8").decode(ui8a);
      // Used to store each character that makes up CSV header
      let headerString = "";

      // Iterate through each character in our Array
      for (let i = 0; i < byteLength; i++) {
        // Get the character for the current iteration
        const char = String.fromCharCode(ui8a[i]);

        // Check if the char is a new line
        if (char.match(/[^\r\n]+/g) !== null) {
          // Not a new line so lets append it to our header string and keep processing
          if (char !== '"') {
            headerString += char;
          }
        } else {
          // We found a new line character, stop processing
          break;
        }
      }
      const cleanCsv = csvToCleanObj(decodedCsv);
      setRecipients(cleanCsv.rows?.length);
      onSaveMapping?.(cleanCsv);
    };
  };

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginBottom: 16,
      }}
    >
      <label
        style={{
          backgroundColor: "secondary",
          borderRadius: "20.5px",
          fontSize: "15px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: `${disabled ? "not-allowed" : "pointer"}`,
          opacity: `${disabled || isLoading ? 0.33 : 1}`,
        }}
        className="bg-primary text-white py-2 px-4"
      >
        <input
          style={{ display: "none" }}
          type={disabled ? "none" : "file"}
          onChange={(f) => handleCsvFileChange(f)}
          multiple={false}
          accept=".csv"
        />
        Upload CSV File
      </label>

      {recipients ? <small>{recipients} recipients</small> : null}
    </div>
  );
};

export default CsvUpload;
