import { Card, TextInput } from "components";
import { IClaimSettlement } from "./ClaimSettlement";
import appStyles from "styles/app.module.scss";
import styles from "./ClaimSettlement.module.scss";
import concatClasses from "utils/concatClasses";
import { formatAsCurrency } from "utils/currency";
import { useRootStore } from "store";
import capitalize from "utils/capitalize";
import { SettlementStatusType } from "enums/claim.enum";

const ClaimSettlement: React.FC<IClaimSettlement.IProps> = ({ claim }) => {
  const { claimStore } = useRootStore();
  const settlementAmount = formatAsCurrency(
    claimStore.getClaimSettlementAmount(claim)
  );
  return (
    <Card bgColor="dark" customClass="px-3 px-md-5">
      <div className={appStyles.formTitle}>Settlement</div>
      <div className="row">
        <div className="col-12 col-md-3">
          <TextInput
            withFormik={false}
            name="policyValue"
            label="Policy Value"
            disabled
            value={formatAsCurrency(
              claim.premiumInformation?.totalPayableAmount
            )}
          />
        </div>
        <div className="col-12 col-md-3">
          <TextInput
            withFormik={false}
            name="outstandingAmount"
            label="Policy Oustanding"
            disabled
            value={formatAsCurrency(
              claim.premiumInformation?.outstandingAmount
            )}
          />
        </div>
        <div className="col-12 col-md-3">
          <TextInput
            withFormik={false}
            name="providerOutstandingAmount"
            label="Policy Provider Oustanding"
            disabled
            value={formatAsCurrency(
              claim.premiumInformation?.providerOutstandingAmount
            )}
          />
        </div>
        <div className="col-12 col-md-3">
          <TextInput
            withFormik={false}
            name="amount"
            label="Claims amount to be settled"
            disabled
            value={formatAsCurrency(claim.amount)}
          />
        </div>
        <div className="col-12 col-md-3">
          <div className={styles.label}>Amount settled </div>
          <Card
            customClass={concatClasses(
              appStyles.dangerCard,
              styles.settlementCard
            )}
          >
            {formatAsCurrency(claim.amountPaid)}
          </Card>
        </div>
        <div className="col-12 col-md-3 mt-2 mt-md-0">
          <div className={styles.label}>Status </div>
          <Card
            customClass={concatClasses(
              claim.settlementStatus === SettlementStatusType.SETTLED
                ? appStyles.successCard
                : appStyles.noStateCard,
              styles.settlementCard
            )}
          >
            {capitalize(claim.settlementStatus)}
          </Card>
        </div>
      </div>
    </Card>
  );
};

export { ClaimSettlement };
