import {
  ClaimIncidentForm,
  ClaimCapture,
  VehicleCapture,
  ClaimWitness,
  ClaimSettlement,
} from "components";
import { IClaimReview } from "./ClaimReview";

const ClaimReview: React.FC<IClaimReview.IProps> = ({ ...props }) => {
  const vehicle = props.claim?.vehicleInformation;

  const preLossCapture = vehicle
    ? vehicle.vehicleCapture || vehicle.vehicleCaptures
    : {};

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12 col-md-8">
          <ClaimIncidentForm claim={props.claim} />
        </div>
        <div className="col-12 col-md-4 mt-4 mt-md-0">
          <ClaimCapture claim={props.claim} />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <VehicleCapture capture={preLossCapture} />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <ClaimWitness claim={props.claim} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <ClaimSettlement claim={props.claim} />
        </div>
      </div>
    </div>
  );
};

export { ClaimReview };
