import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ServiceCenterForm, Modal, Table, CogList } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { ServiceCenter } from "interfaces";
import styles from "./ServiceCenterList.module.scss";
import { IServiceCentersList } from "./ServiceCentersList";
import appStyles from "styles/app.module.scss";

const headersList = [
  { header: "Name" },
  { header: "Email" },
  { header: "Phone Number" },
  { header: "" },
];

const ServiceCentersList: React.FC<IServiceCentersList.IProps> = observer(
  (props: IServiceCentersList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { serviceCenterStore } = useRootStore();
    const {
      activeServiceCenter,
      setActiveServiceCenter,
      data: {
        listItems: serviceCenters,
        meta: { page, totalCount, limit },
      },
    } = serviceCenterStore;

    const getCogActions = (serviceCenter) => [
      {
        label: "Edit",
        fn: () => {
          setActiveServiceCenter(serviceCenter);
          setShowModal(true);
        },
      },
    ];

    const renderServiceCenters = (props: {
      item: ServiceCenter;
      id: number;
    }) => {
      const { item: serviceCenter, id } = props;
      return (
        <tr key={`serviceCenter_${id}`}>
          <td>{serviceCenter.name}</td>
          <td>{serviceCenter.email}</td>
          <td>{serviceCenter.phoneNumber}</td>
          <td>
            <CogList actions={getCogActions(serviceCenter)} />
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={serviceCenters}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderServiceCenters}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await serviceCenterStore.getServiceCenters({ page });
          }}
          showAddBtn
          handleAdd={() => {
            setActiveServiceCenter({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveServiceCenter({});
          }}
          show={showModal}
          title={`${
            activeServiceCenter?.id ? "Edit" : "Create"
          } Service Center `}
        >
          <ServiceCenterForm
            serviceCenter={activeServiceCenter}
            handleSubmit={() => {
              setShowModal(false);
              setActiveServiceCenter({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { ServiceCentersList };
