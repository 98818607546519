import { PaginatedListPayload, Badge, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { createBadge, getBadges, updateBadge } from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

export const BadgeStore = types
  .model("Badge", {
    activeBadge: types.optional(types.frozen<Badge>(), {}),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveBadge(badge: Badge) {
      self.activeBadge = badge;
    },
    getBadges: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getBadges({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }))
  .actions((self) => ({
    createBadge: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createBadge(payload);
        self.getBadges({});
        self.rootStore.toastStore.addSuccessToast("Badge successfully created");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateBadge: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateBadge(
          vehicleModelId,
          payload
        );
        self.getBadges({});
        self.rootStore.toastStore.addSuccessToast("Badge successfully updated");
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
