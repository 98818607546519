import {
  Button,
  Card,
  H4,
  Dropdown,
  TextInput,
  TextArea,
  Form,
  Table,
} from "components";
import ImageUploader from "components/ImageUploader";
import { Modal } from "components/Modal";
import { ProductCategories } from "enums/productCategories.enum";
import { ProductType } from "enums/productType.enum";
import { FormikHelpers } from "formik";
import { Product } from "interfaces/Product";
import ProductSchema from "schemas/product";
import { useRootStore } from "store";
import { ProductProviders } from "utils/product-providers";
import { ProductReviewValues } from "./ProductReview";
import appStyles from "styles/app.module.scss";
import { useEffect, useState } from "react";
import { Order } from "interfaces";
import getFullName from "utils/fullName";
import { formatDate } from "utils/dates";
import getPageCount from "utils/getPageCount";

const headersList = [
  { header: "Customer" },
  { header: "Date Redeemed" },
  { header: "Status" },
];

const ProductReview = ({
  submitHandler,
  product,
  onHide,
  isNew,
}: {
  submitHandler?: (values) => any;
  product?: Product;
  onHide?: () => any;
  isNew?: boolean;
}) => {
  const [orders, setOrders] = useState({
    data: [],
    totalCount: 0,
    page: 1,
    limit: 20,
  });

  const { productsStore } = useRootStore();

  const {
    createProduct,
    getProducts,
    updateProduct,
    deleteProduct,
    getProductOrders,
  } = productsStore;

  const fetchOrders = async (payload = {}) => {
    const ordersResp = await getProductOrders(product.id, payload);
    setOrders(ordersResp);
  };

  useEffect(() => {
    if (!isNew && product?.id) {
      console.log("fetching orders");
      fetchOrders();
    }
  }, [isNew, product]);

  const {
    name,
    type,
    amount,
    points,
    description,
    // status,
    category,
    image,
    providerId,
    providerMerchantId,
    shortDescription,
    quantity,
  } = product || {};

  const initialValues: Product = {
    name,
    type,
    amount,
    points,
    description,
    // status,
    category,
    image,
    shortDescription,
    providerId: providerId || 1,
    providerMerchantId,
    quantity,
  };

  const onSubmit = async (
    values: ProductReviewValues,
    actions: FormikHelpers<ProductReviewValues>
  ) => {
    try {
      if (isNew) {
        await createProduct(values);
        onHide();
      } else {
        await updateProduct(product.id, values);
        onHide();
      }
      actions.setSubmitting(false);
    } catch (error) {
      console.log(error, { ...error });
      actions.setSubmitting(false);
    }
  };

  const renderHistory = (props: { item: Order; id: number }) => {
    const { item: historyItem, id } = props;
    return (
      <tr key={`model_${id}`} style={{ cursor: "pointer" }}>
        <td>
          <div>
            <div>{getFullName(historyItem.user)}</div>
            <span className="text-muted mt-2">{historyItem.user?.email}</span>
          </div>
        </td>
        <td>{formatDate(historyItem.createdAt, "ll")}</td>
        <td>{historyItem.status}</td>
      </tr>
    );
  };

  return (
    <Modal
      handleHide={onHide}
      show={product || isNew ? true : false}
      title={`${isNew ? "Create" : "Edit"} Product`}
      size="xl"
    >
      {(product || isNew) && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ProductSchema}
        >
          {(formikProps) => {
            console.log(formikProps.values, "FORMIKPROPS");
            return (
              <Card bgColor="" customClass="p-5">
                <H4 className="mb-3">{formikProps.values.name || "Product"}</H4>
                <div className="row">
                  <div
                    className="col-3 position-relative"
                    style={{ height: "fit-content", width: "fit-content" }}
                  >
                    <ImageUploader
                      src={formikProps.values.image}
                      label={
                        formikProps.values.name
                          ? formikProps.values.name.charAt(0)
                          : ""
                      }
                      onUpload={(url) => {
                        console.log(`url`, url);
                        formikProps.setFieldValue("image", url);
                      }}
                    />
                  </div>
                  <div className="col-9 pl-4">
                    <TextInput
                      formikInstance={formikProps}
                      name="name"
                      label="Name"
                      placeholder="Enter name"
                    />
                    <div className="row">
                      <div className="col-6">
                        <TextInput
                          formikInstance={formikProps}
                          name="points"
                          label="Points"
                          type="number"
                          placeholder="Enter points"
                        />
                      </div>
                      <div className="col-6">
                        <TextInput
                          formikInstance={formikProps}
                          name="amount"
                          label="Price"
                          type="number"
                          placeholder="Enter price"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Dropdown
                          withFormik={true}
                          formikInstance={formikProps}
                          name="providerId"
                          label="Provider"
                          items={ProductProviders}
                          displayKey="text"
                          idKey="id"
                          placeholder="Choose One"
                        />
                      </div>
                      <div className="col-6">
                        <TextInput
                          formikInstance={formikProps}
                          name="providerMerchantId"
                          label="Provider Merchant ID"
                          type="number"
                          placeholder="Enter value (if applicable)"
                        />
                      </div>
                    </div>
                    {formikProps.values.providerId === 2 && (
                      <div className="row">
                        <div className="col-12">
                          <TextInput
                            formikInstance={formikProps}
                            name="quantity"
                            label="Quantity Remaining"
                            type="number"
                            placeholder="Enter value (if applicable)"
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12">
                        <Dropdown
                          withFormik={true}
                          formikInstance={formikProps}
                          name="type"
                          label="Type"
                          items={Object.values(ProductType)}
                          placeholder="Choose One"
                        />
                      </div>
                    </div>
                    <Dropdown
                      withFormik={true}
                      formikInstance={formikProps}
                      name="category"
                      label="Category"
                      items={Object.values(ProductCategories)}
                      placeholder="Choose One"
                    />
                    <TextInput
                      formikInstance={formikProps}
                      name="shortDescription"
                      label="Short Description"
                      placeholder="Enter short description"
                    />
                    <TextArea
                      formikInstance={formikProps}
                      name="description"
                      label="Description"
                      rows={5}
                      placeholder="Enter description"
                    />
                    <div className="mt-5">
                      {isNew ? (
                        <Button
                          label={"Create Product"}
                          type="submit"
                          onClick={() => formikProps.submitForm()}
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      ) : (
                        <>
                          <Button
                            label={"Save Changes"}
                            customClass="mb-3"
                            onClick={() => formikProps.submitForm()}
                            disabled={
                              formikProps.isSubmitting || !formikProps.isValid
                            }
                          />
                          <Button
                            label={"Delete Product"}
                            style={{ backgroundColor: "red" }}
                            onClick={async () => {
                              await deleteProduct(product.id);
                              onHide();
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          }}
        </Form>
      )}
      {!isNew && (
        <div className="row mt-5">
          <div className="col-12">
            <div className={appStyles.formTitle}>Redemption History</div>
          </div>
          <div className="col-12">
            <Card customClass="p-0">
              <Table
                listItems={orders.data}
                totalCount={orders.totalCount}
                pageCount={getPageCount(orders.totalCount, orders.limit)}
                headersList={headersList}
                renderItems={renderHistory}
                currentPage={orders.page}
                usePagination
                initialPage={orders.page >= 2 ? orders.page - 1 : 0}
                thRowHeight={53}
                borderlessHeader
                tableClass="mb-0"
                handlePagination={async (page) => {
                  await fetchOrders({ page });
                }}
                fixedPagination={false}
              />
            </Card>
          </div>
        </div>
      )}
    </Modal>
  );
};

export { ProductReview };
