import { Toast } from "interfaces";
import { types } from "mobx-state-tree";
import extractError from "utils/extractError";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const ToastStore = types
  .model("Toast", {
    toasts: types.optional(types.array(types.frozen<Toast>()), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    addToast(toast: Toast) {
      console.log("ADDINGTOST");
      self.toasts.unshift(toast);
    },
    removeToast(toastIndex) {
      self.toasts.splice(toastIndex, 1);
    },
  }))
  .actions((self) => ({
    addErrToast(error, title?: string) {
      self.addToast({ bg: "danger", title, body: extractError(error) });
    },
    addSuccessToast(body, title?: string) {
      self.addToast({ bg: "success", title, body });
    },
  }));
