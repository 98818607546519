import React, { useState } from "react";
import styles from "./Icon.module.scss";
import appStyles from "styles/app.module.scss";
import { IIcon } from "./Icon";
import { icons } from "./icons";
import concatClasses from "utils/concatClasses";

export const Icon: React.FC<IIcon.IProps> = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { height, width, fill, label, onClick } = props;
  const IconComponent = icons[props.icon];
  const iconClassNames = concatClasses(
    "d-flex",
    props.withContainer ? styles.IconContainer : "",
    props.successIcon ? styles.successContainer : "",
    props.dangerIcon ? styles.dangerContainer : "",
    props.containerClass,
    isLoading ? appStyles.opacity5 : "",
    appStyles.pointer
  );

  const handleClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    console.log("INCOMPO");
    if (isLoading) return;
    try {
      setIsLoading(true);
      await props.onClick(event);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {label && (
        <span className={`d-flex align-items-center ${styles.controlLabel}`}>
          {label}
        </span>
      )}
      <div
        className={iconClassNames}
        onClick={props.disabled ? handleClick : () => null}
      >
        <IconComponent height={height} width={width} fill={fill} />
      </div>
    </div>
  );
};

Icon.defaultProps = {
  onClick: () => ({}),
  withContainer: false,
  height: 20,
};
