import { PaginatedListPayload, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import cloneDeep from "utils/cloneDeep";
import NProgress from "nprogress";
import { Enterprise } from "interfaces/Enterprise";
import {
  getEnterprise,
  getEnterprises,
  getEnterprisePolicies,
  getEnterpriseDrivers,
} from "services/api/enterprises";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const EnterpriseStore = types
  .model("Enterprise", {
    activeEnterprise: types.optional(types.frozen<Enterprise>(), null),
    recentViews: types.optional(types.array(types.frozen<Enterprise>()), []),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setData(payload: StoreListData) {
      const currentData = cloneDeep(self.data);
      self.data = { ...currentData, ...payload };
    },
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveEnterprise(enterprise: Enterprise | null) {
      self.activeEnterprise = enterprise ? cloneDeep(enterprise) : enterprise;
    },
    addToRecentViews(enterprise: Enterprise | null) {
      self.recentViews.unshift(cloneDeep(enterprise));
      const currentLen = self.recentViews.length;
      if (currentLen > 3) {
        self.recentViews.splice(currentLen - 1, 1);
      }
    },
  }))
  .actions((self) => ({
    getEnterprises: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getEnterprises({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data;
      } catch (error) {
        //set global error
      } finally {
        NProgress.done();
      }
    }),
    getEnterprise: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getEnterprise(payload);
        const { data } = resp.data;
        self.activeEnterprise = data;
        const customerIdx = self.recentViews.findIndex(
          (c) => c.id === data?.id
        );
        if (customerIdx < 0 && data?.id) {
          self.addToRecentViews(data);
        }
        return data;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
    getEnterprisePolicies: flow(function* (id, payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getEnterprisePolicies(
          id,
          payload
        );
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
    getEnterpriseDrivers: flow(function* (id, payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getEnterpriseDrivers(
          id,
          payload
        );
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
  }));
