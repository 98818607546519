import * as Yup from "yup";

const ServiceCenterSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phoneNumber: Yup.string(),
  address: Yup.string(),
  description: Yup.string(),
  state: Yup.string(),
  specialization: Yup.string(),
});

export default ServiceCenterSchema;
