import { DataListMeta, Crash } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, types } from "mobx-state-tree";
import { getCrashes } from "services/api";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const CrashStore = types
  .model("Crash", {
    crashes: types.optional(types.array(types.frozen<Crash>()), []),
    crashesMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
  })
  .views((self) => ({}))
  .actions((self) => ({
    getCrashes: flow(function* (payload) {
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getCrashes(payload);
        const { data, ...rest } = resp.data.data;
        self.crashes = data;
        self.crashesMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.fetching = false;
      }
    }),
  }));
