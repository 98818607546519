import React from "react";
import { observer } from "mobx-react-lite";
import { Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Crash } from "interfaces";
import styles from "./ClaimList.module.scss";
import { getVerboseName } from "utils/vehicle";
import { formatDate } from "utils/dates";
import { ICrashesList } from "./CrashesList";
import formatLocation from "utils/formatLocation";

const headersList = [
  { header: "Vehicle" },
  { header: "User" },
  { header: "Location" },
  { header: "Time" },
  { header: "Date" },
];

const CrashesList: React.FC<ICrashesList.IProps> = observer(
  (props: ICrashesList.IProps) => {
    const { crashStore } = useRootStore();
    const {
      crashes,
      crashesMeta: { page, totalCount, limit },
      fetching,
    } = crashStore;

    console.log(`pagexx`, page);

    const renderCrashes = (props: { item: Crash; id: number }) => {
      const { item: crash, id } = props;
      return (
        <tr key={`crash_${id}`}>
          <td>
            <div>
              <div>{getVerboseName(crash.vehicle)}</div>
              <span className="text-muted mt-2">
                {crash.vehicle?.registrationNumber}
              </span>
            </div>
          </td>
          <td>{crash.user?.email}</td>

          <td>
            <div>
              <div>{formatLocation(crash.longitude, crash.latitude)}</div>
            </div>
          </td>
          <td>{formatDate(crash.createdAt, "hh:mm a")}</td>
          <td>{formatDate(crash.createdAt, "ll")}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={crashes}
          headersList={headersList}
          totalCount={totalCount}
          loading={fetching}
          renderItems={renderCrashes}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            crashStore.getCrashes({ page });
          }}
        />
      </>
    );
  }
);

export { CrashesList };
