import React from "react";

function OverviewIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={props.fill || "#574646"}
        fillRule="evenodd"
        d="M4.25 11c4.069 0 4.25 1.38 4.25 4.25 0 1.438 0 2.461-.604 3.21-.695.863-1.96 1.04-3.646 1.04s-2.951-.177-3.646-1.04C0 17.711 0 16.689 0 15.275l.75-.025H0C0 12.38.181 11 4.25 11zm11 0c4.069 0 4.25 1.38 4.25 4.25 0 1.438 0 2.461-.604 3.21-.695.863-1.96 1.04-3.646 1.04s-2.951-.177-3.646-1.04C11 17.711 11 16.689 11 15.275l.75-.025H11c0-2.87.181-4.25 4.25-4.25zM4.456 12.5H4.25c-2.609 0-2.743.187-2.75 2.377v.853c.006.871.039 1.5.271 1.79.265.328 1.052.48 2.479.48s2.214-.153 2.479-.481C7 17.182 7 16.382 7 15.274c0-2.5 0-2.762-2.544-2.774zm11 0h-.206c-2.609 0-2.743.187-2.75 2.377v.853c.005.871.039 1.5.271 1.79.265.328 1.052.48 2.479.48s2.214-.153 2.479-.481c.271-.337.271-1.137.271-2.245 0-2.5 0-2.762-2.544-2.774zM4.25 0C8.319 0 8.5 1.38 8.5 4.25c0 1.438 0 2.461-.604 3.21-.695.863-1.96 1.04-3.646 1.04S1.299 8.323.604 7.46C0 6.711 0 5.689 0 4.275l.75-.025H0C0 1.38.181 0 4.25 0zm11 0c4.069 0 4.25 1.38 4.25 4.25 0 1.438 0 2.461-.604 3.21-.695.863-1.96 1.04-3.646 1.04s-2.951-.177-3.646-1.04C11 6.711 11 5.689 11 4.275l.75-.025H11C11 1.38 11.181 0 15.25 0zM4.456 1.5H4.25c-2.609 0-2.743.187-2.75 2.377v.853c.006.871.039 1.5.271 1.79.265.328 1.052.48 2.479.48s2.214-.153 2.479-.481C7 6.182 7 5.382 7 4.274c0-2.5 0-2.762-2.544-2.774zm11 0h-.206c-2.609 0-2.743.187-2.75 2.377v.853c.005.871.039 1.5.271 1.79.265.328 1.052.48 2.479.48s2.214-.153 2.479-.481C18 6.182 18 5.382 18 4.274c0-2.5 0-2.762-2.544-2.774z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default OverviewIcon;
