import * as React from "react";

function CogIcon(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18.88 13.253l-1.651.406c-.1.313-.227.617-.382.907l.77 1.282c.1.166.073.379-.064.515l-1.193 1.193a.42.42 0 01-.515.064l-1.284-.77c-.289.154-.59.28-.902.379l-.406 1.651a.422.422 0 01-.41.32h-1.687a.421.421 0 01-.409-.32s-.281-1.153-.406-1.651a5.416 5.416 0 01-.957-.408l-1.331.799a.423.423 0 01-.516-.064l-1.193-1.193a.422.422 0 01-.063-.515l.823-1.375a5.436 5.436 0 01-.333-.813l-1.651-.407a.422.422 0 01-.32-.41v-1.687c0-.193.132-.362.32-.409l1.652-.406c.1-.318.23-.626.388-.923l-.737-1.227a.422.422 0 01.064-.516L7.68 6.482a.423.423 0 01.515-.063l1.23.737c.294-.156.6-.285.916-.385l.406-1.651a.421.421 0 01.41-.32h1.687c.193 0 .362.132.409.32l.406 1.651c.325.103.638.236.936.398l1.25-.75a.422.422 0 01.515.063l1.193 1.193c.137.137.163.35.064.516l-.759 1.263c.15.284.274.581.37.887l1.652.406c.188.047.32.216.32.41v1.687a.422.422 0 01-.32.409zM12 8.8A3.204 3.204 0 008.8 12c0 1.764 1.436 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2 0-1.764-1.436-3.2-3.2-3.2z"
          fill={props.color || "#8F92A1"}
          fillRule="nonzero"
        />
        <circle
          fillOpacity={0.085}
          fill={props.backgroundColor || "#576A94"}
          cx={12}
          cy={12}
          r={12}
        />
      </g>
    </svg>
  );
}

export default CogIcon;
