import React from "react";

function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
      {...props}
    >
      <path
        fill={props.fill || "#A8A8A8"}
        fillRule="evenodd"
        d="M6.827.333a6.499 6.499 0 016.492 6.492c0 1.69-.649 3.23-1.71 4.386l2.088 2.083a.5.5 0 11-.707.708l-2.112-2.107a6.461 6.461 0 01-4.051 1.423A6.5 6.5 0 01.334 6.825 6.5 6.5 0 016.827.333zm0 1a5.498 5.498 0 00-5.493 5.492 5.499 5.499 0 005.493 5.493 5.499 5.499 0 005.492-5.493 5.498 5.498 0 00-5.492-5.492z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon;
