import { Card, Icon } from "components";
import { IRideDetail } from "./RideDetail";
import appStyles from "styles/app.module.scss";
import styles from "./RideDetail.module.scss";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";
import { formatDate, secondsToReadableDate } from "utils/dates";
import capitalize from "utils/capitalize";
import Label from "components/Label";
import concatClasses from "utils/concatClasses";

const RideDetail: React.FC<IRideDetail.IProps> = observer(({ ...props }) => {
  const {
    behaviourLabel,
    behaviourLabel__text,
    behaviourLabel__oval,
    behaviourLabel__no_psuedo,
    headerTr,
    headerTr__th,
    valuesTable,
    valuesTableContainer,
    dateLabel,
  } = styles;

  const { successCard, dangerCard } = appStyles;
  const {
    rideStore: { activeRide, groupedRideBehaviour },
  } = useRootStore();

  console.log(`groupedRideBehaviour`, groupedRideBehaviour);

  const renderValues = (values) => {
    return values.map((value, index) => (
      <div className="row mb-3">
        <div className="col-3">
          <div className={behaviourLabel}>
            <div className="d-flex align-items-center">
              <div className={behaviourLabel__oval}></div>
              <div className={behaviourLabel__text}>
                {capitalize(
                  value.acceleration ||
                    value.anomaly ||
                    `Bevahiour ${index + 1}`
                )}
              </div>
            </div>
            <Icon
              icon="angleDownFilled"
              containerClass="ml-auto"
              width={13.33}
              height={6.67}
            />
          </div>
        </div>
        <div className={concatClasses(valuesTableContainer, "col-9")}>
          <table className={valuesTable}>
            <thead>
              <tr className={headerTr}>
                <th className={headerTr__th}>Duration</th>
                <th className={headerTr__th}>Time</th>
                <th className={headerTr__th}>Pathway</th>
              </tr>
            </thead>
            <tr>
              <td>
                <div className="d-flex">
                  <Icon icon="duration" />
                  {value.duration ? (
                    <div className="ml-2">
                      {secondsToReadableDate(+value.duration)}
                    </div>
                  ) : (
                    <div className="ml-2">
                      {" "}
                      <small>No duration data</small>{" "}
                    </div>
                  )}
                </div>
              </td>
              <td>
                {formatDate(value.start, "hh:mm a")} -{" "}
                {formatDate(value.end, "hh:mm a")}
              </td>
              <td>
                {value && value?.path ? (
                  value.path.map((path) => (
                    <div className="mb-2">
                      <span className="text-muted">Lat: </span> {path.latitude}{" "}
                      | <span className="text-muted">Lng:</span>{" "}
                      {path.longitude}
                    </div>
                  ))
                ) : (
                  <div>
                    {" "}
                    <small> No pathway data</small>{" "}
                  </div>
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
    ));
  };

  const renderAddress = () => (
    <div className="d-flex justify-content-between mt-3 align-items-center">
      <div className={concatClasses(dateLabel, successCard, "w-100")}>
        {activeRide.startLocation}
      </div>
      <div className="mx-3">
        <small>
          <b>to</b>
        </small>
      </div>
      <div className={concatClasses(dateLabel, dangerCard, "w-100")}>
        {activeRide.endLocation}
      </div>
    </div>
  );

  return (
    <div>
      <div className="row mb-5">
        <div className="col-12">
          {activeRide.behaviorScores && (
            <Card bgColor="dark" customClass="p-5 mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <div className={appStyles.formTitle}>Score Summary</div>

                <div className={dateLabel}>
                  {formatDate(activeRide.startTime, "DD MMM YYYY")}
                </div>
              </div>
              {renderAddress()}

              <div className="mt-3 row">
                {Object.entries(activeRide.behaviorScores).map(
                  ([key, value]) => (
                    <div
                      className={concatClasses(
                        behaviourLabel,
                        behaviourLabel__no_psuedo,
                        "col-12",
                        "mw-100"
                      )}
                    >
                      <div className="d-flex align-items-center">
                        <div className={behaviourLabel__oval}></div>
                        <div className={behaviourLabel__text}>
                          {capitalize(key)}
                        </div>
                      </div>
                      <small>{value}</small>
                    </div>
                  )
                )}
              </div>
            </Card>
          )}
          {/* {Object.entries(groupedRideBehaviour).map(([behaviour, values]) => (
            <Card bgColor="dark" customClass="p-5 mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <div className={appStyles.formTitle}>{behaviour}</div>

                <div className={dateLabel}>
                  {formatDate(rideDate, "DD MMM YYYY")}
                </div>
              </div>
              {renderAddress()}

              <div className="mt-5">{values ? renderValues(values) : null}</div>
            </Card>
          ))} */}
        </div>
      </div>
    </div>
  );
});

export { RideDetail };
