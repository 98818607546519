import { Button, Form, TextArea, TextInput } from "components";
import { IPartnerForm } from "./PartnerForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { PartnerSchema } from "schemas";

const PartnerForm: React.FC<IPartnerForm.IProps> = ({
  partner,
  handleSubmit = () => ({}),
}) => {
  const { partnerStore } = useRootStore();
  const initialValues: IPartnerForm.PartnerFormValues = {
    name: partner?.name,
    notes: partner?.notes,
    phoneNumber: partner?.phoneNumber,
    email: partner?.email,
  };

  const onSubmit = async (
    values: IPartnerForm.PartnerFormValues,
    actions: FormikHelpers<IPartnerForm.PartnerFormValues>
  ) => {
    try {
      let resp;
      if (!partner.id) {
        resp = await partnerStore.createPartner(values);
      } else {
        resp = await partnerStore.updatePartner(partner.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PartnerSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />

          <TextInput
            formikInstance={formikProps}
            name="email"
            type="email"
            label="Email"
            placeholder="Enter email"
          />
          <TextInput
            formikInstance={formikProps}
            name="phoneNumber"
            type="text"
            label="Phone Number"
            placeholder="Enter phone"
          />

          <TextArea
            formikInstance={formikProps}
            name="notes"
            label="Notes"
            placeholder="Enter notes"
            rows={5}
          />
          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { PartnerForm };
