import { PaginatedListPayload, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import cloneDeep from "utils/cloneDeep";
import NProgress from "nprogress";
import { Customer } from "interfaces/Customer";
import {
  createBandRequest,
  getCustomer,
  getCustomers,
  updateBandRequest,
  updateCustomer,
} from "services/api/customers";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const CustomersStore = types
  .model("Customers", {
    activeCustomer: types.optional(types.frozen<Customer>(), null),
    recentViews: types.optional(types.array(types.frozen<Customer>()), []),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setData(payload: StoreListData) {
      const currentData = cloneDeep(self.data);
      self.data = { ...currentData, ...payload };
    },
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveCustomer(customer: Customer | null) {
      self.activeCustomer = customer ? cloneDeep(customer) : customer;
    },
    addToRecentViews(customer: Customer | null) {
      self.recentViews.unshift(cloneDeep(customer));
      const currentLen = self.recentViews.length;
      if (currentLen > 3) {
        self.recentViews.splice(currentLen - 1, 1);
      }
    },
  }))
  .actions((self) => ({
    getCustomers: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getCustomers({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data;
      } catch (error) {
        //set global error
      } finally {
        NProgress.done();
      }
    }),
    getCustomer: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getCustomer(payload);
        const { data } = resp.data;
        self.activeCustomer = data;
        const customerIdx = self.recentViews.findIndex(
          (c) => c.id === data?.id
        );
        if (customerIdx < 0 && data?.id) {
          self.addToRecentViews(data);
        }
        return data;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
  }))
  .actions((self) => ({
    updateCustomer: flow(function* (id, payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield updateCustomer(id, payload);
        self.activeCustomer = resp.data.data;
        self.rootStore.toastStore.addSuccessToast("Customer updated");
        return resp;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
      } finally {
        NProgress.done();
      }
    }),
    requestBandChange: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield createBandRequest(payload);
        self.activeCustomer = {
          ...self.activeCustomer,
          bandRequests: [
            ...(self.activeCustomer.bandRequests || []),
            resp.data.data,
          ],
        };
        self.rootStore.toastStore.addSuccessToast("Request Successful");
        return resp;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
      } finally {
        NProgress.done();
      }
    }),
    updateBandRequest: flow(function* (requestId: number, payload: any) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield updateBandRequest(
          requestId,
          payload
        );
        const bandRequests = (self.activeCustomer?.bandRequests || []).slice();
        const bandReqiuestIndex = bandRequests.findIndex(
          (b) => b.id === requestId
        );
        if (bandReqiuestIndex >= 0) {
          bandRequests[bandReqiuestIndex] = resp.data.data;

          self.activeCustomer = {
            ...self.activeCustomer,
            bandRequests,
          };
        }

        self.rootStore.toastStore.addSuccessToast("Request Successful");
        return resp;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
      } finally {
        NProgress.done();
      }
    }),
  }));
