import { PaginatedListPayload } from "interfaces/PaginatedListPayload";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getVehicleParts = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/api/vehicle-parts${params}`);
};

export const createVehiclePart = (payload) => {
  return api.post(`/api/vehicle-parts`, payload);
};
export const updateVehiclePart = (partId, payload) => {
  return api.put(`/api/vehicle-parts/${partId}`, payload);
};
