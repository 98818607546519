import api from "./config";

export const getCrashes = ({
  page = 1,
  limit = 20,
  query = "",
  sortBy = "",
  orderBy = "ASC",
}) => {
  return api.get(
    `/crashes/admin?limit=${limit}&query=${query}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
  );
};
