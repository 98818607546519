import { DataListMeta, Customer } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { disableUser, enableUser } from "services/api";
import { RootStore } from "..";

export const UserStore = types
  .model("User", {
    users: types.optional(types.array(types.frozen<Customer>()), []),
    usersMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
    activeUser: types.optional(types.frozen<Customer>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setActiveUser(user: Customer) {
      self.activeUser = user;
    },
    enableUser: flow(function* (userId: number) {
      try {
        const resp: GenericAxiosResponse = yield enableUser(userId);
        self.rootStore.toastStore.addSuccessToast("User successfully enabled");
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    disableUser: flow(function* (userId: number) {
      try {
        const resp: GenericAxiosResponse = yield disableUser(userId);
        self.rootStore.toastStore.addSuccessToast("User successfully disabled");
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
