import { Button, Form, TextArea, TextInput } from "components";
import { IBroadcastForm } from "./BroadcastForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { BroadcastSchema } from "schemas";
import CsvUpload from "components/CsvInput/CsvUpload";
import extractDataFromCsv from "utils/extractColFromCsv";
import toDistnct from "utils/toDistinct";

const BroadcastForm: React.FC<IBroadcastForm.IProps> = ({
  broadcast,
  handleSubmit = () => ({}),
}) => {
  const { broadcastStore } = useRootStore();

  const initialValues: IBroadcastForm.BroadcastFormValues = {
    subject: broadcast?.subject,
    message: broadcast?.message,
    sendToAll: true,
    toUsers: [],
    toUserGroups: [],
  };

  const onSubmit = async (
    values: IBroadcastForm.BroadcastFormValues,
    actions: FormikHelpers<IBroadcastForm.BroadcastFormValues>
  ) => {
    try {
      let resp;
      if (!broadcast.id) {
        resp = await broadcastStore.createBroadcast(values);
      } else {
        resp = await broadcastStore.updateBroadcast(broadcast.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BroadcastSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <CsvUpload
            requiredColumns={["id"]}
            onSaveMapping={(csv) => {
              formikProps.setFieldValue(
                "toUsers",
                toDistnct(extractDataFromCsv(csv)?.map((id) => +id)).filter(
                  (d) => d > 0
                )
              );
            }}
          />
          <TextInput
            formikInstance={formikProps}
            name="subject"
            type="text"
            label="Subject"
            placeholder="Enter subject"
            disabled={!!broadcast?.id}
          />
          <TextArea
            formikInstance={formikProps}
            name="message"
            label="Message"
            placeholder="Enter message text"
            rows={5}
            disabled={!!broadcast?.id}
          />
          {!broadcast.id && (
            <Button
              label="Save"
              type="submit"
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              customClass="mt-4"
            />
          )}
        </form>
      )}
    </Form>
  );
};

export { BroadcastForm };
