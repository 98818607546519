import { Button, Form, ImageUploader, TextInput } from "components";
import { IVehicleMakeForm } from "./VehicleMakeForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { VehicleMakeSchema } from "schemas";

const VehicleMakeForm: React.FC<IVehicleMakeForm.IProps> = ({
  vehicleMake,
  handleSubmit = () => ({}),
}) => {
  const { makeStore } = useRootStore();
  const initialValues: IVehicleMakeForm.MakeFormValues = {
    name: vehicleMake.name,
    logo: vehicleMake.logo || "",
  };

  const onSubmit = async (
    values: IVehicleMakeForm.MakeFormValues,
    actions: FormikHelpers<IVehicleMakeForm.MakeFormValues>
  ) => {
    try {
      let resp;
      if (!vehicleMake.id) {
        resp = await makeStore.createMake(values);
      } else {
        resp = await makeStore.updateMake(vehicleMake.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={VehicleMakeSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <ImageUploader
            src={formikProps.values.logo}
            label={
              formikProps.values.name ? formikProps.values.name.charAt(0) : ""
            }
            onUpload={(url) => {
              formikProps.setFieldValue("logo", url);
            }}
          />
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />

          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { VehicleMakeForm };
