import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { getUserProfile, setToken, signIn } from "services/api";
import { RootStore } from "..";
import { User } from "./auth";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const AuthStore = types
  .model("Auth", {
    user: types.optional(types.frozen<User>(), {}),
    token: types.optional(types.string, localStorage.getItem("token") || ""),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
    get isAuthenticated() {
      return !!self.token;
    },
    get fullName() {
      return `${self.user.firstname} ${self.user.lastname}`;
    },
  }))
  .actions((self) => ({
    setUser(user: User) {
      self.user = user;
    },
    setToken(token: string) {
      self.token = token;
    },
  }))
  .actions((self) => ({
    signIn: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield signIn(payload);
        const { token } = resp.data.data;
        yield setToken(`Bearer ${token}`);
        localStorage.setItem("token", token);
        self.token = token;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
      return self.token;
    }),
    getUserProfile: flow(function* () {
      try {
        const resp: GenericAxiosResponse = yield getUserProfile();
        console.log(resp, "USER");
        self.user = resp.data.data;
      } catch (error) {
        console.log(`error`, error);
      }

      return self.user;
    }),
    logout: flow(function* () {
      yield setToken("");
      self.user = {};
      localStorage.removeItem("token");
      self.token = "";

      return true;
    }),
  }));
