import React from "react";

function UsersIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="17"
      fill="none"
      viewBox="0 0 22 17"
      {...props}
    >
      <path
        fill={props.fill || "#574646"}
        fillRule="evenodd"
        d="M10.887 10.414h.365c1.93.014 6.343.209 6.343 3.199 0 2.97-4.258 3.165-6.313 3.177h-.76c-1.932-.013-6.344-.207-6.344-3.194 0-2.975 4.412-3.168 6.343-3.181l.253-.001h.113zm0 1.5c-2.376 0-5.21.292-5.21 1.682 0 1.358 2.665 1.676 4.992 1.694l.218.001c2.376 0 5.208-.291 5.208-1.678 0-1.404-2.832-1.699-5.208-1.699zm7.953-1.906c2.423.363 2.932 1.501 2.932 2.392 0 .544-.214 1.537-1.644 2.082a.75.75 0 01-.534-1.402c.678-.258.678-.543.678-.68 0-.438-.557-.744-1.655-.908a.752.752 0 01-.63-.854.755.755 0 01.853-.63zm-15.054.63a.752.752 0 01-.631.854c-1.098.164-1.655.47-1.655.908 0 .137 0 .421.679.68a.75.75 0 11-.534 1.402C.214 13.936 0 12.943 0 12.4c0-.89.509-2.029 2.933-2.392a.755.755 0 01.853.63zM10.886 0c2.52 0 4.57 2.05 4.57 4.569a4.574 4.574 0 01-4.57 4.569h-.026a4.52 4.52 0 01-3.22-1.346A4.519 4.519 0 016.32 4.566 4.573 4.573 0 0110.887 0zm0 1.5A3.072 3.072 0 007.82 4.569c-.003.82.31 1.587.886 2.166.575.579 1.34.9 2.157.903l.025.75v-.75a3.072 3.072 0 003.069-3.069 3.073 3.073 0 00-3.07-3.069zm6.19-.52a3.611 3.611 0 013.035 3.576 3.642 3.642 0 01-3.12 3.583.75.75 0 01-.207-1.486 2.133 2.133 0 001.827-2.1 2.115 2.115 0 00-1.78-2.094.75.75 0 01.245-1.48zM5.56 1.597a.75.75 0 01-.618.862 2.116 2.116 0 00-1.78 2.097A2.132 2.132 0 004.99 6.654a.75.75 0 11-.207 1.486 3.641 3.641 0 01-3.12-3.583A3.612 3.612 0 014.699.98a.746.746 0 01.862.618z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UsersIcon;
