import React from "react";

function AngleDownFilledIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="7"
      fill="none"
      viewBox="0 0 14 7"
      {...props}
    >
      <path
        fill={props.fill || "#56CB77"}
        d="M.332.333L6.999 7 13.665.333H.332z"
      ></path>
    </svg>
  );
}

export default AngleDownFilledIcon;
