export const palette = {
  white: "#ffffff",
  blue: "#307EC4",
  green: "#56CB77",
  pink: "#FF57A1",
  grey: "#9DA0A5",
  deepGrey: "#EBE7E7",
  black: "#1E2323",
  redLight: "#FF57A1",
  black2: "#3F3B3B",
  red: "#c41414",
  skyBlue: "#E7EEFB",
  primary: "#307ec4",
  lightGrey: "#f8f8f9",
  fadedGreen: "#E0F5E6",
  yellow: "yellow",
  lightRed: "#ffe3e3",
};
