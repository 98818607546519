import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { ProductReview, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Product } from "interfaces";
import styles from "./ProductsList.module.scss";
import { IProductsList } from "./ProductsList";
import scrollToTop from "utils/scrollToTop";
import appStyles from "styles/app.module.scss";
import formatNumber from "utils/formatMoney";
import { palette } from "theme/palette";
import { ProductStatus } from "enums/productStatus.enum";

const headersList = [
  { header: "Name" },
  { header: "Provider" },
  // { header: "Type" },
  // { header: "Status" },
  { header: "Amount" },
  { header: "Points" },
];

const ProductsList: React.FC<IProductsList.IProps> = observer(
  (props: IProductsList.IProps) => {
    const { bgHoverClass_grey } = appStyles;
    const { productsStore } = useRootStore();
    const [isNewProduct, setIsNewProduct] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>(null);
    const {
      getProducts,
      data: {
        listItems: products,
        meta: { page, totalCount, limit },
      },
    } = productsStore;
    // const layoutRef = useRef();

    console.log(`pagexx`, page);

    const renderProduct = (props: { item: Product; id: number }) => {
      const { item: product, id } = props;

      // let productStatusColor = palette.green;

      // switch (product.status) {
      //   case ProductStatus.ACTIVE:
      //     productStatusColor = palette.green;
      //     break;
      //   case ProductStatus.DISABLED:
      //     productStatusColor = palette.red;
      //     break;
      //   default:
      //     break;
      // }
      return (
        <tr
          key={`product_${id}`}
          className={bgHoverClass_grey}
          onClick={() => setSelectedProduct(product)}
        >
          <td className="text-muted mt-2">{product.name}</td>
          <td>{product.provider.name}</td>
          {/* <td>{product.type}</td> */}
          {/* <td
            style={{
              color: productStatusColor,
            }}
          >
            {product.status}
          </td> */}
          <td>₦{formatNumber(product.amount)}</td>
          <td>{product.points}</td>
        </tr>
      );
    };

    const submitHandler = (value) => {
      try {
        // createProduct(value);
        console.log(value);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        <Table
          listItems={products}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderProduct}
          showAddBtn
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getProducts({ page, limit });
          }}
          handleAdd={() => {
            setIsNewProduct(true);
          }}
          showRefresh
          handleRefresh={() => getProducts({})}
        />
        <ProductReview
          submitHandler={submitHandler}
          isNew={isNewProduct}
          product={selectedProduct}
          onHide={() => {
            setSelectedProduct(null);
            setIsNewProduct(false);
          }}
        />
      </>
    );
  }
);

export { ProductsList };
