import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getBroadcasts = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/broadcasts${params}`);
};

export const createBroadcast = (payload) => {
  return api.post(`/broadcasts`, payload);
};
export const updateBroadcast = (broadcastId, payload) => {
  return api.patch(`/broadcasts/${broadcastId}`, payload);
};
export const resendBroadcast = (broadcastId) => {
  return api.post(`/broadcasts/${broadcastId}/resend`);
};
