import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { CogList, Modal, Table, VehicleModelForm } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { VehicleModel } from "interfaces";
import styles from "./ClaimList.module.scss";
import { color } from "theme";
import { formatDate } from "utils/dates";
import { IVehicleModelsList } from "./VehicleModelsList";

const headersList = [
  { header: "Model Make" },
  { header: "Model Name" },
  { header: "Date Created" },
  { header: "" },
];

const VehicleModelsList: React.FC<IVehicleModelsList.IProps> = observer(
  (props: IVehicleModelsList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { vehicleModelStore } = useRootStore();
    const {
      activeVehicleModel,
      setActiveVehicleModel,
      getVehicleModels,
      data: {
        listItems: vehicleModels,
        meta: { page, totalCount, limit },
      },
    } = vehicleModelStore;

    console.log(`pagexx`, page);

    const getCogActions = (vehicleModel) => [
      {
        label: "Edit",
        fn: () => {
          setActiveVehicleModel(vehicleModel);
          setShowModal(true);
        },
      },
    ];

    const renderVehicleModels = (props: { item: VehicleModel; id: number }) => {
      const { item: vehicleModel, id } = props;
      return (
        <tr key={`model_${id}`}>
          <td>{vehicleModel.make?.name}</td>
          <td>{vehicleModel.name}</td>
          <td>{formatDate(vehicleModel.createdAt, "ll")}</td>
          <td>
            <CogList actions={getCogActions(vehicleModel)} />
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={vehicleModels}
          headersList={headersList}
          totalCount={totalCount}
          renderItems={renderVehicleModels}
          showAddBtn
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getVehicleModels({ page });
          }}
          handleAdd={() => {
            setActiveVehicleModel({});
            setShowModal(true);
          }}
          showRefresh
          handleRefresh={() => getVehicleModels({})}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveVehicleModel({});
          }}
          show={showModal}
          title={`${activeVehicleModel.id ? "Edit" : "Create"} Vehicle Model`}
          scrollable={false}
        >
          <VehicleModelForm
            vehicleModel={activeVehicleModel}
            handleSubmit={() => {
              setShowModal(false);
              setActiveVehicleModel({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { VehicleModelsList };
