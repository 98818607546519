import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { PartnerForm, Modal, Table, CogList } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Business } from "interfaces";
import styles from "./PartnerList.module.scss";
import { IPartnersList } from "./PartnersList";
import appStyles from "styles/app.module.scss";
import { useHistory } from "react-router-dom";

const headersList = [
  { header: "Name" },
  { header: "Email" },
  { header: "Phone Number" },
  { header: "" },
];

const PartnersList: React.FC<IPartnersList.IProps> = observer(
  (props: IPartnersList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { partnerStore } = useRootStore();
    const {
      partners,
      partnersMeta: { page, totalCount, limit },
      fetching,
      activePartner,
      setActivePartner,
    } = partnerStore;

    const router = useHistory();

    const getCogActions = (business) => [
      {
        label: "Api Keys",
        fn: () =>
          router.push(
            `/api-keys?business=${business.id}&name=${business.name}`
          ),
      },
    ];

    const renderPartners = (props: { item: Business; id: number }) => {
      const { item: partner, id } = props;
      return (
        <tr key={`partner_${id}`} onClick={() => viewPartner(partner)}>
          <td>{partner.name}</td>
          <td>{partner.email}</td>
          <td>{partner.phoneNumber}</td>
          <td>
            <CogList actions={getCogActions(partner)} />
          </td>
        </tr>
      );
    };

    const viewPartner = (partner) => {
      setActivePartner(partner);
      setShowModal(true);
    };

    return (
      <>
        <Table
          listItems={partners}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          loading={fetching}
          renderItems={renderPartners}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            partnerStore.getPartners({ page, ...props.filters });
          }}
          showAddBtn
          handleAdd={() => {
            setActivePartner({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActivePartner({});
          }}
          show={showModal}
          title={`${activePartner?.id ? "Edit" : "Create"} Business `}
        >
          <PartnerForm
            partner={activePartner}
            handleSubmit={() => {
              setShowModal(false);
              setActivePartner({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { PartnersList };
