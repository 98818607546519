import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Customer } from "interfaces";
import { IEnterpriseDriverList } from "./EnterpriseDriverList";
import appStyles from "styles/app.module.scss";
import { formatDate } from "utils/dates";
import formatNumber from "utils/formatMoney";

const headersList = [
  { header: "Driver" },
  { header: "Email" },
  { header: "Number" },
  { header: "Points" },
  { header: "Date Joined" },
];

const EnterpriseDriversList: React.FC<IEnterpriseDriverList.IProps> = observer(
  (props: IEnterpriseDriverList.IProps) => {
    const { bgHoverClass_grey } = appStyles;
    const { enterpriseStore } = useRootStore();
    const {
      getEnterpriseDrivers,
      data: {
        listItems: drivers,
        meta: { page, totalCount, limit },
      },
    } = enterpriseStore;

    const history = useHistory();
    const { id } = useParams();
    const renderEnterprise = (props: { item: Customer; id: number }) => {
      const { item: driver, id } = props;

      return (
        <tr
          key={`driver_${id}`}
          className={bgHoverClass_grey}
          onClick={() => history.replace(`/customers/${driver.id}`)}
        >
          <td className="text-muted mt-2">{`${driver.firstname} ${driver.lastname}`}</td>
          <td>{driver.email}</td>
          <td>{driver.phone || "-"}</td>
          <td>
            {driver.point?.point
              ? formatNumber(driver.point?.point)
              : (0).toFixed(2)}
          </td>
          <td>{formatDate(driver.createdAt, "ll")}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={drivers}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderEnterprise}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getEnterpriseDrivers(id, { page });
          }}
          showRefresh
          handleRefresh={() => getEnterpriseDrivers(id, {})}
        />
      </>
    );
  }
);

export { EnterpriseDriversList };
