import React from "react";
import { IForm } from "./Form";
import { Formik } from "formik";
import { forwardRef } from "react";

export const Form: React.FC<IForm.IProps> = forwardRef<any, IForm.IProps>(
  ({ ...props }, ref) => {
    return (
      <Formik
        ref={ref}
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
        onReset={props.onReset}
      >
        {props.children}
      </Formik>
    );
  }
);
