import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Button, ClaimReview, Icon, Modal, Table, Switch } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Claim } from "interfaces";
import { SettlementMethodType, SettlementStatusType } from "enums/claim.enum";
import styles from "./ClaimList.module.scss";
import { color } from "theme";
import { getVerboseName } from "utils/vehicle";
import { formatAsCurrency } from "utils/currency";
import { formatDate } from "utils/dates";
import { IClaimsList } from "./ClaimsList";
import appStyles from "styles/app.module.scss";

const headersList = [
  { header: "Vehicle" },
  { header: "Date Received" },
  { header: "Claim Status" },
  { header: "No. of Damaged Parts" },
  { header: "Claim Value" },
  { header: "Claim UID" },
];

const ClaimsList: React.FC<IClaimsList.IProps> = observer(
  (props: IClaimsList.IProps) => {
    const { onToggleExternal } = props;
    const [showModal, setShowModal] = useState(false);
    const [showExternal, setShowExternal] = useState(false);

    const { claimStore } = useRootStore();
    const {
      activeClaim,
      setActiveClaim,
      approveClaimSettlement,
      declineClaimSettlement,
      data: {
        listItems: claims,
        meta: {
          page,
          totalCount,
          limit,
          pendingClaims,
          settledClaims,
          initiatedClaims,
        },
      },
    } = claimStore;

    console.log(`pagexx`, page);

    const handleToggleExternal = useCallback(
      (value: boolean) => {
        setShowExternal(value);
        if (value) {
          claimStore.getClaimsExternal({ page: 1 });
        } else {
          claimStore.getClaims({ page: 1 });
        }
        onToggleExternal(value);
      },
      [claimStore, onToggleExternal]
    );

    const renderClaimStatus = (claim: Claim) => {
      let containerClass;
      let labelText;
      let icon;
      let iconWidth;
      let iconHeight;
      let iconFill;
      switch (claim.settlementStatus) {
        case SettlementStatusType.SETTLED:
          containerClass = styles.approvedContainer;
          labelText = "Approved";
          icon = "check";
          iconWidth = 13;
          iconFill = color.palette.green;
          break;
        case SettlementStatusType.DECLINED:
          containerClass = styles.declinedContainer;
          labelText = "Declined";
          icon = "check";
          iconWidth = 13;
          iconFill = color.palette.red;
          break;

        default:
          containerClass = styles.reviewContainer;
          labelText = "Review";
          icon = "claims";
          iconWidth = 18;
          iconHeight = 19.9;
          break;
      }
      return (
        <div className={containerClass} onClick={() => viewClaim(claim)}>
          <span>{labelText}</span>
          <Icon
            icon={icon}
            width={iconWidth}
            height={iconHeight || iconWidth}
            fill={iconFill}
            containerClass="ml-1"
          />
        </div>
      );
    };

    const renderClaims = (props: { item: Claim; id: number }) => {
      const { item: claim, id } = props;
      return (
        <tr key={`claim_${id}`}>
          <td>
            <div>
              <div>
                {claim.vehicleInformation
                  ? getVerboseName(claim.vehicleInformation)
                  : getVerboseName({
                      make: claim.vehicleMake,
                      year: claim.year,
                      model: claim.vehicleModel,
                    })}
              </div>
              <span className="text-muted mt-2">
                {claim.vehicleInformation
                  ? claim.vehicleInformation.registrationNumber
                  : claim.vehicleRegistrationNumber}
              </span>
            </div>
          </td>
          <td>{formatDate(claim.incidentDate, "ll")}</td>
          <td>{claim.settlementStatus ? renderClaimStatus(claim) : "-"}</td>
          <td>{claim.captures ? claim.captures?.length : "-"}</td>
          <td>{claim.amount ? formatAsCurrency(claim.amount) : "-"}</td>
          <td>{claim.uid}</td>
        </tr>
      );
    };

    const viewClaim = (claim) => {
      setActiveClaim(claim);
      setShowModal(true);
    };

    const handleAccept = async () => {
      await approveClaimSettlement(activeClaim.id);
      setShowModal(false);
    };

    const handleDecline = async () => {
      await declineClaimSettlement(activeClaim.id);
      setShowModal(false);
    };

    return (
      <>
        <Table
          listItems={claims}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderClaims}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            (await showExternal)
              ? claimStore.getClaimsExternal({ page })
              : claimStore.getClaims({ page });
          }}
          topStats={[
            { label: "Pending", value: pendingClaims },
            { label: "Initiated", value: initiatedClaims },
            {
              label: "Settled",
              value: settledClaims,
              className: appStyles.successCard,
            },
          ]}
          headerContent={
            <Switch
              label="Show External Claims"
              checked={showExternal}
              onClick={handleToggleExternal}
              customClass="mb-0 mr-4"
            />
          }
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveClaim({});
          }}
          show={showModal}
          title="Claims Review"
          size="xl"
          headerActions={
            <div className="d-flex flex-column flex-md-row mt-3 mt-md-0">
              {activeClaim?.settlementStatus ===
                SettlementStatusType.INITIATED &&
              activeClaim?.settlementMethod === SettlementMethodType.DIRECT ? (
                <>
                  <Button
                    label="Approve"
                    customClass="px-4"
                    useLoader
                    onClick={handleAccept}
                  />
                  <Button
                    label="Decline"
                    outline
                    customClass="ml-md-3 px-4 my-3 my-md-0"
                    useLoader
                    onClick={handleDecline}
                  />
                </>
              ) : null}
            </div>
          }
        >
          <ClaimReview claim={activeClaim} />
        </Modal>
      </>
    );
  }
);

export { ClaimsList };
