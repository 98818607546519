import {
  DataListMeta,
  PaginatedListPayload,
  Ride,
  StoreListData,
} from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, types } from "mobx-state-tree";
import { getRides } from "services/api";
import lodashGroupBy from "lodash.groupby";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";

export const RideStore = types
  .model("Ride", {
    activeRide: types.optional(types.frozen<Ride>(), null),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get groupedRideBehaviour() {
      if (!self.activeRide) return [];
      return lodashGroupBy(
        self.activeRide.rideBehavior,
        (behaviour) => behaviour.type
      );
    },
    // get rideDate() {
    //   if (!self.activeRide) return "";
    //   const { rideBehavior = [] } = self.activeRide;
    //   return rideBehavior.length ? rideBehavior[0].start : "";
    // },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveRide(ride: Ride) {
      self.activeRide = ride;
    },
    getRides: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getRides({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {}
    }),
  }));
