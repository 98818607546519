import { Card, Image } from "components";
import { IVehicleCapture } from "./VehicleCapture";
import appStyles from "styles/app.module.scss";
import { useState } from "react";
import { color } from "theme";
import { useRootStore } from "store";
import { VehicleCaptureV1, VehicleCaptureV2 } from "interfaces/Captures";

const VehicleCapture: React.FC<IVehicleCapture.IProps> = ({ capture }) => {
  const [activeCapture, setActiveCapture] = useState(null);

  const {
    galleryStore: { setItems, setVisibility },
  } = useRootStore();

  const getImageUrl = (image) => {
    return typeof image === "string" ? image : image?.url;
  };

  // let preLossImages:
  //   | Partial<VehicleCaptureV1>
  //   | Partial<VehicleCaptureV2> = Object.keys(capture).includes("bonnetCenter")
  //   ? {}
  //   : capture;

  let preLossImages = capture;
  if (capture) {
    if (Object.keys(capture).includes("bonnetCentre")) {
      const {
        id,
        isCompleted,
        longitude,
        latitude,
        createdAt,
        updatedAt,
        version,
        ...rest
      } = capture as VehicleCaptureV1;
      preLossImages = rest;
    } else {
      const { lat, lng, ...rest } = capture as VehicleCaptureV2;
      preLossImages = rest;
    }
  }

  const renderUploadedParts = () =>
    Object.keys(preLossImages).map((key) => (
      <div className="mr-3 mb-3" key={key}>
        <Image
          width="130px"
          height="58px"
          label={`capture_${key}`}
          style={{ backgroundColor: color.secondary }}
          src={getImageUrl(capture[key])}
        />
        <div className="d-flex justify-content-between">
          <span className="text-muted">
            <small>{key}</small>
          </span>
          <span
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveCapture(capture[key])}
          >
            <small>View</small>
          </span>
        </div>
      </div>
    ));

  return (
    <Card bgColor="dark" customClass="px-3 px-md-5 pb-5">
      <div className="row">
        <div className="col-4">
          <div className={appStyles.formTitle}>Vehicle (Pre Incident)</div>
          {activeCapture ? (
            <Image
              width="100%"
              height="314px"
              label={`Capture`}
              src={getImageUrl(activeCapture)}
            />
          ) : null}
        </div>

        <div className={activeCapture ? "col-8" : "col-12"}>
          <div className="d-flex justify-content-between">
            <span className="text-muted" style={{ lineHeight: "24px" }}>
              Uploaded Body Parts
            </span>
            <span
              className="text-primary"
              onClick={() => {
                console.log("first");
                setItems(
                  Object.values(preLossImages).map((part: any) => ({
                    original: getImageUrl(part),
                    thumbnail: getImageUrl(part),
                  }))
                );
                setVisibility(true);
              }}
            >
              <small>Expand</small>{" "}
            </span>
          </div>

          <div className="d-flex flex-wrap" style={{ marginTop: 29 }}>
            {preLossImages ? renderUploadedParts() : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

export { VehicleCapture };
