import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Icon, Modal, PointDetail, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { PointHistory } from "interfaces";
import styles from "./ClaimList.module.scss";
import { color } from "theme";
import { getVerboseName } from "utils/vehicle";
import { formatDate } from "utils/dates";
import { IPointsList } from "./PointsList";
import getFullName from "utils/fullName";

const headersList = [
  { header: "User" },
  { header: "Date" },
  { header: "Description" },
  { header: "Points" },
];

const PointsList: React.FC<IPointsList.IProps> = observer(
  (props: IPointsList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { pointStore } = useRootStore();
    const {
      setActivePoint,
      getPoints,
      data: {
        listItems: points,
        meta: { page, totalCount, limit },
      },
    } = pointStore;

    console.log(`pagexx`, page);

    const renderPoints = (props: { item: PointHistory; id: number }) => {
      const { item: point, id } = props;
      return (
        <tr
          key={`point_${id}`}
          // onClick={() => {
          //   setActivePoint(point);
          //   setShowModal(true);
          // }}
        >
          <td>
            <div>
              <div>{getFullName(point.user)}</div>
              <span className="text-muted mt-2">{point.user?.email}</span>
            </div>
          </td>
          <td>{formatDate(point.createdAt, "ll")}</td>
          <td>{point.description}</td>
          <td className={+point.points < 0 ? "text-danger" : "text-success"}>
            {point.points} <small>pts</small>
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={points}
          headersList={headersList}
          totalCount={totalCount}
          renderItems={renderPoints}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getPoints({ page });
          }}
          showRefresh
          handleRefresh={() => getPoints({})}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActivePoint({});
          }}
          show={showModal}
          title={`Point Detail`}
          size="xl"
        >
          <PointDetail />
        </Modal>
      </>
    );
  }
);

export { PointsList };
