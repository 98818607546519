import { PremiumType } from "enums/premium-type.enum";
import { Policy } from "interfaces";

const getPolicyTypeVerbose = (policy: Policy) => {
  const { policyType } = policy || {};
  return policyType === PremiumType.COMPREHENSIVE
    ? "Comprehensive"
    : "Third Party";
};

export default getPolicyTypeVerbose;
