import { PaginatedListPayload } from "interfaces/PaginatedListPayload";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getProducts = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/products/admin${params}`);
};

export const createProduct = (payload: PaginatedListPayload) => {
  return api.post("/products/create", payload);
};

export const updateProduct = (productId, payload) => {
  return api.put(`/products/update/${productId}`, payload);
};
export const deleteProduct = (productId) => {
  return api.delete(`/products/delete/${productId}`);
};

export const getProductOrders = (productId, query: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...query });
  return api.get(`/products/${productId}/orders${params}`);
};
