import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getBanks = (payload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/banks${getURLParams(params)}`);
};

export const getCustomerBankAccounts = (id, payload) => {
  return api.get(`customers/${id}/bank-accounts`);
};
