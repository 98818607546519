import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, RiskProfileDetail, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { RiskProfile } from "interfaces";
import styles from "./RiskProfileList.module.scss";
import { color } from "theme";
import { IRiskProfilesList } from "./RiskProfilesList";
import appStyles from "styles/app.module.scss";
import formatNumber from "utils/formatMoney";

const headersList = [
  { header: "Business" },
  { header: "Email" },
  { header: "Car Worth" },
  { header: "Risk Score" },
];

const RiskProfilesList: React.FC<IRiskProfilesList.IProps> = observer(
  (props: IRiskProfilesList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { riskProfileStore } = useRootStore();
    const {
      activeRiskProfile,
      data: {
        listItems: riskProfiles,
        meta: { page, totalCount, limit },
      },
      setActiveRiskProfile,
    } = riskProfileStore;

    const renderRiskProfiles = (props: { item: RiskProfile; id: number }) => {
      const { item: riskProfile, id } = props;
      return (
        <tr
          key={`riskProfile_${id}`}
          onClick={() => viewRiskProfile(riskProfile)}
        >
          <td>{riskProfile.businessName}</td>
          <td>{riskProfile.email}</td>
          <td> N {formatNumber(riskProfile.vehicleWorth)}</td>
          <td>{riskProfile.score}</td>
        </tr>
      );
    };

    const viewRiskProfile = (riskProfile) => {
      setActiveRiskProfile(riskProfile);
      setShowModal(true);
    };

    return (
      <>
        <Table
          listItems={riskProfiles}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderRiskProfiles}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await riskProfileStore.getRiskProfiles({ page });
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveRiskProfile({});
          }}
          show={showModal}
          title={`Risk Profile Detail`}
          size="lg"
        >
          <RiskProfileDetail riskProfile={activeRiskProfile} />
        </Modal>
      </>
    );
  }
);

export { RiskProfilesList };
