import { INumberCard } from "./NumberCard";
import styles from "./NumberCard.module.scss";
import splitNumber from "utils/splitNumber";

const NumberCard: React.FC<INumberCard.IProps> = ({ number }) => {
  const { daysLeft, daysLeft__top, daysLeft__bottom, daysLeft__num } = styles;

  const renderNumber = () =>
    splitNumber(number).map((n) => (
      <div className={daysLeft}>
        <div className={daysLeft__num}>{n}</div>
        <div className={daysLeft__top}></div>
        <div className={daysLeft__bottom}></div>
      </div>
    ));
  return <div className="d-flex">{renderNumber()}</div>;
};

export { NumberCard };
