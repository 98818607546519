export enum SettlementMethodType {
  DIRECT = "BANK",
  PARTNER = "PARTNER",
}

export enum SettlementStatusType {
  SETTLED = "SETTLED",
  INITIATED = "INITIATED",
  PENDING = "PENDING",
  DECLINED = "DECLINED",
}
