import api from "./config";

export const getMakes = ({
  page = 1,
  limit = 20,
  query = "",
  sortBy = "",
  orderBy = "ASC",
}) => {
  return api.get(
    `/api/vehicle-makes/admin?limit=${limit}&query=${query}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
  );
};

export const createMake = (payload) => {
  return api.post(`/api/vehicle-makes`, payload);
};
export const updateMake = (makeId, payload) => {
  return api.put(`/api/vehicle-makes/${makeId}`, payload);
};
