import { DataListMeta, Bank } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, types } from "mobx-state-tree";
import { getBanks } from "services/api";

export const BankStore = types.model("Bank", {
  banks: types.optional(types.array(types.frozen<Bank>()), []),
  banksMeta: types.optional(types.frozen<DataListMeta>(), {}),
  fetching: false,
})
.views((self) => ({}))
.actions((self) => ({
  getBanks: flow(function* (payload) {
    try {
      self.fetching = true;
      const resp: GenericAxiosResponse = yield getBanks(payload);
      const { data, ...rest } = resp.data.data;
      self.banks = data;
      self.banksMeta = rest;
      self.fetching = false;
      return resp.data.data; 
    } catch (error) {
      console.log(`error`, error);
      self.fetching = false;
    }
  }),
}));

