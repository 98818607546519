import { Button } from "components";
import React from "react";
import { useHistory } from "react-router-dom";

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12 text-center">
          <h1>404 Page Not Found</h1>
        </div>
        <div className="col-12 text-center mt-4">
          <Button label="Back Home" onClick={() => history.push("/overview")} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
