import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Table, VehiclePartReview } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { VehiclePart } from "interfaces";
import styles from "./VehiclePartsList.module.scss";
import { IVehiclePartsList } from "./VehiclePartsList";
import appStyles from "styles/app.module.scss";
import formatNumber from "utils/formatMoney";
import capitalize from "utils/capitalize";

const headersList = [
  { header: "Category" },
  { header: "Part" },
  { header: "Make" },
  { header: "Model" },
  { header: "Year" },
  { header: "Side" },
  { header: "Value" },
];

const VehiclePartsList: React.FC<IVehiclePartsList.IProps> = observer(
  (props: IVehiclePartsList.IProps) => {
    const { bgHoverClass_grey } = appStyles;
    const { sideContainer } = styles;
    const [selectedPart, setSelectedPart] = useState<null | VehiclePart>(null);
    const [isNewVehiclePart, setIsNewVehiclePart] = useState<boolean>(false);

    const { vehiclePartsStore } = useRootStore();
    const {
      getVehicleParts,
      data: {
        listItems: vehicleParts,
        meta: { page, totalCount, limit },
      },
    } = vehiclePartsStore;

    console.log(`pagexx`, page);

    const renderVehiclePart = (props: { item: VehiclePart; id: number }) => {
      const { item: part, id } = props;
      return (
        <tr
          key={`product_${id}`}
          className={bgHoverClass_grey}
          onClick={() => setSelectedPart(part)}
        >
          <td className="text-muted">
            {part.partsCategory?.name || part.category}
          </td>
          <td>{part.name}</td>
          <td>{part.make?.name}</td>
          <td>{part.model?.name}</td>
          <td>{part.year}</td>
          <td>
            <span className={sideContainer}>{capitalize(part.side)}</span>
          </td>
          <td>₦{formatNumber(part.price)}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={vehicleParts}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderVehiclePart}
          showAddBtn
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getVehicleParts({ page });
          }}
          handleAdd={() => {
            setIsNewVehiclePart(true);
            setSelectedPart(null);
          }}
          showRefresh
          handleRefresh={() => getVehicleParts({})}
        />
        <VehiclePartReview
          vehiclePart={selectedPart}
          isNew={isNewVehiclePart}
          onHide={() => {
            setSelectedPart(null);
            setIsNewVehiclePart(false);
          }}
        />
      </>
    );
  }
);

export { VehiclePartsList };
