import { UserRole } from "enums/userRole.enum";
import * as Yup from "yup";

const BroadcastSchema = Yup.object().shape({
  subject: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  sendToAll: Yup.boolean(),
  toUsers: Yup.array().of(Yup.number()),
  toUserGroups: Yup.array().of(
    Yup.mixed<UserRole>().oneOf(Object.values(UserRole))
  ),
});

export default BroadcastSchema;
