import * as Yup from "yup";

const PartnerSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.string(),
  notes: Yup.string(),
});

export default PartnerSchema;
