import React from "react";
import { IBaseText } from "./BaseText";
import styles from "./BaseText.module.scss";
import concatClasses from "utils/concatClasses";

export default function BaseText({
  className,
  color,
  weight,
  grey,
  fontSize,
  style,
  As = (props) => <p {...props} />,
  size = "md",
  ...props
}: IBaseText.IProps) {
  const { baseTextClass, smTextClass, mdTextClass, lgTextClass } = styles;

  const textStyles = {
    sm: smTextClass,
    md: mdTextClass,
    lg: lgTextClass,
  };

  return (
    <As
      {...props}
      className={concatClasses(
        baseTextClass,
        textStyles[size],
        className || ""
      )}
      style={{
        ...{ color, fontWeight: weight, fontSize, ...style },
      }}
    />
  );
}
