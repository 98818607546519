import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AdminForm, CogList, Modal, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Customer } from "interfaces";
import styles from "./AdminList.module.scss";
import { IAdminsList } from "./AdminsList";
import getFullName from "utils/fullName";
import { accessLevels } from "utils/access-levels";

const headersList = [
  { header: "Name" },
  { header: "Email" },
  { header: "Phone Number" },
  { header: "Access" },
  { header: "Status" },
  { header: "" },
];

const AdminsList: React.FC<IAdminsList.IProps> = observer(
  (props: IAdminsList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const {
      adminStore,
      userStore: { enableUser, disableUser },
    } = useRootStore();
    const {
      admins,
      adminsMeta: { page, totalCount, limit },
      fetching,
      activeAdmin,
      setActiveAdmin,
      getAdmins,
    } = adminStore;

    const getCogActions = (admin: Customer) => {
      return [
        {
          label: "Edit",
          fn: () => {
            setActiveAdmin(admin);
            setShowModal(true);
          },
        },
        admin.isActive
          ? {
              label: "Disable",
              fn: async () => {
                await disableUser(admin.id);
                getAdmins({});
              },
            }
          : {
              label: "Enable",
              fn: async () => {
                await enableUser(admin.id);
                getAdmins({});
              },
            },
      ];
    };

    const renderAdmins = (props: { item: Customer; id: number }) => {
      const { item: admin, id } = props;
      return (
        <tr key={`admin_${id}`}>
          <td>{getFullName(admin)}</td>
          <td>{admin.email}</td>
          <td>{admin.phone}</td>
          <td>{admin.role ? accessLevels[admin.role] : ""}</td>
          <td className={admin.isActive ? "text-success" : "text-danger"}>
            {admin.isActive ? "Active" : "Disabled"}
          </td>
          <td>
            <CogList actions={getCogActions(admin)} />
          </td>
        </tr>
      );
    };

    // const viewAdmin = (admin) => {
    //   setActiveAdmin(admin);
    //   setShowModal(true);
    // };

    return (
      <>
        <Table
          listItems={admins}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          loading={fetching}
          renderItems={renderAdmins}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            adminStore.getAdmins({ page, ...props.filters });
          }}
          showAddBtn
          handleAdd={() => {
            setActiveAdmin({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveAdmin({});
          }}
          show={showModal}
          title={`${activeAdmin?.id ? "Edit" : "Create"} Admin`}
        >
          <AdminForm
            admin={activeAdmin}
            handleSubmit={() => {
              setShowModal(false);
              setActiveAdmin({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { AdminsList };
