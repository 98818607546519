import React from "react";
import useLoadImage from "hooks/useLoadImage";
import { useEffect } from "react";
import { IParagraph } from "./Paragraph";
import styles from "./Paragraph.module.scss";
import concatClasses from "utils/concatClasses";
import { useRef } from "react";
import BaseText from "components/BaseText";

export default function Paragraph({
  className,
  ...props
}: IParagraph.IProps) {
  const { paragraphClass } = styles;

  return <BaseText {...props} className={concatClasses(paragraphClass, className)} />;
}
