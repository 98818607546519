import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { CogList, Modal, Table, VehicleMakeForm } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { VehicleMake } from "interfaces";
import styles from "./ClaimList.module.scss";
import { color } from "theme";
import { formatDate } from "utils/dates";
import { IVehicleMakesList } from "./VehicleMakesList";

const headersList = [
  { header: "Name" },
  { header: "Date Created" },
  { header: "" },
];

const VehicleMakesList: React.FC<IVehicleMakesList.IProps> = observer(
  (props: IVehicleMakesList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { makeStore } = useRootStore();
    const {
      makes,
      makesMeta: { page, totalCount, limit },
      fetching,
      activeMake,
      setActiveMake,
      getMakes,
    } = makeStore;

    console.log(`pagexx`, page);

    const getCogActions = (make) => [
      {
        label: "Edit",
        fn: () => {
          setActiveMake(make);
          setShowModal(true);
        },
      },
    ];

    const renderMakes = (props: { item: VehicleMake; id: number }) => {
      const { item: make, id } = props;
      return (
        <tr key={`make_${id}`}>
          <td>{make.name}</td>
          <td>{formatDate(make.createdAt, "ll")}</td>
          <td>
            <CogList actions={getCogActions(make)} />
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={makes}
          headersList={headersList}
          totalCount={totalCount}
          loading={fetching}
          renderItems={renderMakes}
          showAddBtn
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            getMakes({ page });
          }}
          handleAdd={() => {
            setActiveMake({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveMake({});
          }}
          show={showModal}
          scrollable={false}
          title={`${activeMake.id ? "Edit" : "Create"} Vehicle Make`}
        >
          <VehicleMakeForm
            vehicleMake={activeMake}
            handleSubmit={() => {
              setShowModal(false);
              setActiveMake({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { VehicleMakesList };
