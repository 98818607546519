import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getBadges = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/badges/paginate${params}`);
};

export const createBadge = (payload) => {
  return api.post(`/badges`, payload);
};
export const updateBadge = (badgeId, payload) => {
  return api.put(`/badges/${badgeId}`, payload);
};
