import { AddressInput, Button, Form, TextArea, TextInput } from "components";
import { IServiceCenterForm } from "./ServiceCenterForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { ServiceCenterSchema } from "schemas";

const ServiceCenterForm: React.FC<IServiceCenterForm.IProps> = ({
  serviceCenter,
  handleSubmit = () => ({}),
}) => {
  const { serviceCenterStore } = useRootStore();
  const initialValues: IServiceCenterForm.ServiceCenterFormValues = {
    name: serviceCenter?.name,
    email: serviceCenter?.email,
    phoneNumber: serviceCenter?.phoneNumber,
    address: serviceCenter?.address,
    description: serviceCenter.description,
    state: serviceCenter.state,
    specialization: serviceCenter.specialization,
    location: serviceCenter.location,
  };

  const onSubmit = async (
    values: IServiceCenterForm.ServiceCenterFormValues,
    actions: FormikHelpers<IServiceCenterForm.ServiceCenterFormValues>
  ) => {
    try {
      let resp;
      if (!serviceCenter.id) {
        resp = await serviceCenterStore.createServiceCenter(values);
      } else {
        resp = await serviceCenterStore.updateServiceCenter(
          serviceCenter.id,
          values
        );
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ServiceCenterSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />

          <TextInput
            formikInstance={formikProps}
            name="email"
            type="email"
            label="Email"
            placeholder="Enter email"
          />
          <TextInput
            formikInstance={formikProps}
            name="phoneNumber"
            type="text"
            label="Phone Number"
            placeholder="Enter phone"
          />

          <AddressInput
            formikInstance={formikProps}
            name="address"
            type="text"
            label="Address"
            placeholder="Enter address"
            postChange={(payload) => {
              formikProps.setFieldValue("state", payload.city);
              formikProps.setFieldValue("location", {
                type: "Point",
                coordinates: [payload.lng, payload.lat],
              });
            }}
          />

          <TextInput
            formikInstance={formikProps}
            name="specialization"
            type="text"
            label="Specialization"
            placeholder="Enter specialization"
          />

          <TextArea
            formikInstance={formikProps}
            name="description"
            label="Description"
            placeholder="Enter description"
            rows={5}
          />
          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { ServiceCenterForm };
