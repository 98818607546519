import { ProductCategories } from "enums/productCategories.enum";
import { ProductStatus } from "enums/productStatus.enum";
import { ProductType } from "enums/productType.enum";
import * as Yup from "yup";

const ProductSchema = Yup.object().shape({
  // image: Yup.mixed<File>().required(),
  image: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  type: Yup.mixed<ProductType>()
    .oneOf(Object.values(ProductType))
    .required("Required"),
  amount: Yup.number().min(0).required("Required"),
  points: Yup.number().min(0).required("Required"),
  quantity: Yup.number().min(0).nullable(),
  description: Yup.string(),
  providerId: Yup.number().min(0).nullable(),
  providerMerchantId: Yup.number().min(0).nullable(),
  shortDescription: Yup.string(),
  category: Yup.mixed<ProductCategories>()
    .oneOf(Object.values(ProductCategories))
    .required("Required"),
});

export default ProductSchema;
