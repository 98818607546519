import { PaginatedListPayload, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import cloneDeep from "utils/cloneDeep";
import NProgress from "nprogress";
import { WebPolicy } from "interfaces/WebPolicy";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";
import { getWebPolicies } from "services/api/web-policies";

export const WebPolicyStore = types
  .model("WebPolicy", {
    activePolicy: types.optional(types.frozen<WebPolicy>(), null),
    recentViews: types.optional(types.array(types.frozen<WebPolicy>()), []),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setData(payload: StoreListData) {
      const currentData = cloneDeep(self.data);
      self.data = { ...currentData, ...payload };
    },
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActivePolicy(policy: WebPolicy) {
      self.activePolicy = policy ? cloneDeep(policy) : policy;
    },
    addToRecentViews(policy: WebPolicy | null) {
      self.recentViews.unshift(cloneDeep(policy));
      const currentLen = self.recentViews.length;
      if (currentLen > 3) {
        self.recentViews.splice(currentLen - 1, 1);
      }
    },
  }))
  .actions((self) => ({
    getWebPolicies: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getWebPolicies({
          ...self.query,
          ...payload,
        });
        self.data = {
          listItems: resp.data.data.data,
          meta: resp.data.data.pageInfo,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
      } finally {
        NProgress.done();
      }
    }),
  }));
