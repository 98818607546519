export const icons = {
  calendar: require("./Calendar").default,
  angleDown: require("./AngleDown").default,
  check: require("./Check").default,
  overview: require("./Overview").default,
  users: require("./Users").default,
  claims: require("./Claims").default,
  edit: require("./Edit").default,
  star: require("./Star").default,
  info: require("./Info").default,
  cart: require("./Cart").default,
  wallet: require("./Wallet").default,
  compass: require("./Compass").default,
  user: require("./User").default,
  bag: require("./Bag").default,
  search: require("./Search").default,
  marker: require("./Marker").default,
  clock: require("./Clock").default,
  cog: require("./Cog").default,
  refresh: require("./Refresh").default,
  close: require("./Close").default,
  angleDownFilled: require("./AngleDownFilled").default,
  duration: require("./Duration").default,
  handshake: require("./Handshake").default,
};

export type IconTypes = keyof typeof icons;
