import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getClaims = (payload: PaginatedListPayload, flag = false) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  if (!flag) {
    return api.get(`/admin/claims${params}`);
  }
  return api.get(`/claims/external`);
};

export const approveClaimSettlement = (claimId) => {
  return api.post(`/claims/${claimId}/approve-settlement`);
};
export const declineClaimSettlement = (claimId) => {
  return api.post(`/claims/${claimId}/decline-settlement`);
};
