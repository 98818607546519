import React from "react";
import useLoadImage from "hooks/useLoadImage";
import { useEffect } from "react";
import { IStatCard } from "./StatCard";
import styles from "./StatCard.module.scss";
import concatClasses from "utils/concatClasses";
import shortenNumber from "utils/shortenNumber";
import BaseText from "components/BaseText";
import { palette } from "theme/palette";

export default function StatCard({ stats }: IStatCard.IProps) {
  const {
    container,
    statContainerClass,
    statTitleClass,
    statValueClass,
    statPercentageClass,
    statPercentageClass_negative: statPercentageNegativeClass,
    statBottomClass,
  } = styles;

  return (
    <div className={container}>
      {stats.map(({ title, value, percentage, currency, Right, Bottom }) => {
        const percentageIsNegative = percentage < 0;
        const valueIsNegative = value < 0;
        return (
          <div className={statContainerClass}>
            <div>
              <BaseText className={statTitleClass}>{title}</BaseText>
              <BaseText className={statValueClass} >
                {currency && "₦"}
                {typeof value === "number" ? shortenNumber(value) : value}
              </BaseText>
              {percentage ? (
                <BaseText
                  className={concatClasses(
                    statPercentageClass,
                    percentageIsNegative ? statPercentageNegativeClass : ""
                  )}
                >
                  {percentage}%
                </BaseText>
              ) : null}
              {Bottom && (
                <div className={statBottomClass}>
                  {typeof Bottom === "function" ? <Bottom /> : Bottom}
                </div>
              )}
            </div>
            {Right && (
              <div>{typeof Right === "function" ? <Right /> : Right}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}
