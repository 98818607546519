import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {
  Claim,
  DataListMeta,
  PaginatedListPayload,
  StoreListData,
} from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  approveClaimSettlement,
  declineClaimSettlement,
  getClaims,
} from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx state Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const ClaimStore = types
  .model("Claim", {
    toggle: false, // external or internal claims
    customerClaims: types.optional(types.array(types.frozen<Claim>()), []),
    claimsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    activeClaim: types.optional(types.frozen<Claim>(), {}),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get claims() {
      return self.data.listItems;
    },
  }))
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
    getClaimSettlementAmount(claim: Claim) {
      const claimIndex = self.claims.findIndex((c) => c.id === claim.id);
      if (claimIndex >= 0) {
        const claim = self.claims[claimIndex];
        let settlementAmount: number = +claim.amount;
        const { premiumInformation } = claim;
        if (premiumInformation?.outstandingAmount) {
          settlementAmount -= +premiumInformation?.outstandingAmount;
        }
        return settlementAmount;
      }
      return 0;
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    resetToggle() {
      self.toggle = !self.toggle;
    },
    setActiveClaim(claim: Claim) {
      self.activeClaim = cloneDeep(claim);
    },
  }))
  .actions((self) => ({
    getClaims: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getClaims({
          ...self.query,
          ...payload,
        });

        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        //set global error
      }
    }),
  }))

  .actions((self) => ({
    getClaimsExternal: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getClaims(
          {
            ...self.query,
            ...payload,
          },
          true
        );
        const { data, ...rest } = resp;
        const totalCount = Object.keys(data).length;
        self.data = {
          listItems: data?.data,
          meta: {
            limit: 20,
            totalCount,
            page: 1,
            key: [],
          },
        };
        return resp.data;
      } catch (error) {
        //set global error
      }
    }),
  }))

  .actions((self) => ({
    approveClaimSettlement: flow(function* (claimId) {
      try {
        const resp: GenericAxiosResponse = yield approveClaimSettlement(
          claimId
        );
        self.getClaims({});
        self.rootStore.toastStore.addSuccessToast(
          "Settlement successfully approved"
        );
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        console.log(`error`, error);
      }
    }),
    declineClaimSettlement: flow(function* (claimId) {
      try {
        const resp: GenericAxiosResponse = yield declineClaimSettlement(
          claimId
        );
        self.getClaims({});
        self.rootStore.toastStore.addSuccessToast(
          "Settlement successfully declined"
        );
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
