import { PaginatedListPayload } from "interfaces/PaginatedListPayload";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getEnterprises = (payload: PaginatedListPayload) => {
  const params = getURLParams({
    ...defaultPaginatedList,
    ...payload,
    orderBy: "DESC",
    sortBy: "createdAt",
  });
  return api.get(`/admin/enterprise${params}`);
};

export const getEnterprise = (id: string) => {
  return api.get(`/admin/enterprise/${id}`);
};

export const getEnterprisePolicies = (
  id: string,
  payload: PaginatedListPayload
) => {
  const params = getURLParams({
    ...defaultPaginatedList,
    ...payload,
    orderBy: "DESC",
    sortBy: "createdAt",
  });
  return api.get(`/admin/enterprise/${id}/policies${params}`);
};

export const getEnterpriseDrivers = (
  id: string,
  payload: PaginatedListPayload
) => {
  const params = getURLParams({
    ...defaultPaginatedList,
    ...payload,
    orderBy: "DESC",
    sortBy: "createdAt",
  });
  return api.get(`/admin/enterprise/${id}/drivers${params}`);
};
