import React from "react";
import { color } from "theme";

function AngleDownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 16 10"
      {...props}
    >
      <path
        fill={props.fill}
        d="M.47.97a.75.75 0 01.976-.073L1.53.97 8 7.439l6.47-6.47a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084-7 7a.75.75 0 01-.976.073L7.47 9.03l-7-7a.75.75 0 010-1.06z"
      ></path>
    </svg>
  );
}

export default AngleDownIcon;

AngleDownIcon.defaultProps = {
  height: 10,
  width: 16,
  fill: color.palette.black,
};
