import { Icon } from "components";
import { Toast as ToastType } from "interfaces";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { Toast } from "react-bootstrap";
import { useRootStore } from "store";
import { color } from "theme";
import styles from "./ToastNotifications.module.scss";

const bgColors = {
  info: color.primary,
  danger: color.palette.red,
  success: color.green,
  warning: color.palette.yellow,
};

const toastStyle: CSSProperties = {
  minWidth: 200,
  minHeight: 50,
  position: "relative",
};

const toastBodyStyle: CSSProperties = {
  minHeight: 50,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingRight: 40,
};

export const ToastNotifications = observer(() => {
  const { mainContainer, toastsContainer, toastTitle, toastClose } = styles;
  const {
    toastStore: { toasts, removeToast },
  } = useRootStore();

  const renderToasts = () =>
    toasts.map((toast: ToastType, index: number) => (
      <Toast
        style={{ ...toastStyle, backgroundColor: bgColors[toast.bg || "info"] }}
        autohide
        onClose={() => removeToast(index)}
      >
        <Toast.Body style={toastBodyStyle}>
          <Icon
            icon="close"
            fill={color.palette.white}
            containerClass={toastClose}
            onClick={() => removeToast(index)}
            width={16}
            height={16}
          />
          {toast.title && <div className={toastTitle}>{toast.title}</div>}
          <div className="h-100">{toast.body}</div>
        </Toast.Body>
      </Toast>
    ));
  return (
    <div className={mainContainer}>
      <div className={toastsContainer}>{renderToasts()}</div>
    </div>
  );
});
