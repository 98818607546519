import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { BadgeForm, Modal, Table, CogList } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Badge } from "interfaces";
import styles from "./BadgeList.module.scss";
import { IBadgesList } from "./BadgesList";
import capitalize from "utils/capitalize";
import removeUnderscores from "utils/remove-underscores";

const headersList = [
  { header: "Name" },
  { header: "Badge Type" },
  { header: "Rank" },
  { header: "Basis" },
  { header: "Status" },
  { header: "" },
];

const BadgesList: React.FC<IBadgesList.IProps> = observer(
  (props: IBadgesList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { badgeStore } = useRootStore();
    const {
      activeBadge,
      setActiveBadge,
      data: {
        listItems: badges,
        meta: { page, totalCount, limit },
      },
    } = badgeStore;

    const { activeContainer, inactiveContainer } = styles;

    const getCogActions = (badge) => [
      {
        label: "Edit",
        fn: () => {
          setActiveBadge(badge);
          setShowModal(true);
        },
      },
    ];

    const renderBadges = (props: { item: Badge; id: number }) => {
      const { item: badge, id } = props;
      return (
        <tr key={`badge_${id}`}>
          <td>{badge.name}</td>
          <td>{badge.badgeType ? capitalize(badge.badgeType) : ""}</td>
          <td>{badge.rank}</td>
          <td>
            {badge.criteria
              ? capitalize(removeUnderscores(badge.criteria?.basis))
              : ""}
          </td>
          <td>
            <span
              className={badge.active ? activeContainer : inactiveContainer}
            >
              {badge.active ? "Active" : "Inactive"}
            </span>
          </td>
          <td>
            <CogList actions={getCogActions(badge)} />
          </td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={badges}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderBadges}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await badgeStore.getBadges({ page });
          }}
          showAddBtn
          handleAdd={() => {
            setActiveBadge({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveBadge({});
          }}
          show={showModal}
          title={`${activeBadge?.id ? "Edit" : "Create"} Badge`}
        >
          <BadgeForm
            badge={activeBadge}
            handleSubmit={() => {
              setShowModal(false);
              setActiveBadge({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { BadgesList };
