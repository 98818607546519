import { Card, Icon, TextInput } from "components";
import { IClaimWitness } from "./ClaimWitness";
import appStyles from "styles/app.module.scss";
import styles from "./ClaimWitness.module.scss";
import { color } from "theme";
import { useState } from "react";
import splitName from "utils/splitName";
import { ClaimWitness as WitnessType } from "interfaces";

const ClaimWitness: React.FC<IClaimWitness.IProps> = ({ claim }) => {
  const [showAll, setShowAll] = useState(false);

  const { witnesses = [] } = claim;

  const renderWitnesses = () => {
    if (!witnesses.length) {
      return <div className="text-center w-100">No Witnesses</div>;
    }

    const displayWitnesses = showAll ? witnesses : [witnesses[0]];
    return displayWitnesses.map((witness: WitnessType) => (
      <>
        <div className="col-6">
          <TextInput
            withFormik={false}
            name="firstName"
            label="First Name"
            disabled
            value={splitName(witness.name)?.firstName}
          />
          <TextInput
            withFormik={false}
            name="phone"
            label="Phone Number"
            disabled
            value={witness.phoneNumber}
          />
          <TextInput
            withFormik={false}
            name="address"
            label="Home Address"
            disabled
            value={witness.address}
          />
        </div>
        <div className="col-6">
          <TextInput
            withFormik={false}
            name="lastName"
            label="Last Name"
            disabled
            value={splitName(witness.name)?.lastName}
          />
          <div className="d-flex align-items-center">
            <Icon
              icon="marker"
              fill={color.primary}
              withContainer
              containerClass={styles.pinIcon}
              label="Location"
            />
            <div className="text-primary mt-4">
              Lat: 34.397 N, Lon: 150.644E
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr />
        </div>
      </>
    ));
  };

  return (
    <Card bgColor="dark" customClass="px-3 px-md-5 pb-5">
      <div className={appStyles.formTitle}>Witness Details</div>
      <div className="row">
        <div className="col-12 col-md-8 row">
          {renderWitnesses()}

          {witnesses.length > 1 && (
            <div
              className="col-12 d-flex mt-4"
              onClick={() => setShowAll((prev) => !prev)}
            >
              <span
                className="mr-2 font-weight-light"
                style={{ cursor: "pointer" }}
              >
                See {showAll ? "less" : "more"} witnesses
              </span>
              <Icon icon="angleDown" fill={color.primary} />
            </div>
          )}
        </div>

        <div className="col-12 col-md-4 mt-4 mt-md-0">
          <div className={styles.mapPlaceholder}></div>
        </div>
      </div>
    </Card>
  );
};

export { ClaimWitness };
