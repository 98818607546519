import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getInsuranceCompanies = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/insurance-companies/paginate${params}`);
};

export const createInsuranceCompany = (payload) => {
  return api.post(`/insurance-companies`, payload);
};
export const updateInsuranceCompany = (insuranceCompanyId, payload) => {
  return api.put(`/insurance-companies/${insuranceCompanyId}`, payload);
};
