import { Button, Dropdown, Form, TextInput } from "components";
import { IRegionForm } from "./RegionForm";
import { FormikHelpers } from "formik";
import { useRootStore } from "store";
import { RegionSchema } from "schemas";
import { Underwriter } from "enums/underwriter.enum";
import { PaymentProviders } from "enums/payment-providers.enum";
import { Currency } from "enums/currency.enum";

const RegionForm: React.FC<IRegionForm.IProps> = ({
  region,
  handleSubmit = () => ({}),
}) => {
  const { regionStore } = useRootStore();
  const initialValues: IRegionForm.RegionFormValues = {
    name: region?.name,
    paymentProvider: region?.paymentProvider,
    underwriter: region?.underwriter,
    flagPng: region?.flagPng,
    flagSvg: region?.flagSvg,
    currency: region.currency,
    code: region.code,
  };

  const onSubmit = async (
    values: IRegionForm.RegionFormValues,
    actions: FormikHelpers<IRegionForm.RegionFormValues>
  ) => {
    try {
      let resp;
      if (!region.id) {
        resp = await regionStore.createRegion(values);
      } else {
        resp = await regionStore.updateRegion(region.id, values);
      }
      handleSubmit(resp);
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={RegionSchema}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <TextInput
            formikInstance={formikProps}
            name="name"
            type="text"
            label="Name"
            placeholder="Enter name"
          />
          <Dropdown
            withFormik={true}
            formikInstance={formikProps}
            name="paymentProvider"
            label="Payment Provider"
            placeholder="Choose One"
            items={Object.values(PaymentProviders)}
          />
          <Dropdown
            withFormik={true}
            formikInstance={formikProps}
            name="underwriter"
            label="Underwriter"
            placeholder="Choose One"
            items={Object.values(Underwriter)}
          />

          <TextInput
            formikInstance={formikProps}
            name="flagPng"
            label="Flag (PNG)"
            placeholder="Enter url"
          />
          <TextInput
            formikInstance={formikProps}
            name="flagSvg"
            label="Flag (SVG)"
            placeholder="Enter url"
          />
          <TextInput
            formikInstance={formikProps}
            name="code"
            label="code"
            placeholder="Enter region code"
            bottomText="Click to see options"
            onClickBottomText={() =>
              (window.location.href =
                "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2")
            }
          />
          <Dropdown
            withFormik={true}
            formikInstance={formikProps}
            name="currency"
            label="Currency"
            placeholder="Choose One"
            items={Object.values(Currency)}
          />

          <Button
            label="Save"
            type="submit"
            disabled={!formikProps.isValid || formikProps.isSubmitting}
            customClass="mt-4"
          />
        </form>
      )}
    </Form>
  );
};

export { RegionForm };
