import { DataListMeta, VehicleMake } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { createMake, getMakes, updateMake } from "services/api";
import cloneDeep from "utils/cloneDeep";
import { RootStore } from "..";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const MakeStore = types
  .model("Make", {
    makes: types.optional(types.array(types.frozen<VehicleMake>()), []),
    makesMeta: types.optional(types.frozen<DataListMeta>(), {}),
    activeMake: types.optional(types.frozen<VehicleMake>(), {}),
    fetching: false,
    posting: false,
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
    get serializedMakes() {
      return self.makes.map((make) => ({ id: make.id, text: make.name }));
    },
  }))
  .actions((self) => ({
    setActiveMake(make: VehicleMake) {
      self.activeMake = cloneDeep(make);
    },
  }))
  .actions((self) => ({
    getMakes: flow(function* (payload) {
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getMakes(payload);
        const { data, ...rest } = resp.data.data;
        self.makes = data;
        self.makesMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.fetching = false;
      }
    }),
  }))
  .actions((self) => ({
    createMake: flow(function* (payload) {
      try {
        self.posting = true;
        const resp: GenericAxiosResponse = yield createMake(payload);
        self.getMakes({});
        self.posting = false;
        self.rootStore.toastStore.addSuccessToast(
          "Vehicle Make successfully created"
        );
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.posting = false;
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateMake: flow(function* (makeId, payload) {
      try {
        self.posting = true;
        const resp: GenericAxiosResponse = yield updateMake(makeId, payload);
        self.getMakes({});
        self.posting = false;
        self.rootStore.toastStore.addSuccessToast(
          "Vehicle Make successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
        self.posting = false;
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
