import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Enterprise } from "interfaces";
import { IEnterpriseList } from "./EnterpriseList";
import appStyles from "styles/app.module.scss";
import { formatDate } from "utils/dates";

const headersList = [
  { header: "Enterprise" },
  { header: "Email" },
  { header: "Number" },
  { header: "Date Joined" },
];

const EnterpriseList: React.FC<IEnterpriseList.IProps> = observer(
  (props: IEnterpriseList.IProps) => {
    const { bgHoverClass_grey } = appStyles;
    const { enterpriseStore } = useRootStore();
    const {
      getEnterprises,
      data: {
        listItems: enterprises,
        meta: { page, totalCount, limit },
      },
    } = enterpriseStore;

    const history = useHistory();

    const renderEnterprise = (props: { item: Enterprise; id: number }) => {
      const { item: enterprise, id } = props;

      return (
        <tr
          key={`enterprise_${id}`}
          className={bgHoverClass_grey}
          onClick={() => history.push(`enterprises/${enterprise.id}`)}
        >
          <td className="text-muted mt-2">{enterprise.enterpriseName}</td>
          <td>{enterprise.email}</td>
          <td>{enterprise.phone_number || "-"}</td>
          <td>{formatDate(enterprise.createdAt, "ll")}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={enterprises}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderEnterprise}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getEnterprises({ page });
          }}
          showRefresh
          handleRefresh={() => getEnterprises({})}
        />
      </>
    );
  }
);

export { EnterpriseList };
