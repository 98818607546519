import React from "react";

function CompassIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M10.361 0c5.713 0 10.361 4.648 10.361 10.361 0 5.713-4.648 10.361-10.361 10.361C4.648 20.722 0 16.074 0 10.361 0 4.648 4.648 0 10.361 0zm0 1.5C5.475 1.5 1.5 5.476 1.5 10.361c0 4.886 3.975 8.861 8.861 8.861 4.886 0 8.861-3.975 8.861-8.861 0-4.885-3.975-8.861-8.861-8.861zm3.872 4.99a.75.75 0 01.185.754l-1.592 5.089a.75.75 0 01-.492.492l-5.09 1.593a.757.757 0 01-.754-.185.75.75 0 01-.185-.754l1.593-5.09a.746.746 0 01.49-.491l5.09-1.593a.748.748 0 01.755.185zm-1.674 1.674L9.212 9.212l-1.047 3.347 3.346-1.048 1.048-3.347z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CompassIcon;
