import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

// export const getPolicies = ({
//   page = 1,
//   limit = 20,
//   query = "",
//   sortBy = "",
//   orderBy = "DESC",
//   status = "",
// }) => {
export const getPolicies = (payload: PaginatedListPayload, flag = false) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  if (!flag) {
    return api.get(`/admin/policies${params}`);
  }
  return api.get(`/admin/policies/external${params}`);
};

export const updatePolicy = (policyId: number, payload) => {
  return api.put(`/api/premiums/${policyId}`, payload);
};

export const renewPolicy = (policyId: number, numOfPayments) => {
  return api.post(`/payments/${policyId}/future-renewal`, { numOfPayments });
};

export const retryPolicyCreate = (policyId: number) => {
  return api.post(`/api/premiums/${policyId}/create-policy`);
};

export const logTransferPayment = (payload) => {
  return api.post(`admin/payments/manual-transfer`, payload);
};
