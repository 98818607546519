import React from "react";
import { color } from "theme";
import styles from "./DatePicker.module.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import MomentUtils from "@date-io/moment";
import { Icon } from "../index";
import { IDatePicker } from "./DatePicker";
import { FormControl } from "components/FormControl";
import { formatDate } from "utils/dates";
import { DEFAULT_DATE_FORMAT } from "utils/constants";
// import { useField, useFormikContext } from "formik";

class LocalizedUtils extends MomentUtils {
  getYearRange(start, end) {
    const startDate = this.moment(end).startOf("year");
    const endDate = this.moment(start).endOf("year");
    const years = [];

    let current = startDate;
    while (current.isAfter(endDate)) {
      years.push(current);
      current = current.clone().subtract(1, "year");
    }

    return years;
  }
}

export const DateInput: React.FC<IDatePicker.IProps> = ({ ...props }) => {
  const { name, value, withFormik, formikInstance } = props;
  // const { setFieldValue } = useFormikContext();
  // const [field] = useField(props);
  const controlValue = withFormik ? formikInstance.values[name] : value;
  const [isDateDialogueOpen, setIsDateDialogueOpen] = React.useState(false);

  const onChangeHandler = (e) =>
    withFormik ? formikInstance.setFieldValue(name, e) : props.onChange(e);

  const onBlurHandler = () =>
    withFormik ? formikInstance.setFieldTouched(name, true) : props.onBlur();

  const inputConfig = {
    onChange: onChangeHandler,
    onBlur: onBlurHandler,
  };

  const materialTheme = createTheme({
    palette: {
      primary: {
        main: color.primary,
      },
      secondary: {
        main: color.secondary,
      },
    },
    typography: {
      fontFamily: "CharlieDisplay",
    },
  });

  const handleAccept = (value) => {
    setIsDateDialogueOpen(false);
    onBlurHandler();
    onChangeHandler(value);
    if (props.onAcceptDate) {
      props.onAcceptDate();
    }
  };

  const handleChange = (value) => {
    setIsDateDialogueOpen(false);
    onChangeHandler(formatDate(value?.toDate()));
    onBlurHandler();
  };

  return (
    <FormControl {...props}>
      {
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <KeyboardDatePicker
              {...inputConfig}
              margin="normal"
              variant="dialog"
              format={DEFAULT_DATE_FORMAT}
              // id={props.id}
              name={name}
              open={props.isDateDialogueOpen || isDateDialogueOpen}
              placeholder={props.placeholder}
              value={controlValue}
              // onBlur={props.onBlur}
              autoComplete={"chrome-off"}
              onOpen={() => setIsDateDialogueOpen(true)}
              showTodayButton
              disableToolbar={false}
              minDate={props.minDate}
              maxDate={props.maxDate}
              onClose={() => {
                if (props.onCloseDateDialogue) {
                  props.onCloseDateDialogue();
                }
                setIsDateDialogueOpen(false);
              }}
              okLabel="sss"
              onChange={(value) => handleChange(value)}
              onAccept={(value) => handleAccept(value)}
              className={styles.datePickerCustom}
              style={{
                backgroundColor: !controlValue
                  ? props.inputBgColor
                  : color.palette.deepGrey,
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<Icon icon="calendar" />}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      }

      {isDateDialogueOpen || props.isDateDialogueOpen ? (
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <DatePicker
              {...inputConfig}
              name={name}
              value={controlValue}
              open={isDateDialogueOpen || props.isDateDialogueOpen}
              margin="normal"
              variant="dialog"
              label="Date picker dialog"
              format={DEFAULT_DATE_FORMAT}
              okLabel={props.dateDialogueOkLabel || "Ok"}
              onOpen={() => setIsDateDialogueOpen(true)}
              onAccept={(value) => handleAccept(value)}
              onClose={() => {
                setIsDateDialogueOpen(false);
                if (props.onBlur) {
                  props.onBlur();
                }
              }}
              onSelect={() => alert("sss")}
              style={{ display: "none" }}
              // onChange={(value) => handleChange(value)}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ) : null}
    </FormControl>
  );
};

DateInput.defaultProps = {
  placeholder: "",
  style: {},
  onBlur: () => ({}),
  onChange: () => ({}),
  withFormik: true,
};
