import React from "react";
import useLoadImage from "hooks/useLoadImage";
import { IAvatar } from "./Avatar";
import styles from "./Avatar.module.scss";
import concatClasses from "utils/concatClasses";

enum AvatarSizes {
  sm = "24px",
  md = "32px",
  lg = "48px",
}

enum TagSizes {
  sm = "8px",
  md = "16px",
  lg = "20px",
}

export default function Avatar({
  src,
  size = "md",
  color,
  label = "",
  left = true,
}: IAvatar.IProps) {
  const isImageLoaded = useLoadImage(src);
  const avatarSize = AvatarSizes[size];
  const tagSize = TagSizes[size];

  return (
    <div className={styles.wrapper}>
      <figure
        className={concatClasses(
          styles.container,
          left ? styles.container_left : ""
        )}
      >
        <div
          className={styles.avatarImageWrapper}
          style={{ height: avatarSize, width: avatarSize }}
        >
          {isImageLoaded ? (
            <img
              className={styles.avatarImage}
              src={src}
              alt={"profile avatar"}
            />
          ) : null}
          <div
            className={
              isImageLoaded
                ? styles.tag
                : concatClasses(styles.tag, styles.tag_loading)
            }
          >
            <p className={styles.tag__text} style={{ fontSize: tagSize }}>
              {label[0]}
            </p>
          </div>
        </div>
        <figcaption className={styles.label}>{label}</figcaption>
      </figure>
    </div>
  );
}
