import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  CogList,
  Modal,
  PolicyDetail,
  Table,
  TextInput,
} from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Policy } from "interfaces";
import { getVerboseName } from "utils/vehicle";
import { formatDate } from "utils/dates";
import { IEnterprisePoliciesList } from "./EnterprisePoliciesList";
import appStyles from "styles/app.module.scss";
import { UserRole } from "enums/userRole.enum";
import { useParams } from "react-router-dom";
import { color } from "theme";

const EnterprisePoliciesList: React.FC<IEnterprisePoliciesList.IProps> =
  observer((props: IEnterprisePoliciesList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const [showRenewalModal, setShowRenewalModal] = useState(false);
    const [busy, setBusy] = useState(false);
    const [numOfRenewals, setNumOfRenewals] = useState();
    const { policyStore, authStore, enterpriseStore } = useRootStore();
    const { user } = authStore;
    const { setActivePolicy, renewPolicy, hasCreationError } = policyStore;
    const {
      getEnterprisePolicies,
      data: {
        listItems: policies,
        meta: {
          page,
          totalCount,
          limit,
          pendingRenewalPolicies,
          activePolicies,
          expiredPolicies,
        },
      },
    } = enterpriseStore;

    const headersList = useMemo(
      () => [
        { header: "Vehicle" },
        { header: "Customer" },
        { header: "Start Date" },
        { header: "End Date" },
        { header: "Policy Number" },
        { header: "Band" },
        { header: "" },
      ],
      []
    );

    const isSuperUser = user.role === UserRole.SUPER_ADMIN;
    const { id } = useParams();

    const getCogActions = (policy) => [
      {
        label: "Renew Advance",
        fn: () => {
          setActivePolicy(policy);
          setShowRenewalModal(true);
        },
      },
    ];

    const renderPolicies = (props: { item: Policy; id: number }) => {
      const { item: policy, id } = props;
      const showError = hasCreationError(policy);
      return (
        <tr
          key={`policy_${id}`}
          style={{
            backgroundColor: showError
              ? color.palette.lightRed
              : color.palette.white,
          }}
          onClick={() => {
            setActivePolicy(policy);
            setShowModal(true);
          }}
        >
          <td>
            <div>
              <div>
                {policy.vehicleInformation
                  ? getVerboseName(policy.vehicleInformation)
                  : "-"}
              </div>
              <span className="text-muted mt-2">
                {policy.vehicleInformation?.registrationNumber}
              </span>
            </div>
          </td>
          <td>{policy.user?.email || "-"}</td>
          <td>{formatDate(policy.startDate, "ll")}</td>
          <td className="text-danger">{formatDate(policy.expiryDate, "ll")}</td>
          <td>{policy.policyNumber}</td>
          <td>{policy.band}</td>{" "}
          {isSuperUser ? (
            <td>
              <CogList actions={getCogActions(policy)} />
            </td>
          ) : null}
        </tr>
      );
    };

    const handlePolicyRenewal = async () => {
      if (busy) {
        return;
      }
      setBusy(true);
      await renewPolicy(numOfRenewals ? +numOfRenewals : 0);
      setBusy(false);
      setShowRenewalModal(false);
    };

    const displayedHeaders = isSuperUser
      ? headersList
      : [...headersList].slice(0, -1);

    return (
      <>
        <Table
          listItems={policies}
          headersList={displayedHeaders}
          totalCount={totalCount}
          renderItems={renderPolicies}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={+(page || 1)}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            getEnterprisePolicies(id, { page });
          }}
          topStats={[
            {
              label: "Active",
              value: activePolicies,
              className: appStyles.successCard,
            },
            { label: "Pending Renewal", value: pendingRenewalPolicies },

            {
              label: "Expired",
              value: expiredPolicies,
              className: appStyles.dangerCard,
            },
          ]}
          showRefresh={true}
          handleRefresh={() => getEnterprisePolicies(id)}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActivePolicy({});
          }}
          show={showModal}
          title={`Manage Policy`}
          size="xl"
        >
          <PolicyDetail />
        </Modal>
        <Modal
          handleHide={() => {
            setShowRenewalModal(false);
            setActivePolicy({});
          }}
          show={showRenewalModal}
          title={`Renew Policy`}
        >
          <div>
            <TextInput
              name="name"
              type="number"
              label="Number of Renewals"
              placeholder="Enter number"
              value={numOfRenewals}
              withFormik={false}
              onChange={(e) => setNumOfRenewals(e.target.value)}
            />

            <Button
              label="Save"
              disabled={!numOfRenewals || busy}
              customClass="mt-4"
              type="button"
              onClick={handlePolicyRenewal}
            />
          </div>
        </Modal>
      </>
    );
  });

export { EnterprisePoliciesList };
