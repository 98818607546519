import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, RideDetail, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Ride } from "interfaces";
import styles from "./RidesList.module.scss";
import { IRidesList } from "./RidesList";
import getFullName from "utils/fullName";

const headersList = [
  { header: "User" },
  { header: "Distance" },
  { header: "Start At" },
  { header: "End At" },
  { header: "Score" },
];

const RidesList: React.FC<IRidesList.IProps> = observer(
  (props: IRidesList.IProps) => {
    const { addressTd } = styles;
    const [showModal, setShowModal] = useState(false);
    const { rideStore } = useRootStore();
    const {
      setActiveRide,
      getRides,
      data: {
        listItems: rides,
        meta: { page, totalCount, limit },
      },
    } = rideStore;

    const renderRides = (props: { item: Ride; id: number }) => {
      const { item: ride, id } = props;
      const distance = (+ride.distance / 1000).toFixed(2);
      return (
        <tr
          key={`ride_${id}`}
          onClick={() => {
            setActiveRide(ride);
            setShowModal(true);
          }}
        >
          <td>
            <div>
              <div>{getFullName(ride.user)}</div>
              <span className="text-muted mt-2">{ride.user?.email}</span>
            </div>
          </td>
          <td>
            {distance} <small>km</small>
          </td>
          <td>
            <div className={addressTd}> {ride.startLocation}</div>
          </td>
          <td>
            <div className={addressTd}> {ride.endLocation}</div>
          </td>
          <td className="text-success">{ride.score}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={rides}
          headersList={headersList}
          totalCount={totalCount}
          renderItems={renderRides}
          pageCount={getPageCount(totalCount, limit)}
          usePagination
          currentPage={page || 1}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getRides({ page });
          }}
          showRefresh
          handleRefresh={() => getRides({})}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveRide({});
          }}
          show={showModal}
          title={`Ride Detail`}
          size="xl"
        >
          <RideDetail />
        </Modal>
      </>
    );
  }
);

export { RidesList };
