import { FancySpinner, TopProgressBar } from "components";

export const ContentLoader = () => (
  <div
    className="d-flex flex-column justify-content-center align-items-center w-100"
    style={{ height: "100vh" }}
  >
    <TopProgressBar />
    <FancySpinner />
  </div>
);
