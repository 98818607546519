import React from "react";
import useLoadImage from "hooks/useLoadImage";
import { useEffect } from "react";
import { IImage } from "./Image";
import styles from "./Image.module.scss";
import concatClasses from "utils/concatClasses";

enum LabelSizes {
  sm = "40p",
  md = "70px",
  lg = "100px",
}

enum ImageSizes {
  sm = "70px",
  md = "100px",
  lg = "160px",
}

export default function Image({
  src,
  size,
  color,
  label = "",
  labelFontSize,
  height,
  width,
  customClass,
  style,
}: IImage.IProps) {
  const isImageLoaded = useLoadImage(src);
  const labelSize = labelFontSize || LabelSizes[size];

  return (
    <div
      className={concatClasses(styles.wrapper, customClass || "")}
      style={
        size
          ? { height: ImageSizes[size], width: ImageSizes[size] }
          : { ...(height ? { height } : {}), ...(width ? { width } : {}) }
      }
    >
      <figure className={styles.container}>
        {isImageLoaded ? <img className={styles.image} src={src} /> : null}
        <div
          className={
            isImageLoaded
              ? styles.label__container
              : concatClasses(
                  styles.label__container,
                  styles.label__container_loading
                )
          }
        >
          <figcaption
            className={styles.label__text}
            style={{ fontSize: labelSize }}
          >
            {label}
          </figcaption>
        </div>
      </figure>
    </div>
  );
}

Image.defaultProps = {
  style: {},
};
