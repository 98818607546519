import React from "react";
import concatClasses from "utils/concatClasses";
import { ICard } from "./Card";
import styles from "./Card.module.scss";

export const Card: React.FC<ICard.IProps> = ({ ...props }) => {
  return (
    <div
      className={concatClasses(
        styles.card,
        props.bgColor === `light` ? styles.lightbg : styles.darkbg,
        props.shadow ? styles.outline : "",
        props.border ? "" : styles.noBorder,
        props.customClass
      )}
    >
      {props.children}
    </div>
  );
};

Card.defaultProps = {
  border: true,
  bgColor: "light",
  shadow: false,
};
