import { UserRole } from "enums/userRole.enum";
import * as Yup from "yup";

const AdminSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phone: Yup.string(),
  role: Yup.mixed<UserRole>()
    .oneOf(Object.values(UserRole))
    .required("Required"),
});

export default AdminSchema;
