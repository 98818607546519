import { types } from "mobx-state-tree";
import { AuthStore } from "./auth";
import { CounterStore } from "./counter";
import { persist } from "mst-persist";
import { PolicyStore } from "./policy";
import { EnterpriseStore } from "./enterprises";
import { WebPolicyStore } from "./webPolicies";
import { ClaimStore } from "./claim";
import { VehiclePartsStore } from "./vehicleParts";
import { ProductsStore } from "./products";
import { MakeStore } from "./vehicleMake";
import { VehicleModelStore } from "./vehicleModel";
import { CrashStore } from "./crash";
import { BankStore } from "./banks";
import { CustomersStore } from "./customers";
import { OverviewStore } from "./overview";
import { BanksStore } from "./bank";
import { ToastStore } from "./toasts";
import { PointStore } from "./points";
import { RideStore } from "./rides";
import { PartnerStore } from "./partners";
import { AdminStore } from "./admins";
import { UserStore } from "./users";
import { BroadcastStore } from "./broadcasts";
import { DailySettlementStore } from "./dailySettlements";
import { ServiceCenterStore } from "./serviceCenters";
import { RiskProfileStore } from "./riskProfiles";
import { ImagesStore } from "./images";
import { GalleryStore } from "./gallery";
import { insuranceCompaniesStore } from "./insuranceCompanies";
import { RegionStore } from "./regions";
import { BadgeStore } from "./badges";
import { VehicleInspectionStore } from "./vehicleInspection";

export const RootStore = types.model("RootStore", {
  counterStore: CounterStore,
  authStore: AuthStore,
  policyStore: PolicyStore,
  claimStore: ClaimStore,
  vehiclePartsStore: VehiclePartsStore,
  customersStore: CustomersStore,
  productsStore: ProductsStore,
  makeStore: MakeStore,
  vehicleModelStore: VehicleModelStore,
  crashStore: CrashStore,
  bankStore: BankStore,
  overviewStore: OverviewStore,
  banksStore: BanksStore,
  toastStore: ToastStore,
  pointStore: PointStore,
  rideStore: RideStore,
  partnerStore: PartnerStore,
  adminStore: AdminStore,
  userStore: UserStore,
  broadcastStore: BroadcastStore,
  dailySettlementStore: DailySettlementStore,
  serviceCenterStore: ServiceCenterStore,
  riskProfileStore: RiskProfileStore,
  imagesStore: ImagesStore,
  galleryStore: GalleryStore,
  insuranceCompaniesStore: insuranceCompaniesStore,
  regionStore: RegionStore,
  badgeStore: BadgeStore,
  vehicleInspectionStore: VehicleInspectionStore,
  enterpriseStore: EnterpriseStore,
  webPolicyStore: WebPolicyStore,
});

export const createRootStore = () => {
  const authStore = AuthStore.create();
  persist("auth", authStore);

  const overviewStore = OverviewStore.create();
  persist("overview", overviewStore);

  return RootStore.create({
    counterStore: CounterStore.create(),
    authStore,
    policyStore: PolicyStore.create(),
    claimStore: ClaimStore.create(),
    vehiclePartsStore: VehiclePartsStore.create(),
    customersStore: CustomersStore.create(),
    productsStore: ProductsStore.create(),
    makeStore: MakeStore.create(),
    vehicleModelStore: VehicleModelStore.create(),
    crashStore: CrashStore.create(),
    bankStore: BankStore.create(),
    overviewStore,
    banksStore: BanksStore.create(),
    toastStore: ToastStore.create(),
    pointStore: PointStore.create(),
    rideStore: RideStore.create(),
    partnerStore: PartnerStore.create(),
    adminStore: AdminStore.create(),
    userStore: UserStore.create(),
    broadcastStore: BroadcastStore.create(),
    dailySettlementStore: DailySettlementStore.create(),
    serviceCenterStore: ServiceCenterStore.create(),
    riskProfileStore: RiskProfileStore.create(),
    imagesStore: ImagesStore.create(),
    galleryStore: GalleryStore.create(),
    insuranceCompaniesStore: insuranceCompaniesStore.create(),
    regionStore: RegionStore.create(),
    badgeStore: BadgeStore.create(),
    vehicleInspectionStore: VehicleInspectionStore.create(),
    enterpriseStore: EnterpriseStore.create(),
    webPolicyStore: WebPolicyStore.create(),
  });
};
