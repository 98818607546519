import { DataListMeta, Broadcast } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import NProgress from "nprogress";
import {
  createBroadcast,
  getBroadcasts,
  updateBroadcast,
  resendBroadcast,
} from "services/api";
import { RootStore } from "..";

export const BroadcastStore = types
  .model("Broadcast", {
    broadcasts: types.optional(types.array(types.frozen<Broadcast>()), []),
    broadcastsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
    activeBroadcast: types.optional(types.frozen<Broadcast>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setActiveBroadcast(broadcast: Broadcast) {
      self.activeBroadcast = broadcast;
    },
    getBroadcasts: flow(function* (payload) {
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getBroadcasts(payload);
        const { data, ...rest } = resp.data;
        self.broadcasts = data;
        self.broadcastsMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        self.fetching = false;
      }
    }),
  }))
  .actions((self) => ({
    createBroadcast: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createBroadcast(payload);
        self.getBroadcasts({});
        self.rootStore.toastStore.addSuccessToast(
          "Broadcast successfully created"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateBroadcast: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateBroadcast(
          vehicleModelId,
          payload
        );
        self.getBroadcasts({});
        self.rootStore.toastStore.addSuccessToast(
          "Broadcast successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    resend: flow(function* (broadcastId) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield resendBroadcast(broadcastId);
        self.rootStore.toastStore.addSuccessToast(
          "Broadcast successfully resent"
        );
        NProgress.done();
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        NProgress.done();
        throw error;
      }
    }),
  }));
