/**
 *
 * @param csv csv object
 * @param column column name to extract data from
 * @returns array of column data
 */
const extractDataFromCsv = (csv, column = "id") => {
  const colIdx = csv.headerRow.findIndex(
    (row) => row.toLowerCase() === column.toLowerCase()
  );
  return csv.rows.map((row) => {
    const rowSplit = row.split(",");
    return rowSplit[colIdx];
  });
};

export default extractDataFromCsv;
