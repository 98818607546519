import { PaginatedListPayload, ServiceCenter, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import {
  createServiceCenter,
  getServiceCenters,
  updateServiceCenter,
} from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";

export const ServiceCenterStore = types
  .model("ServiceCenter", {
    activeServiceCenter: types.optional(types.frozen<ServiceCenter>(), {}),
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveServiceCenter(serviceCenter: ServiceCenter) {
      self.activeServiceCenter = serviceCenter;
    },
    getServiceCenters: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield getServiceCenters({
          ...self.query,
          ...payload,
        });
        const { data, ...rest } = resp.data;

        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      }
    }),
  }))
  .actions((self) => ({
    createServiceCenter: flow(function* (payload) {
      try {
        const resp: GenericAxiosResponse = yield createServiceCenter(payload);
        self.getServiceCenters({});
        self.rootStore.toastStore.addSuccessToast(
          "Service center successfully created"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    updateServiceCenter: flow(function* (vehicleModelId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateServiceCenter(
          vehicleModelId,
          payload
        );
        self.getServiceCenters({});
        self.rootStore.toastStore.addSuccessToast(
          "Service center successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
