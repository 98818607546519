import React from "react";

function CartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M5.544 16.952a1.296 1.296 0 010 2.589 1.296 1.296 0 010-2.59zm11.28 0c.716 0 1.297.58 1.297 1.294a1.296 1.296 0 11-1.296-1.294zM1.27.009l2.08.36c.335.06.59.337.619.677l.235 2.801h.873l.424.001h1.604l.38.001 1.77.001h.33l1.242.001h.291l1.092.001h.255l.952.001h.847l.196.001h.725l.167.001h.611l.14.001h.389l.12.001h.433l.097.001h.266l.081.001h.286l.063.001h.167l.05.001.17.001h.128l.026.001h.067l.018.001h.046l.012.001h.028l.008.001.02.001h.004l.013.001a2.083 2.083 0 011.38.82c.335.447.475.998.395 1.55l-.95 6.558a2.562 2.562 0 01-2.522 2.19H5.976a2.562 2.562 0 01-2.54-2.344L2.522 1.748l-1.507-.26A.752.752 0 01.402.62a.76.76 0 01.867-.61zm3.607 5.339h-.547l.603 7.172c.044.552.495.966 1.046.966h10.917c.52 0 .966-.389 1.04-.904l.95-6.559a.587.587 0 00-.112-.438.58.58 0 00-.388-.23h-.16l-.076.001-.566.001h-1.708l-1.52-.001H13.219l-.297-.001h-.91l-.31-.001h-.628l-.317-.001-.958-.001H8.838l-.32-.001h-.635l-.315-.001h-.93l-.304-.001-1.176-.001h-.282zm10.413 2.196a.75.75 0 010 1.5h-2.772a.75.75 0 110-1.5h2.772z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CartIcon;
