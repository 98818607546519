import { PaginatedListPayload, RiskProfile, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import { getRiskProfiles } from "services/api";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";
import { RootStore } from "..";
import NProgress from "nprogress";

export const RiskProfileStore = types
  .model("RiskProfile", {
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
    activeRiskProfile: types.optional(types.frozen<RiskProfile>(), null),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    setActiveRiskProfile(riskProfile: RiskProfile) {
      self.activeRiskProfile = riskProfile;
    },
    getRiskProfiles: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getRiskProfiles({
          ...self.query,
          ...payload,
        });
        console.log(resp.data.data)
        const { data, ...rest } = resp.data.data;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return resp.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        NProgress.done();
      }
    }),
  }));
