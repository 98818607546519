import React from "react";
import styles from "./Gallery.module.scss";
import { IGallery } from "./Gallery";
import ImageGallery from "react-image-gallery";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";
import { Icon } from "components";

export const Gallery: React.FC<IGallery.IProps> = observer(({ items }) => {
  const {
    galleryStore: { items: galleryItems, isVisible, setVisibility },
  } = useRootStore();

  const { close } = styles;

  return isVisible ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#000",
        zIndex: 1055,
      }}
    >
      <div className={close} onClick={() => setVisibility(false)}>
        <Icon icon="close" fill="red" />
      </div>
      <ImageGallery autoPlay lazyLoad items={galleryItems} />
    </div>
  ) : null;
});
