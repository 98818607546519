import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Customer } from "interfaces";
import styles from "./ClaimList.module.scss";
import { ICustomersList } from "./CustomersList";
import appStyles from "styles/app.module.scss";
import formatNumber from "utils/formatMoney";
import { formatDate } from "utils/dates";

const headersList = [
  { header: "Customer" },
  { header: "Email" },
  { header: "Number" },
  { header: "Points" },
  { header: "Score" },
  { header: "Date Joined" },
];

const CustomersList: React.FC<ICustomersList.IProps> = observer(
  (props: ICustomersList.IProps) => {
    const { bgHoverClass_grey } = appStyles;
    const { customersStore } = useRootStore();
    const {
      getCustomers,
      data: {
        listItems: customers,
        meta: { page, totalCount, limit },
      },
    } = customersStore;

    const history = useHistory();

    console.log(`pagexx`, page);

    const renderCustomer = (props: { item: Customer; id: number }) => {
      const { item: customer, id } = props;

      return (
        <tr
          key={`customer_${id}`}
          className={bgHoverClass_grey}
          onClick={() => history.push(`customers/${customer.id}`)}
        >
          <td className="text-muted mt-2">{`${customer.firstname} ${customer.lastname}`}</td>
          <td>{customer.email}</td>
          <td>{customer.phone || "-"}</td>
          <td>
            {customer.point?.point
              ? formatNumber(customer.point?.point)
              : (0).toFixed(2)}
          </td>
          <td>
            {customer.point?.drivingScore
              ? formatNumber(customer.point?.drivingScore)
              : (0).toFixed(2)}
          </td>
          <td>{formatDate(customer.createdAt, "ll")}</td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={customers}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderCustomer}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await getCustomers({ page });
          }}
          showRefresh
          handleRefresh={() => getCustomers({})}
        />
      </>
    );
  }
);

export { CustomersList };
