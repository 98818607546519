import React, { useState } from "react";
import { color } from "theme";
import { IButton } from "./Button";
import styles from "./Button.module.scss";

export const Button: React.FC<IButton.IProps> = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const btnClass = `btn btn-primary ${styles.button} ${
    props.outline ? styles.outlineButton : ""
  } ${props.customClass}`;

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setIsLoading(true);
      await props.onClick(event);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <button
      style={props.style}
      type={props.type}
      className={btnClass}
      onClick={handleClick}
      disabled={props.disabled || (isLoading && props.loadingDisable)}
    >
      {props.isLoading || (isLoading && props.useLoader) ? (
        <div
          className={styles.loader}
          style={{
            borderTopColor: props.loaderColor,
          }}
        ></div>
      ) : (
        props.label
      )}
    </button>
  );
};

Button.defaultProps = {
  customClass: "",
  isLoading: false,
  onClick: () => ({}),
  outline: false,
  loaderColor: color.primary,
  loadingDisable: true,
  useLoader: false,
};
