import { PrivateRoute, ProtectedRoutes, PublicRoute } from "components";
import { observer } from "mobx-react-lite";
import Login from "pages/Login";
import { Switch, Route, Redirect } from "react-router-dom";
import { useRootStore } from "store";
import NotFound from "pages/404";
import { UserRole } from "enums/userRole.enum";

const Routes = observer(() => {
  const { authStore } = useRootStore();
  const {
    isAuthenticated,
    user: { role },
  } = authStore;

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return (
            <Redirect
              to={role === UserRole.SUPER_ADMIN ? "/overview" : "/policies"}
            />
          );
        }}
      />
      <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
        <Login />
      </PublicRoute>

      {/* other public routes */}

      <PrivateRoute path="/" isAuthenticated={isAuthenticated} roles={role}>
        <ProtectedRoutes />
      </PrivateRoute>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
});

export { Routes };
