import * as Yup from "yup";

const VehiclePartSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  partsCategoryId: Yup.number().required("Required"),
  make: Yup.number().required("Required"),
  model: Yup.number().required("Required"),
  price: Yup.number().min(1).required("Required"),
  side: Yup.string(),
  year: Yup.number()
    .min(1990)
    .max(new Date().getFullYear())
    .required("Required"),
});

export default VehiclePartSchema;
