import React from "react";
import { ITextInput } from "./TextInput";
// import styles from "./TextInput.module.scss";
import appStyles from "../../styles/app.module.scss";
import NumberFormat from "react-number-format";
import { FormControl } from "components/FormControl";
import extractFormikValue from "utils/extract-formik-value";

export const TextInput: React.FC<ITextInput.IProps> = ({ ...props }) => {
  const { name, placeholder, value, withFormik, formikInstance, type } = props;

  const controlValue = withFormik
    ? extractFormikValue(name, formikInstance.values)
    : value;

  const inputConfig = {
    onChange: withFormik
      ? (e) =>
          formikInstance.setFieldValue(
            name,
            type === "number" ? parseInt(e.target.value) : e.target.value
          )
      : props.onChange,
    onBlur: withFormik
      ? () => formikInstance.setFieldTouched(name, true)
      : props.onBlur,
  };

  return (
    <FormControl {...props}>
      {props.maskFormat ? (
        <NumberFormat
          format={props.maskFormat}
          className={`${appStyles.textInput} ${props.inputClass || ""}`}
          placeholder={placeholder}
          value={controlValue}
          disabled={props.disabled}
          onValueChange={(values) => {
            const { value: returnedValue } = values;
            if (withFormik) {
              formikInstance.setFieldValue(name, returnedValue);
            } else {
              props.onChange({
                target: {
                  value: returnedValue,
                  name: props.name,
                },
              });
            }
          }}
        />
      ) : (
        <input
          {...inputConfig}
          className={`${appStyles.textInput} ${props.inputClass || ""}`}
          // id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          value={controlValue}
          // onBlur={props.onBlur}
          // onChange={props.onChange}
          type={props.type}
          disabled={props.disabled}
          ref={props.setRef}
        />
      )}
    </FormControl>
  );
};

TextInput.defaultProps = {
  placeholder: "",
  style: {},
  inputClass: "",
  onChange: () => ({}),
  onBlur: () => ({}),
  withFormik: true,
};
