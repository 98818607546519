import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { IPaginate } from "./Pagination";
import styles from "./Pagination.module.scss";

export const Paginate: React.FC<IPaginate.IProps> = ({ ...props }) => {
  const [pageNum, setPageNum] = useState(props.currentPage || 0);
  let nextClassName = styles.nextLabel;
  if (props.pageCount <= 1) {
    nextClassName += ` ${styles.disabled}`;
  }
  let prevClassName = styles.previousLabel;
  if (!pageNum) {
    prevClassName += ` ${styles.disabled}`;
  }

  const handlePageChange = async (e) => {
    const { selected } = e;
    console.log(`selected, pageNum`, selected, pageNum);
    // if (pageNum != selected) {
    //   console.log("NO CHR");
    await props.paginationHandler(selected + 1);
    // }

    setPageNum(selected);
  };

  return (
    <div className={styles.PaginationContainer}>
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        breakLabel={"..."}
        activeClassName={styles.activeLabel}
        previousClassName={prevClassName}
        nextClassName={nextClassName}
        breakClassName={styles.breakLabel}
        previousLinkClassName={styles.previousLinkLabel}
        nextLinkClassName={styles.nextLinkLabel}
        activeLinkClassName={styles.activeLinkLabel}
        pageClassName={styles.pageLabel}
        pageLinkClassName={styles.pageLinkLabel}
        breakLinkClassName={styles.breakLinkLabel}
        containerClassName={styles.pagination}
        initialPage={props.initialPage || 0}
        pageCount={props.pageCount || 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        // disableInitialCallback={true}
        onPageChange={(e) => {
          handlePageChange(e);
        }}
      />
    </div>
  );
};

Paginate.defaultProps = {
  paginationHandler: () => ({}),
};
