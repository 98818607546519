import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getAdmins = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/admin/admin-users${params}`);
};

export const createAdmin = (payload) => {
  return api.post(`/admin/admin-users`, payload);
};
export const updateAdmin = (adminId, payload) => {
  return api.patch(`/admin/admin-users/${adminId}`, payload);
};
