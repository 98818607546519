import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal, VehicleInspectionDetail, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import {VehicleInspection} from "interfaces";
import { IVehicleInspectionList } from "./VehicleInspectionList";

const headersList = [
  { header: "Business Name" },
  { header: "Registration Number" },
  { header: "Color" },
  { header: "Year" },
];

const VehicleInspectionList: React.FC<IVehicleInspectionList.IProps> = observer(
  (props: IVehicleInspectionList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { vehicleInspectionStore } = useRootStore();
    const {
      activeVehicleInspection,
      data: {
        listItems: vehicleInspection,
        meta: { page, totalCount, limit },
      },
      setActiveVehicleInspection,
    } = vehicleInspectionStore;

    const renderVehicleInspectionList = (props: { item: VehicleInspection; id: number }) => {
      
      const { item: vehicleInspection, id } = props;
      return (
        <tr
          key={`vehicleInspection_${id}`}
          onClick={() => viewVehicleInspection(vehicleInspection)}
        >
          <td>{vehicleInspection.businessName? vehicleInspection.businessName : "-"}</td>
          <td>{vehicleInspection.registrationNumber}</td>
          <td>{vehicleInspection.color}</td>
          <td>{vehicleInspection.year}</td>
        </tr>
      );
    };

    const viewVehicleInspection = (vehicleInspection) => {
      setActiveVehicleInspection(vehicleInspection);
      setShowModal(true);
    };

    return (
      <>
        <Table
          listItems={vehicleInspection}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderVehicleInspectionList}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await vehicleInspectionStore.getVehicleInspection({ page });
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveVehicleInspection({});
          }}
          show={showModal}
          title={`Vehicle Inspection Detail`}
          size="xl"
        >
          <VehicleInspectionDetail vehicleInspection={activeVehicleInspection} />
        </Modal>
      </>
    );
  }
);

export { VehicleInspectionList };
