import React from "react";
import { observer } from "mobx-react-lite";
import { Button, CogList, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { DailySettlement } from "interfaces";
import styles from "./DailySettlementList.module.scss";
import { IDailySettlementsList } from "./DailySettlementsList";
import { formatDate } from "utils/dates";
import formatNumber from "utils/formatMoney";
import { PaymentStatus } from "enums/payment-status.enum";
import appStyles from "styles/app.module.scss";

const headersList = [{ header: "Date" }, { header: "Amount" }, { header: "" }];

const DailySettlementsList: React.FC<IDailySettlementsList.IProps> = observer(
  (props: IDailySettlementsList.IProps) => {
    const { dailySettlementStore } = useRootStore();
    const {
      data: {
        listItems: dailySettlements,
        meta: {
          page,
          totalCount,
          limit,
          nonSettledSettlements,
          paidSettlements,
        },
      },

      updateSettlement,
    } = dailySettlementStore;

    const renderDailySettlements = (props: {
      item: DailySettlement;
      id: number;
    }) => {
      const { item: dailySettlement, id } = props;
      return (
        <tr key={`dailySettlement_${id}`}>
          <td>{formatDate(dailySettlement.settlementDate, "LL")}</td>
          <td>N{formatNumber(dailySettlement.sumAssured)}</td>
          <td style={{ width: 200, textAlign: "center" }}>
            {dailySettlement.status === PaymentStatus.PAID ? (
              <span className="text-success">Paid</span>
            ) : (
              <Button
                disabled={!+dailySettlement.sumAssured}
                label="Mark as Paid"
                onClick={() => updateSettlement(dailySettlement.id)}
              />
            )}
          </td>
          <td style={{ width: 45 }}></td>
        </tr>
      );
    };

    return (
      <>
        <Table
          listItems={dailySettlements}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          renderItems={renderDailySettlements}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={async (page) => {
            await dailySettlementStore.getDailySettlements({
              page,
            });
          }}
          topStats={[
            {
              label: "Paid",
              value: paidSettlements,
              className: appStyles.successCard,
            },
            {
              label: "Unpaid",
              value: nonSettledSettlements,
              className: appStyles.dangerCard,
            },
          ]}
        />
      </>
    );
  }
);

export { DailySettlementsList };
