import React from "react";
import { IPaginate2 } from "./Pagination2";
import styles from "./Pagination.module.scss";
import usePagination from "hooks/usePagination";
import concatClasses from "utils/concatClasses";

export const Pagination2 = (props) => {
  const {
    paginationContainer,
    paginationItem,
    paginationItem__selected,
    arrow,
    disabled,
  } = styles;
  const {
    paginationHandler,
    siblingCount = 1,
    currentPage,
    pageCount,
    isFixed = true,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    pageCount,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = async () => {
    await paginationHandler(currentPage + 1);
  };

  const onPrevious = async () => {
    await paginationHandler(currentPage - 1);
  };

  const onPageClick = async () => {
    await paginationHandler(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  const containerStyle: React.CSSProperties = {
    position: isFixed ? "fixed" : "static",
  };
  return (
    <ul className={paginationContainer} style={containerStyle}>
      <li
        className={concatClasses(
          paginationItem,
          currentPage === 1 ? disabled : ""
        )}
        onClick={onPrevious}
      >
        <div className={arrow}>{"<"}</div>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === "...") {
          return (
            <li key={`page_${index}_${pageNumber}`} className={paginationItem}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={`page_${pageNumber}`}
            className={concatClasses(
              paginationItem,
              pageNumber === currentPage ? paginationItem__selected : ""
            )}
            onClick={() => paginationHandler(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={concatClasses(
          paginationItem,
          currentPage === lastPage ? disabled : ""
        )}
        onClick={onNext}
      >
        <div className={arrow}>{">"}</div>
      </li>
    </ul>
  );
};
