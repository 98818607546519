import React from "react";
// import useLoadImage from "hooks/useLoadImage";
import { IChart } from "./Chart";
import styles from "./Chart.module.scss";
// import concatClasses from "utils/concatClasses";
import getRandomColor from "utils/getRandomColor";

export default function Chart({ labels, values, height }: IChart.IProps) {
  const maxValue = Math.max(...values);
  const { containerClass, barClass, valueClass, labelClass } = styles;
  return (
    <div className={containerClass} style={height ? { height } : {}}>
      {values.map((value, index) => {
        return (
          <div
            className={valueClass}
            style={{
              width: `${(100 * 0.6) / values.length}%`,
            }}
          >
            <div
              className={barClass}
              style={{
                height: `${(value * 100) / maxValue}%`,
                width: "100%",
                backgroundColor: getRandomColor(),
              }}
            />
            <p className={labelClass}>{labels[index]}</p>
          </div>
        );
      })}
    </div>
  );
}
