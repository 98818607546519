import React from "react";
import { useHistory } from "react-router-dom";
import { ISideBar } from "./Sidebar.d";
import styles from "./Sidebar.module.scss";
import { FancySpinner, Icon, Button, Avatar } from "components";
import Logo from "../../assets/img/Logo";
import { color } from "theme";
import { useRootStore } from "store";
import { observer } from "mobx-react-lite";
import { Customer } from "interfaces";
import { UserRole } from "enums/userRole.enum";

export const Sidebar: React.FC<ISideBar.IProps> = observer(
  (props: ISideBar.IProps) => {
    const { items: routes } = props;
    const router = useHistory();
    const {
      authStore,
      customersStore: { recentViews },
      toastStore: { addToast },
    } = useRootStore();
    const { fullName, user } = authStore;

    const navigate = (pathname: string) => {
      props.setIsOpen(false);
      if (pathname) {
        router.push(pathname);
      }
    };

    const permissionsMap = {
      [UserRole.ADMIN]: [
        "/policies",
        "/claims",
        "/crash-notifications",
        "/service-centers",
      ],
      [UserRole.PARTNER_ADMIN]: ["/policies", "/claims"],
    };

    let permittedRoutes = [];
    if (user.role) {
      permittedRoutes =
        user.role === UserRole.SUPER_ADMIN
          ? routes
          : routes.filter((routeConfig) => {
              return permissionsMap[user.role]?.includes(routeConfig.path);
            });
    }

    return (
      <div
        className={`${
          props.isOpen ? styles.sidebarOpen : styles.sidebarClose
        } ${styles.sidebarContainer}`}
        onBlur={() => {
          props.setIsOpen(false);
        }}
        tabIndex={0}
      >
        <div className={styles.innerContainer}>
          <Logo />
          {/* TODO: Add Image component here */}
          <span className={styles.mainName}>{fullName}</span>
          <div className={styles.subName}>{user.email}</div>
          <ul className={styles.contentContainer}>
            {permittedRoutes?.map((routeItem, id) => {
              return (
                <li key={id}>
                  <div className={styles.listContentContainer}>
                    <div
                      className={styles.listContentWrapper}
                      onClick={() => navigate(routeItem.path)}
                    >
                      <Icon
                        icon={routeItem.icon}
                        fill={
                          routeItem.active ? color.primary : color.palette.black
                        }
                        height={20}
                        width={20}
                      />

                      <span
                        className={styles.listLabel}
                        style={{
                          color: routeItem.active
                            ? color.primary
                            : color.secondary,
                        }}
                      >
                        {routeItem.label}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
            <li>
              <div className={styles.listContentContainer}>
                <div
                  className={styles.listContentWrapper}
                  onClick={() => authStore.logout()}
                >
                  <Icon icon={"edit"} fill={color.palette.red} />

                  <span
                    className={styles.listLabel}
                    style={{
                      color: color.palette.red,
                    }}
                  >
                    Logout
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div className={styles.recentsContainer}>
            <span className={styles.mainName}>RECENT PROFILE VIEW</span>
            <div className={styles.recentsList}>
              {recentViews.map((customer: Customer) => (
                <div
                  className={styles.recentsItem}
                  key={`random_${customer.id}`}
                  onClick={() => router.push(`/customers/${customer.id}`)}
                >
                  <Avatar
                    label={
                      customer?.firstname &&
                      `${customer?.firstname} ${customer?.lastname}`
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Sidebar.defaultProps = {
  setIsOpen: () => ({}),
};
