import { BandRequestStatus } from "enums/band-request.enum";
import { Customer } from "interfaces/Customer";
import { PaginatedListPayload } from "interfaces/PaginatedListPayload";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getCustomers = (payload: PaginatedListPayload) => {
  const params = getURLParams({
    ...defaultPaginatedList,
    ...payload,
    orderBy: "DESC",
    sortBy: "createdAt",
  });
  return api.get(`/customers${params}`);
};

export const getCustomer = (id: string) => {
  return api.get(`/customers/user/${id}`);
};

export const updateCustomer = (id: string, payload: Customer) => {
  return api.put(`/customers/user/${id}`, payload);
};

export const createBandRequest = (payload: {
  userId: number;
  band: number;
}) => {
  return api.post(`/api/users/band-requests`, payload);
};

export const updateBandRequest = (
  requestId: number,
  payload: {
    status: BandRequestStatus;
  }
) => {
  return api.patch(`/api/users/band-requests/${requestId}`, payload);
};
