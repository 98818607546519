import { PaginatedListPayload } from "interfaces/PaginatedListPayload";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getWebPolicies = (payload: PaginatedListPayload) => {
  const params = getURLParams({
    ...defaultPaginatedList,
    ...payload,
    orderBy: "DESC",
    sortBy: "createdAt",
    limit: 25,
  });
  return api.get(`/web-apis/policies/third-party${params}`);
};
