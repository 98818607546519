import React from "react";

function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      {...props}
    >
      <path
        fill={props.fill || "#3F3B3B"}
        fillRule="evenodd"
        d="M9.492.037a.75.75 0 010 1.5H5.753c-2.584 0-4.253 1.77-4.253 4.509v8.314c0 2.739 1.669 4.509 4.253 4.509h8.824c2.584 0 4.254-1.77 4.254-4.51v-4.027a.75.75 0 011.5 0v4.028c0 3.594-2.313 6.009-5.754 6.009H5.753C2.312 20.369 0 17.954 0 14.359V6.047C0 2.452 2.312.036 5.753.036h3.739zm8.71.878l1.217 1.217c.593.592.919 1.38.918 2.217 0 .838-.326 1.624-.918 2.215l-7.51 7.51a2.902 2.902 0 01-2.064.854H6.099a.75.75 0 01-.75-.769l.094-3.779a2.912 2.912 0 01.854-1.992L13.772.915a3.14 3.14 0 014.43 0zm-5.047 2.736L7.358 9.45a1.412 1.412 0 00-.415.968l-.075 3.011h2.977c.38 0 .736-.147 1.005-.416l5.832-5.834-3.527-3.527zm1.676-1.675l-.616.614 3.527 3.528.617-.615c.308-.308.478-.718.478-1.154 0-.437-.17-.848-.478-1.156l-1.217-1.217a1.64 1.64 0 00-2.311 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default EditIcon;
