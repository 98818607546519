import React from "react";
import { ITextArea } from "./Textarea";
import styles from "./Textarea.module.scss";
import { FormControl } from "components/FormControl";
// import { useField } from "formik";

export const TextArea: React.FC<ITextArea.IProps> = ({ ...props }) => {
  const { name, value, withFormik, formikInstance } = props;

  const controlValue = withFormik ? formikInstance.values[name] : value;

  const inputConfig = {
    onChange: withFormik
      ? (e) => formikInstance.setFieldValue(name, e.target.value)
      : props.onChange,
    onBlur: withFormik
      ? () => formikInstance.setFieldTouched(name, true)
      : props.onBlur,
  };
  // const [field] = useField(props);
  return (
    <FormControl
      controlInputContainerClass={styles.textAreaInputContainer}
      {...props}
    >
      <textarea
        {...inputConfig}
        className={styles.textArea}
        rows={props.rows}
        // id={props.id}
        // name={props.name}
        placeholder={props.placeholder}
        value={controlValue}
        // onBlur={props.onBlur}
        // onChange={props.onChange}
        disabled={props.disabled}
        ref={props.setRef}
      />
    </FormControl>
  );
};

TextArea.defaultProps = {
  placeholder: "",
  rows: 3,
  style: {},
  inputClass: "",
  // onChange: () => ({}),
  onBlur: () => ({}),
  withFormik: true,
};
