import React from "react";

function ClaimIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill={props.fill || "#574646"}
        fillRule="evenodd"
        d="M15.436 0C19.063 0 21.5 2.546 21.5 6.335v8.83c0 3.789-2.437 6.335-6.064 6.335H6.064C2.437 21.5 0 18.954 0 15.165v-8.83C0 2.546 2.437 0 6.064 0h9.372zm0 1.5H6.064C3.292 1.5 1.5 3.397 1.5 6.335v8.83C1.5 18.103 3.292 20 6.064 20h9.372C18.209 20 20 18.103 20 15.165v-8.83C20 3.397 18.209 1.5 15.436 1.5zM6.121 8.203a.75.75 0 01.75.75v6.86a.75.75 0 01-1.5 0v-6.86a.75.75 0 01.75-.75zm4.667-3.284a.75.75 0 01.75.75v10.143a.75.75 0 01-1.5 0V5.668a.75.75 0 01.75-.75zm4.59 6.909a.75.75 0 01.75.75v3.234a.75.75 0 01-1.5 0v-3.234a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ClaimIcon;
