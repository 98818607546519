import { DataListMeta } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, types } from "mobx-state-tree";
import NProgress from "nprogress";
import { Bank } from "interfaces/Bank";
import { getBanks, getCustomerBankAccounts } from "services/api/bank";
import { BankAccount } from "interfaces/BankAccount";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const BanksStore = types
  .model("Banks", {
    banks: types.optional(types.array(types.frozen<Bank>()), []),
    bankAccounts: types.optional(types.array(types.frozen<BankAccount>()), []),
    banksMeta: types.optional(types.frozen<DataListMeta>(), {}),
    banksAccountsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    fetching: false,
  })
  .views((self) => ({}))
  .actions((self) => ({
    getBanks: flow(function* (payload) {
      NProgress.start();
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getBanks(payload);
        const { data, ...rest } = resp.data.data;
        self.banks = data;
        self.banksMeta = rest;
        self.fetching = false;
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log({ ...error });
        console.log(`error`, error);
      } finally {
        self.fetching = false;
        NProgress.done();
      }
    }),
    getCustomerBankAccounts: flow(function* (id, payload) {
      NProgress.start();
      try {
        self.fetching = true;
        const resp: GenericAxiosResponse = yield getCustomerBankAccounts(
          id,
          payload
        );
        const { data } = resp.data;
        console.log(`dataOB`, data);
        self.bankAccounts = data;
        // self.banksAccountsMeta = rest;
        return resp.data.data;
      } catch (error) {
        //set global error
        console.log(`error`, error);
      } finally {
        self.fetching = false;
        NProgress.done();
      }
    }),
  }));
