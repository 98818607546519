import { Card, CheckBox, TextArea, TextInput } from "components";
import { IClaimIncidentForm } from "./ClaimIncidentForm";
import appStyles from "styles/app.module.scss";
import { formatDate } from "utils/dates";

const ClaimIncidentForm: React.FC<IClaimIncidentForm.IProps> = ({ claim }) => {
  return (
    <Card bgColor="dark" customClass="px-3 px-md-5 pb-5 h-100">
      <div className={appStyles.formTitle}>Incident Details</div>
      <div>
        <div className="row  mb-2">
          <div className="col-6">
            <TextInput
              withFormik={false}
              name="incidentDate"
              label="When did the incident occur? "
              icon="calendar"
              disabled
              value={formatDate(claim.incidentDate, "DD/MM/YYYY")}
            />
          </div>
          <div className="col-4">
            <TextInput
              withFormik={false}
              name="time"
              label="Time"
              icon="clock"
              disabled
              value={formatDate(claim.information?.time, "HH:mm")}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <TextInput
              withFormik={false}
              name="vehicleSpeed"
              label="How fast was vehicle moving "
              disabled
              value={claim.information?.vehicleSpeed}
            />
          </div>

          <div className="col-6">
            <TextInput
              withFormik={false}
              label="Weather"
              name="weather"
              disabled
              value={claim.information?.weather}
            />
          </div>
        </div>

        <TextArea
          name="incidentDescription"
          withFormik={false}
          label="Description of what happened"
          disabled
          value={claim.incidentDescription}
        />
        <div className="mt-4">
          <CheckBox
            name="incidentDriver"
            checked={!claim.incidentDriver}
            label="Were  you driving alone?"
            onChange={() => ({})}
          />
        </div>
      </div>
    </Card>
  );
};

export { ClaimIncidentForm };
