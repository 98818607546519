import React, { useState } from "react";
import { ICogList } from "./CogList";
import styles from "./CogList.module.scss";
import { Card, Icon } from "components";

export const CogList: React.FunctionComponent<ICogList.IProps> = (
  props: ICogList.IProps
) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderActions = () => {
    return props.actions.map((action, index) => (
      <div
        className={styles.cardItem}
        key={`action_${index}`}
        onClick={(e) => {
          e.stopPropagation();
          action.fn();
        }}
      >
        {action.label}
      </div>
    ));
  };
  let className = `${styles.CogContainer}`;
  let optionsClassName = styles.OptionCard;
  if (isOpen) {
    optionsClassName += " d-flex flex-column";
  } else {
    optionsClassName += " d-none";
  }

  return (
    <div
      className={className}
      onClick={(e) => {
        setIsOpen((prev) => !prev);
        e.stopPropagation();
      }}
      onBlur={() => setIsOpen(false)}
      tabIndex={-1}
    >
      <Icon icon="cog" />
      <div
        className={styles.OptionsContainer}
        style={{ ...props.optionStyle, width: props.width || "100%" }}
      >
        <Card customClass={optionsClassName}>{renderActions()}</Card>
      </div>
    </div>
  );
};

CogList.defaultProps = {
  optionStyle: {},
};
