import * as Yup from "yup";

const RegionSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  paymentProvider: Yup.string().required("Required"),
  underwriter: Yup.string().required("Required"),
  flag: Yup.string().nullable(),
  currency: Yup.string().required("Required"),
});

export default RegionSchema;
