import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Card, Modal, NumberCard, Table } from "components";
import { useRootStore } from "store";
import { WebPolicy } from "interfaces/WebPolicy";
import { color } from "theme";
import { daysFromNow, formatDate } from "utils/dates";
import appStyles from "styles/app.module.scss";
import formatNumber from "utils/formatMoney";
import concatClasses from "utils/concatClasses";
import getPageCount from "utils/getPageCount";

const WebPoliciesList: React.FC = observer(() => {
  const [showModal, setShowModal] = useState(false);
  const { webPolicyStore } = useRootStore();
  const {
    getWebPolicies,
    setActivePolicy,
    activePolicy,
    data: {
      listItems: policies,
      meta: { total, limit, page },
    },
  } = webPolicyStore;

  const headersList = useMemo(
    () => [
      { header: "Vehicle" },
      { header: "Customer" },
      { header: "Start Date" },
      { header: "End Date" },
      { header: "Policy Number" },
    ],
    []
  );

  const renderPolicies = (props: { item: WebPolicy; id: number }) => {
    const { item: policy, id } = props;
    return (
      <tr
        key={`policy_${id}`}
        style={{
          backgroundColor: color.palette.white,
        }}
        onClick={() => {
          setActivePolicy(policy);
          setShowModal(true);
        }}
      >
        <td>
          <div>
            <div>
              {`${policy.policies_vehicle_make || "-"} ${
                policy.policies_vehicle_model || "-"
              } ${policy.policies_vehicle_year_of_manufacture || "-"}`}
            </div>
            <span className="text-muted mt-2">
              {policy.policies_vehicle_reg_number || "-"}
            </span>
          </div>
        </td>
        <td>{policy.policies_email || "-"}</td>
        <td>{formatDate(policy.policies_created_at, "ll")}</td>
        <td className="text-danger">
          {formatDate(policy.policies_expiry_date, "ll")}
        </td>
        <td>{policy.policies_policy_number}</td>
      </tr>
    );
  };

  return (
    <>
      <Table
        listItems={policies}
        headersList={headersList}
        totalCount={total}
        renderItems={renderPolicies}
        pageCount={getPageCount(total, limit)}
        usePagination
        currentPage={+(page || 1)}
        initialPage={page >= 2 ? page - 1 : 0}
        showRefresh
        handleRefresh={() => getWebPolicies({ page: 1 })}
        handlePagination={async (page) => {
          await getWebPolicies({ page });
        }}
      />
      <Modal
        handleHide={() => {
          setShowModal(false);
          setActivePolicy({});
        }}
        show={showModal}
        title={`Manage Policy`}
        size="xl"
      >
        <div>
          <div className="col-12">
            <Card bgColor="dark" customClass="h-100">
              <div className={appStyles.formTitle}>Policy Details</div>
              <div className="pb-4 border-bottom d-flex justify-content-between ">
                <div>
                  <Card
                    customClass="mb-2 py-2 px-3 text-center border-primary"
                    bgColor="dark"
                  >
                    Underwriter
                  </Card>
                  <span className="text-primary">
                    {activePolicy?.policies_underwriter}
                  </span>
                </div>
                <div>
                  <div>
                    {formatDate(
                      activePolicy?.policies_created_at,
                      "DD MMMM, YYYY"
                    )}
                  </div>
                  <div className="text-danger mt-2">
                    {formatDate(
                      activePolicy?.policies_expiry_date,
                      "DD MMMM, YYYY"
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Insurance Type</div>
                  <div>THIRD PARTY</div>
                </div>
              </div>
              <div className="py-4 border-bottom d-flex align-items-center justify-content-between">
                <div>
                  <div className="text-muted mb-2">Policy Number</div>
                  <div className="mb-2">
                    {activePolicy?.policies_policy_number}
                  </div>
                </div>
                <div>
                  <div
                    style={{ fontSize: 16 }}
                    className={concatClasses(
                      appStyles.formTitle,
                      "font-weight-normal text-success"
                    )}
                  >
                    Days Left
                  </div>
                  <NumberCard
                    number={daysFromNow(activePolicy?.policies_expiry_date)}
                  />
                </div>
              </div>
              <div className="pt-4 d-flex justify-content-between">
                <div>
                  <div className="text-muted mb-2">Vehicle Type</div>
                  <div className="mb-2">
                    {`${activePolicy?.policies_vehicle_make} ${activePolicy?.policies_vehicle_model} ${activePolicy?.policies_vehicle_year_of_manufacture}`}
                  </div>
                  <div className="mb-2 small">
                    {activePolicy?.policies_vehicle_reg_number}
                  </div>
                  <div className="small">
                    Chasis No.
                    {activePolicy?.policies_vehicle_chassis_number}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Vehicle Value</div>
                  <div className="mb-2">
                    N {formatNumber(activePolicy?.policies_worth)}
                  </div>
                </div>
                <div>
                  <div className="text-muted mb-2">Amount Covered</div>
                  <div className="mb-2 text-primary">
                    N{" "}
                    {formatNumber(
                      parseFloat(activePolicy?.policies_amount) / 100
                    )}
                  </div>
                </div>
              </div>
              <div className="py-3 border-top mt-4">
                <div className={concatClasses(appStyles.formTitle, "mb-0")}>
                  Customer Information
                </div>
                <div className="pt-4 d-flex justify-content-between">
                  <div>
                    <div className="text-muted mb-2">Phone Number</div>
                    <div className="mb-2">
                      {activePolicy?.policies_phone_number || "N/A"}
                    </div>
                  </div>
                  <div>
                    <div className="text-muted mb-2">Email Address</div>
                    <div className="mb-2">{activePolicy?.policies_email}</div>
                  </div>
                  <div>
                    <div className="text-muted mb-2">Date registered</div>
                    <div className="mb-2 text-primary">
                      {activePolicy?.policies_created_at
                        ? formatDate(activePolicy?.policies_created_at, "ll")
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
});

export { WebPoliciesList };
