import { PaginatedListPayload } from "interfaces";
import getURLParams from "utils/getUrlParams";
import api, { defaultPaginatedList } from "./config";

export const getServiceCenters = (payload: PaginatedListPayload) => {
  const params = getURLParams({ ...defaultPaginatedList, ...payload });
  return api.get(`/service-centers/admin${params}`);
};

export const createServiceCenter = (payload) => {
  return api.post(`/service-centers`, payload);
};
export const updateServiceCenter = (serviceCenterId, payload) => {
  return api.put(`/service-centers/${serviceCenterId}`, payload);
};
