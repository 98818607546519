import React from "react";
import { ITabBar } from "./TabBar";
import styles from "./TabBar.module.scss";
import concatClasses from "utils/concatClasses";

export function Tab({ text, onSelect, value, active }) {
  const { tab__container, tab__text, tab__container_active } = styles;
  return (
    <div
      className={concatClasses(
        tab__container,
        active ? tab__container_active : null
      )}
      onClick={() => onSelect(value)}
      key={value}
    >
      <p className={tab__text}>{text}</p>
    </div>
  );
}

export default function TabBar({
  tabs,
  onSelectTab,
  activeTab,
  className,
  showTab = true,
  ...props
}: ITabBar.IProps) {
  const {} = styles;
  const currentTab = tabs.find(({ value }) => value === activeTab);
  const CurrentTabComponent = currentTab.component;
  return (
    <>
      <div className={concatClasses(styles.container, className)}>
        <div className={styles.tabs}>
          {tabs.map(({ value, label, component }) => (
            <Tab
              key={value}
              value={value}
              text={label}
              active={activeTab === value}
              onSelect={onSelectTab}
            />
          ))}
        </div>
      </div>
      {showTab ? <CurrentTabComponent /> : null}
    </>
  );
}
