import { Route, Redirect } from "react-router-dom";
import { IPrivateRoute } from "./PrivateRoute";

const PrivateRoute: React.FC<IPrivateRoute.IProps> = ({
  children,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
