import { BadgeBasis } from "enums/badge-basis.enum";
import { BadgeType } from "enums/badge-type.enum";
import * as Yup from "yup";

const BadgeSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().nullable(),
  badgeType: Yup.mixed<BadgeType>().oneOf(Object.values(BadgeType)),
  active: Yup.boolean(),
  rank: Yup.number(),
  criteria: Yup.object()
    .shape({
      basis: Yup.mixed<BadgeBasis>().oneOf(Object.values(BadgeBasis)),
      amount: Yup.number(),
    })
    .nullable(),
});

export default BadgeSchema;
