import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { BroadcastForm, CogList, Modal, Table } from "components";
import { useRootStore } from "store";
import getPageCount from "utils/getPageCount";
import { Broadcast } from "interfaces";
import styles from "./BroadcastList.module.scss";
import { IBroadcastsList } from "./BroadcastsList";
import { formatDate } from "utils/dates";
import shortenText from "utils/shortenText";

const headersList = [
  { header: "Subject" },
  { header: "Message" },
  { header: "Date Created" },
  { header: "" },
];

const BroadcastsList: React.FC<IBroadcastsList.IProps> = observer(
  (props: IBroadcastsList.IProps) => {
    const [showModal, setShowModal] = useState(false);
    const { broadcastStore } = useRootStore();
    const {
      broadcasts,
      broadcastsMeta: { page, totalCount, limit },
      fetching,
      activeBroadcast,
      setActiveBroadcast,
      resend,
    } = broadcastStore;

    const renderBroadcasts = (props: { item: Broadcast; id: number }) => {
      const { item: broadcast, id } = props;
      return (
        <tr key={`broadcast_${id}`} onClick={() => viewBroadcast(broadcast)}>
          <td>{broadcast.subject}</td>
          <td>{shortenText(broadcast.message, 30)}</td>
          <td>{formatDate(broadcast.createdAt, "ll")}</td>
          <td>
            <CogList actions={getCogActions(broadcast)} />
          </td>
        </tr>
      );
    };

    const getCogActions = (broadcast) => [
      {
        label: "Resend",
        fn: async () => {
          await resend(broadcast.id);
        },
      },
    ];

    const viewBroadcast = (broadcast) => {
      setActiveBroadcast(broadcast);
      setShowModal(true);
    };

    return (
      <>
        <Table
          listItems={broadcasts}
          headersList={headersList}
          totalCount={totalCount}
          pageCount={getPageCount(totalCount, limit)}
          loading={fetching}
          renderItems={renderBroadcasts}
          usePagination
          currentPage={page}
          initialPage={page >= 2 ? page - 1 : 0}
          handlePagination={(page) => {
            broadcastStore.getBroadcasts({ page, ...props.filters });
          }}
          showAddBtn
          handleAdd={() => {
            setActiveBroadcast({});
            setShowModal(true);
          }}
        />

        <Modal
          handleHide={() => {
            setShowModal(false);
            setActiveBroadcast({});
          }}
          show={showModal}
          title={`${activeBroadcast?.id ? "Edit" : "Create"} Broadcast `}
        >
          <BroadcastForm
            broadcast={activeBroadcast}
            handleSubmit={() => {
              setShowModal(false);
              setActiveBroadcast({});
            }}
          />
        </Modal>
      </>
    );
  }
);

export { BroadcastsList };
