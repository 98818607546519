import { PaginatedListPayload, StoreListData } from "interfaces";
import { GenericAxiosResponse } from "interfaces/api";
import { flow, getRoot, types } from "mobx-state-tree";
import NProgress from "nprogress";
import {
  createProduct,
  deleteProduct,
  getProductOrders,
  getProducts,
  updateProduct,
} from "services/api/products";
import { RootStore } from "..";
import cloneDeep from "utils/cloneDeep";
import { defaultStoreList } from "utils/constants";

/**
 * This file is generated as an example of Mobx State Tree Stores
 *
 * To learn more about Mobx and Mobx State Tree,
 * please visit https://mobx-state-tree.js.org/intro/welcome
 */

export const ProductsStore = types
  .model("Products", {
    // products: types.optional(types.array(types.frozen<Product>()), []),
    // productsMeta: types.optional(types.frozen<DataListMeta>(), {}),
    // fetching: false,
    data: types.optional(
      types.frozen<StoreListData>(),
      cloneDeep(defaultStoreList)
    ),
    query: types.optional(types.frozen<PaginatedListPayload>(), {}),
  })
  .views((self) => ({
    get rootStore() {
      return getRoot<typeof RootStore>(self);
    },
  }))
  .actions((self) => ({
    setQuery(payload: PaginatedListPayload) {
      const currentData = cloneDeep(self.query);
      self.query = { ...currentData, ...payload };
    },
    resetQuery() {
      self.query = {};
    },
    getProducts: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield getProducts({
          ...self.query,
          ...payload,
        });
        const { all } = resp.data;
        const { data, ...rest } = all;
        self.data = {
          listItems: data,
          meta: rest,
        };
        return data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
      } finally {
        NProgress.done();
      }
    }),
  }))
  .actions((self) => ({
    createProduct: flow(function* (payload) {
      NProgress.start();
      try {
        const resp: GenericAxiosResponse = yield createProduct(payload);
        yield self.getProducts({});
        // self.productsMeta = rest;
        NProgress.done();
        self.rootStore.toastStore.addSuccessToast(
          "Product successfully created"
        );
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        NProgress.done();
        throw error;
      }
    }),
    updateProduct: flow(function* (productId, payload) {
      try {
        const resp: GenericAxiosResponse = yield updateProduct(
          productId,
          payload
        );
        self.getProducts({});
        self.rootStore.toastStore.addSuccessToast(
          "Product successfully updated"
        );
        return resp.data.data;
      } catch (error) {
        console.log(`error`, error);
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    deleteProduct: flow(function* (productId) {
      try {
        const resp: GenericAxiosResponse = yield deleteProduct(productId);
        self.getProducts({});
        self.rootStore.toastStore.addSuccessToast(
          "Product successfully deleted"
        );
        return resp.data.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
    getProductOrders: flow(function* (productId, query) {
      try {
        const resp: GenericAxiosResponse = yield getProductOrders(
          productId,
          query
        );
        console.log(`resp.data`, resp.data);
        return resp.data;
      } catch (error) {
        self.rootStore.toastStore.addErrToast(error);
        throw error;
      }
    }),
  }));
